import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

const LinkTooltip = ({ onApply, onCancel }) => {
  const [linkType, setLinkType] = useState("web");
  const [title, setTitle] = useState("");
  const [href, setHref] = useState("");
  const [target, setTarget] = useState("_self");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");

  const handleApply = () => {
    let finalHref = href;
    if (linkType === "email") {
      finalHref = `mailto:${href}?subject=${encodeURIComponent(
        emailSubject
      )}&body=${encodeURIComponent(emailContent)}`;
    } else if (linkType === "phone") {
      finalHref = `tel:${href}`;
    } else if (!href.startsWith("http")) {
      finalHref = `https://${href}`;
    }
    onApply({ title, href: finalHref, target });
  };

  const handleTypeChange = (event) => {
    setLinkType(event.target.value);
    if (event.target.value === "web") {
      setEmailSubject("");
      setEmailContent("");
    }
  };

  const getPlaceholderByType = (type) => {
    switch (type) {
      case "web":
        return "Enter URL";
      case "phone":
        return "Enter Phone Number";
      case "email":
        return "Enter Email ID";
      default:
        return "Enter Value";
    }
  };

  return (
    <>
      <div className="link-manager-wrapper">
        <div className="select-input-container">
          <div className="select-and-input">
            <div className="mui-select-wrapper">
              <FormControl fullWidth>
                <Select
                  value={linkType}
                  onChange={handleTypeChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="web">Web</MenuItem>
                  <MenuItem value="email">Email</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                </Select>
              </FormControl>
            </div>
            <input
              type="text"
              className="input-trait"
              value={href}
              placeholder={getPlaceholderByType(linkType)}
              onChange={(e) => setHref(e.target.value)}
            />
          </div>
          {linkType === "email" && (
            <div className="email-subject-content-container">
              <input
                type="text"
                value={emailSubject}
                className="input-trait"
                onChange={(e) => setEmailSubject(e.target.value)}
                placeholder="Email Subject"
              />
              <textarea
                className="input-trait"
                placeholder="Email Content"
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="tooltip-buttons">
          <Button className="btn btn-primary" onClick={handleApply}>
            Save
          </Button>
          <Button className="btn btn-outline dark-border" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default LinkTooltip;
