import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({data, options, width, height}) => {
 return (
  <div style={{width, height}}>
   <Doughnut data={data} options={options} />
  </div>
 );
};

export default DoughnutChart;
