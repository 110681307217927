// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItemContainer {
 display: flex;
 align-items: center;
 justify-content: space-between;
 border: 1px solid #dedfea;
 padding: 6px 12px;
 border-radius: 8px;
 margin-bottom: 12px;
}

.SortableItem {
 cursor: move;
 display: flex;
 span {
  margin-left: 8px;
 }
 img {
  margin-right: 0px;
  padding-right: 0px;
 }
}

ul {
 list-style: none;
 padding: 0;
 margin: 0;
}

.drawerHeader {
 display: flex;
 gap: 8px;
 flex-direction: row;
 /* flex: 1 auto; */
 align-items: center;
 /* justify-content: space-between; */
 padding: 16px 24px;
 span {
  font-size: 16px;
  font-weight: 500;
  display: block;
  width: 100%;
 }
}

.css-j204z7-MuiFormControlLabel-root {
 margin-left: 0 !important;
 margin-right: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/contacts/AllContacts/component/component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;CAC9B,yBAAyB;CACzB,iBAAiB;CACjB,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb;EACC,gBAAgB;CACjB;CACA;EACC,iBAAiB;EACjB,kBAAkB;CACnB;AACD;;AAEA;CACC,gBAAgB;CAChB,UAAU;CACV,SAAS;AACV;;AAEA;CACC,aAAa;CACb,QAAQ;CACR,mBAAmB;CACnB,kBAAkB;CAClB,mBAAmB;CACnB,oCAAoC;CACpC,kBAAkB;CAClB;EACC,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,WAAW;CACZ;AACD;;AAEA;CACC,yBAAyB;CACzB,0BAA0B;AAC3B","sourcesContent":[".listItemContainer {\n display: flex;\n align-items: center;\n justify-content: space-between;\n border: 1px solid #dedfea;\n padding: 6px 12px;\n border-radius: 8px;\n margin-bottom: 12px;\n}\n\n.SortableItem {\n cursor: move;\n display: flex;\n span {\n  margin-left: 8px;\n }\n img {\n  margin-right: 0px;\n  padding-right: 0px;\n }\n}\n\nul {\n list-style: none;\n padding: 0;\n margin: 0;\n}\n\n.drawerHeader {\n display: flex;\n gap: 8px;\n flex-direction: row;\n /* flex: 1 auto; */\n align-items: center;\n /* justify-content: space-between; */\n padding: 16px 24px;\n span {\n  font-size: 16px;\n  font-weight: 500;\n  display: block;\n  width: 100%;\n }\n}\n\n.css-j204z7-MuiFormControlLabel-root {\n margin-left: 0 !important;\n margin-right: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
