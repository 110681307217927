import {IconButton, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import iconCross from '../../assets/icons/icon-cross.svg';
import classNames from 'classnames';

const CustomDialog = ({open, title, actions, content, onClose, notice}) => {
 const handleOnClose = (event, reason) => {
  if (reason !== 'backdropClick') {
   onClose(event);
  }
 };
 return (
  <Dialog open={open} onClose={handleOnClose}>
   <Stack direction="row" alignItems="center" justifyContent="space-between">
    <DialogTitle
     sx={{
      p: 0,
      fontFamily: 'Lato',
      fontWeight: 'bold',
      height: '28px'
     }}
    >
     {title}
    </DialogTitle>
    <IconButton aria-label="close" onClick={onClose}>
     <img className="icon" src={iconCross} />
    </IconButton>
   </Stack>

   <DialogContent keepMounted sx={{px: 0, pb: '24px', pt: '16px'}}>
    {content}
   </DialogContent>
   <DialogActions sx={{justifyContent: 'space-between', alignSelf: 'flex-end'}}>
    {actions.map(action => {
     if (action.label === 'Resend Verification Code') {
      return (
       <div
        onClick={action.onClick}
        style={{textDecoration: 'underline', fontWeight: '600'}}
       >
        {action.label}
       </div>
      );
     }
    })}
    <DialogActions>
     {actions.map(action => {
      const {label, onClick, classes, render} = action;
      if (render) {
       return render;
      }
      if (label !== 'Resend Verification Code') {
       return (
        <Button
         className={classNames('btn', classes)}
         variant="contained"
         onClick={onClick}
        >
         {label}
        </Button>
       );
      }
     })}
    </DialogActions>
   </DialogActions>

   {notice && (
    <DialogContent sx={{px: 0, pb: '24px', pt: '16px'}}>{notice}</DialogContent>
   )}
  </Dialog>
 );
};

export default CustomDialog;
