import React from "react";
import CloseBtn from "../../../../assets/icons/icon-cross.svg";
import { useDispatch, useSelector } from "react-redux";
import ColorPicker from "../ColorPicker/ColorPicker";
import { changeRowColor, togglePropertyContainer } from "../../../../store/store.js";

const PropertyPopup = ({editor}) => {
  const propertyContainer = useSelector((state) => state.propertyPopup);
  const rowBgColor = useSelector((state) => state.rowBackground);
  const dispatch = useDispatch();

  function setRowBackgroundColor (color) {
    dispatch(changeRowColor(color));
    // const editorBody = editor.Canvas.getBody();
    // editorBody.setAttribute('style',`background-color: ${color}`);
  }

  return (
    <>
      <div
        className="property-container"
        style={{ display: propertyContainer ? "block" : "none" }}
      >
        <div className="row-settings-popup">
          <div className="property-container-header">
            <span className="block-name">Row</span>
            <button
              className="common-btn"
              onClick={() => dispatch(togglePropertyContainer(false))}
            >
              <img
                src={CloseBtn}
                alt="Close Container Popup Button"
                height="20px"
                width="20px"
              />
            </button>
          </div>
          <div className="row-settings-body">
                  
            <div id="my-custom-trait-manager"></div>

          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyPopup;
