import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import GrapeJSEditor from "./components/GrapesJSComponents/GrapeJSEditor.js";
import "./style/override.scss";
import "./style/components/emaileditor.scss";
import EmailEditorHeader from "./components/Header/EmailEditorHeader.js";
import EditorBlocks from "./components/GrapesJSComponents/EditorBlocks.js";
import BlockProperties from "./components/GrapesJSComponents/BlockProperties.js";
import { customContainers } from "./components/GrapesJSComponents/customBlocks/customContainers.js";
import { template } from "../../api/templateapi.js";
import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { registerTraits } from "./components/GrapesJSComponents/traits/registerTraits.js";
import placeHolder from "../../assets/images/empty_structure.png";
import {
  moveBlocksToDivs,
  renderSectionTableTraitManager,
  renderMenuTraitManager,
  renderTimerTraitManager,
  renderTableTraitManager,
  renderSocialTraitManager,
  renderColumnTraitManager,
  renderTextBlockTraitManager,
  renderImageBlockTraitManager,
  renderButtonBlockTraitManager,
  renderDividerBlockTraitManager,
  renderFooterBlockTraitManager,
  renderHTMLBlockTraitManager,
  setupAssetManagerButtonBehavior,
  customizeRTEIcons,
  applyNonSelectability,
  renderLogoBlockTraitManager,
} from "./components/GrapesJSComponents/utils.js";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, {
  openColorPicker,
  setBrandColors,
  setBrandStyles,
  setCurrentTemplateName,
  setEditor,
  setLayoutData,
  setTemplateSaved,
  toggleCreateLayoutDialog,
  togglePropertyContainer,
  toggleRowPaddingLock,
} from "../../store/store.js";
import { registerTextBlock } from "./components/GrapesJSComponents/customBlocks/textBlock.js";
import { registerLogoBlock } from "./components/GrapesJSComponents/customBlocks/logoBlock.js";
import { registerImageBlock } from "./components/GrapesJSComponents/customBlocks/imageBlock.js";
import { registerButtonBlock } from "./components/GrapesJSComponents/customBlocks/buttonBlock.js";
import { registerDividerBlock } from "./components/GrapesJSComponents/customBlocks/dividerBlock.js";
import { registerTableBlock } from "./components/GrapesJSComponents/customBlocks/tableBlock.js";
import { registerMenuBlock } from "./components/GrapesJSComponents/customBlocks/menuBlock.js";
import { registerSocialBlock } from "./components/GrapesJSComponents/customBlocks/socialBlock.js";
import { registerFooterBlock } from "./components/GrapesJSComponents/customBlocks/footerBlock.js";
import { registerHtmlBlock } from "./components/GrapesJSComponents/customBlocks/htmlBlock.js";
import { registercountdownTimerBlock } from "./components/GrapesJSComponents/customBlocks/countdownTimerBlock.js";
import gjsNewsletter from "grapesjs-preset-newsletter";
import grapesjsRTEExtensions from "grapesjs-rte-extensions";
import ColorPicker from "./components/ColorPicker/ColorPicker.js";
import { closeColorPicker, setColorPickerColor } from "../../store/store.js";
import SaveTemplateDialog from "./components/Header/SaveChangesDialog.js";
import LinkTooltip from "./components/GrapesJSComponents/LinkTooltip";
import iconMore from "../../assets/icons/icon-more-white.svg";
import iconClose from "../../assets/icons/icon-cross.svg";
import iconMove from "../../assets/icons/icon-drag-white.svg";
import iconDelete from "../../assets/icons/icon-delete.svg";
import thumbnail from "../../assets/images/thumbnail_temp.png";
import CreateLayoutDialog from "./components/GrapesJSComponents/CreateLayoutDialog.js";
import CustomDialog from "../../components/Dialog/Dialog.jsx";
import CommonOverlay from "../../components/Overlay/Overlay.jsx";
import CategoryFilter from "../../pages/templates/PrebuiltTemplates/components/CategoryFilter.js";
import CardContainer from "../../components/CardContainer/CardContainer.jsx";
import Header from "../../components/Header/Header.jsx";
import DynamicTabs from "../../components/Tab/Tab.jsx";
import { useTemplates } from "../../hooks/useTemplates.js";
// import { useImages } from "../../hooks/useImages.js";
import { useLocation } from "react-router-dom";
import { imageApi } from "../../api/imageapi.js";
import Progress from "../../components/Progress/Progess.jsx";
import ResourceApi from "../../api/resourceapi.js";

const EmailBuilder = () => {
  const editorRef = useRef(null);
  const editor = useSelector((state) => state.editor);
  const [isLinkTooltipVisible, setLinkTooltipVisible] = useState(false);
  const [deleteLayoutDialog, setDeleteLayoutDialog] = useState(false);
  const [templateLayout, setTemplateLayout] = useState(false);
  const [layoutToDelete, setLayoutToDelete] = useState(null);
  const [layoutId, setLayoutId] = useState(null);
  const [linkTooltipPosition, setLinkTooltipPosition] = useState({
    x: 0,
    y: 0,
  });
  const [linkDataPromise, setLinkDataPromise] = useState(null);
  const selectedRangeRef = useRef(null);
  const bodyBgColor = useSelector((state) => state.bodyBackground);
  const colorPickerState = useSelector((state) => state.colorPicker);
  const rowPaddingLock = useSelector((state) => state.rowPaddingLock);
  const templateId = useSelector((state) => state.currentTemplateId);
  const isSaveTemplateDialog = useSelector(
    (state) => state.isSaveTemplateDialog
  );
  const isCreateLayoutDialog = useSelector(
    (state) => state.isCreateLayoutDialog
  );
  const layoutData = useSelector((state) => state.layoutData);
  const customLayouts = useSelector((state) => state.customLayouts);
  const brandStyles = useSelector((state) => state.brandStyles);
  const prebuiltLayouts = useSelector((state) => state.prebuiltLayouts);
  const dispatch = useDispatch();
  const location = useLocation();
  const htmlContent = location.state?.htmlContent;
  const { templateData, fetchTemplates } = useTemplates();
  // const { imageData, fetchImages} = useImages();
  const prebuiltLayoutData = useSelector((state) => state.prebuiltLayoutData);
  const isTemplateSaved = useSelector((state) => state.isTemplateSaved);
  const brandColors = useSelector((state) => state.brandColors);
  const [templateLoading, setTemplateLoading] = useState(true);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);

  const categories = {
    Containers: [],
    Blocks: [],
    Layouts: [],
    Prebuilt: [],
  };

  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const openCreateLayoutDialog = () => {
    dispatch(toggleCreateLayoutDialog());
  };

  const handleMenuOptionClick = (option) => {
    switch (option) {
      case "Duplicate":
        if (editor) {
          editor.runCommand("clone-component");
        }
        break;
      case "Save as Layout":
        // Add your save as layout logic here
        if (editor) {
          openCreateLayoutDialog();
        }
        break;
      case "Delete":
        // Add your delete logic here
        if (editor) {
          editor.runCommand("core:component-delete");
        }
        break;
      default:
        break;
    }
    handleMenuClose();
  };

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if (htmlContent && editor) {
      editor.setComponents(htmlContent);
    }
  }, [htmlContent, editor]);

  useEffect(() => {
    let lastRange;

    const grapesjsEditor = grapesjs.init({
      container: "#editor-container",
      fromElement: false,
      height: "100%",
      width: "100%",
      panels: {},
      canvas: {
        frame: { framerate: 30 },
      },
      canvasCss: `
        .gjs-selected {
          outline: 2px solid #4f647d !important;
          outline-offset: -2px;
        }
        .gjs-selected-parent {
          outline: 2px solid transparent !important;
        }
        .container {
          height: 80px;
          min-width: 60px;
          vertical-align: top;
        }
        .container-td {
          border-radius: 4px;
          border: 1px dashed #edb2bd;
          background-color: #FFF9FA;
          background-image: url(${placeHolder});
          background-size: 102px 36px;
          background-repeat: no-repeat;
          background-position: center;
        }
      `,
      plugins: [gjsNewsletter, grapesjsRTEExtensions],
      pluginsOpts: {
        [gjsNewsletter]: {
          blocks: "false",
        },
        [grapesjsRTEExtensions]: {
          base: {
            bold: true,
            italic: true,
            underline: true,
            strikethrough: true,
            link: false,
            wrapforstyle: false,
          },
          fonts: {
            fontSize: true,
            fontColor: false,
            hilite: false,
          },
          format: {
            heading1: false,
            heading2: false,
            heading3: false,
            heading4: false,
            heading5: false,
            heading6: false,
            paragraph: false,
            quote: false,
            clearFormatting: false,
          },
          subscriptSuperscript: true,
          indentOutdent: false,
          list: true,
          align: false,
          actions: false,
          undoredo: false,
          extra: false,
          darkColorPicker: true,
          maxWidth: "1500px",
        },
      },
      blockManager: {
        appendTo: document.querySelector("#editor-blocks"),
        draggable: "td, .container",
      },
      avoidInlineStyle: false,
      styleManager: {},
      traitManager: {
        appendTo: "#my-custom-trait-manager",
      },
      layerManager: {
        appendTo: "#my-custom-layer-manager",
      },
      domComponents: {
        disableTextInnerChilds: true,
      },
      commands: {},
      assetManager: {
        upload: false,
        uploadFile: async function (e) {
          const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formData.append("image", files[i]);
          }
          console.log("Uploading files:", files);
          console.log("Form Data:", formData);

          try {
            const response = await imageApi.uploadImage(formData);
            console.log(response, "Response of uploadImage");
            const [file] = response.data;

            console.log(file, "Uplaoded image data");
            assetManager.add({
              type: "image",
              src: file.url,
              name: file.name,
              id: file.id,
            });
            // await fetchImages();
            console.log("Image Upload Successful");
          } catch (error) {
            console.error("Error uploading image", error);
          }
        },
      },
    });

    dispatch(setEditor(grapesjsEditor));

    const fetchAndAddAssets = async () => {
      const modalContainer = document.querySelector(".gjs-mdl-container");

      if (modalContainer) {
        document.body.appendChild(modalContainer);
        modalContainer.style.zIndex = "1400"; // Ensure it's on top of the overlay
      }
      const response = await imageApi.getImage();
      const imageData = response.data.map((item) => ({
        type: "image",
        id: item.id,
        name: item.name,
        src: item.url,
      }));
      console.log(response.data, "FETCHED IMAGES");
      if (imageData.length > 0) {
        assetManager.add(imageData);
      }
    };

    grapesjsEditor.on("asset:open", fetchAndAddAssets);

    grapesjsEditor.on("asset:remove", async (asset) => {
      try {
        const assetId = asset.get("id");
        console.log("Asset ID:", assetId);
        assetManager.remove(asset);
        await imageApi.deleteImage({ ids: [assetId] });
      } catch (error) {
        console.error("Error deleting image", error);
      }
    });

    const styleManager = grapesjsEditor.StyleManager;
    const traitManager = grapesjsEditor.TraitManager;
    const blockManager = grapesjsEditor.BlockManager;
    const deviceManager = grapesjsEditor.DeviceManager;
    const assetManager = grapesjsEditor.AssetManager;
    const rte = grapesjsEditor.RichTextEditor;

    const handleLinkInput = () => {
      return new Promise((resolve, reject) => {
        const frame = grapesjsEditor.Canvas.getFrameEl();
        const doc = frame.contentDocument;
        const selection = doc.getSelection();
        const lastRange = selection.getRangeAt(0);
        if (lastRange) {
          selectedRangeRef.current = lastRange;

          const rect = lastRange.getBoundingClientRect();
          const tooltipX = rect.left + window.scrollX;
          const tooltipY = rect.top + window.scrollY - 50;

          setLinkTooltipPosition({ x: tooltipX, y: tooltipY });
          setLinkTooltipVisible(true);

          setLinkDataPromise({
            resolve,
            reject,
          });
        } else {
          reject("No text selected");
        }
      });
    };

    const handleFontSizeChange = (grapesjsEditor, newSize) => {
      const selectedComponent = grapesjsEditor.getSelected();
      if (selectedComponent) {
        selectedComponent.addStyle({ "font-size": newSize });
      }
    };

    const renderFontSizeSelect = (grapesjsEditor, toolbarEl) => {
      const fontSizeSpan = Array.from(toolbarEl.querySelectorAll("span")).find(
        (span) => span.title === "Font Size"
      );

      if (!fontSizeSpan) {
        return;
      }

      const el = document.createElement("div");
      fontSizeSpan.innerHTML = "";
      fontSizeSpan.appendChild(el);

      const MUISelect = () => {
        const [value, setValue] = React.useState("16px");

        const handleChange = (event) => {
          const newSize = event.target.value;
          setValue(newSize);
          handleFontSizeChange(grapesjsEditor, newSize);
        };

        const pixelSizes = Array.from({ length: 96 - 8 + 1 }, (_, i) => 8 + i);

        return (
          <FormControl sx={{ minWidth: 80 }}>
            <Select
              value={value}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {pixelSizes.map((size) => (
                <MenuItem key={size} value={`${size}px`}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };

      ReactDOM.render(<MUISelect />, el);
    };

    const handleColorChange = (newColor, commandName) => {
      dispatch(setColorPickerColor(newColor));

      const frame = grapesjsEditor.Canvas.getFrameEl();
      const doc = frame.contentDocument;

      if (lastRange) {
        const selection = doc.getSelection();
        selection.removeAllRanges();
        selection.addRange(lastRange);
      }

      doc.execCommand(commandName, false, newColor);
      lastRange = null;
      dispatch(closeColorPicker());
    };

    rte.add("fontColor", {
      icon: "<b>FS</b>",
      attributes: { title: "Font Color" },
      result: (rte) => {
        const frame = grapesjsEditor.Canvas.getFrameEl();
        const window = frame.contentWindow;
        const selection = window.getSelection();
        lastRange = selection.getRangeAt(0);
        dispatch(openColorPicker("foreColor"));
      },
    });

    rte.add("hiliteColor", {
      icon: "<b>HL</b>",
      attributes: { title: "Highlight Color" },
      result: (rte) => {
        const frame = grapesjsEditor.Canvas.getFrameEl();
        const window = frame.contentWindow;
        const selection = window.getSelection();
        lastRange = selection.getRangeAt(0);
        dispatch(openColorPicker("hiliteColor"));
      },
    });

    rte.add("hyperlink", {
      icon: "&#128279;",
      attributes: { title: "Hyperlink" },
      result: (rte) => {
        handleLinkInput()
          .then(({ title, href, target }) => {
            if (selectedRangeRef.current) {
              const frame = grapesjsEditor.Canvas.getFrameEl();
              const doc = frame.contentDocument;
              doc.getSelection().removeAllRanges();
              doc.getSelection().addRange(selectedRangeRef.current);

              rte.insertHTML(
                `<a class="link" title="${title}" href="${href}" target="${target}">${selectedRangeRef.current.toString()}</a>`
              );

              // Use document.execCommand to insert the link
              // document.execCommand(
              //   "insertHTML",
              //   false,
              //   `<a class="link" title="${title}" href="${href}" target="${target}">${selectedRangeRef.current.toString()}</a>`
              // );

              selectedRangeRef.current = null;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });

    rte.getToolbarEl().addEventListener("mousedown", () => {
      setLinkTooltipVisible(false);
    });

    const renderReactColorPicker = (commandName) => {
      const container = document.createElement("div");
      ReactDOM.render(
        <ColorPicker
          initialColor={colorPickerState.color}
          onChange={(color) => handleColorChange(color, commandName)}
          isGradient={true}
          brandColors={brandColors}
        />,
        container
      );
      return container;
    };

    grapesjsEditor.setComponents(`
      <table class='content-table' align="center" style="width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table">
        <tbody>
          <tr>
            <td style="width:100%;" class='container-td container' type="column"></td>
          </tr>
        </tbody>
      </table>
      <table class='content-table' align="center" style="width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table">
        <tbody>
          <tr style="height: 100%">
            <td style=" width: 50%;" class='container-td container' type="column"></td>
            <td style=" width: 50%;" class='container-td container' type="column"></td>
          </tr>
        </tbody>
      </table> 
    `);

    deviceManager.add({
      id: "mobile",
      name: "Mobile",
      width: "360px",
    });

    const desktop = deviceManager.get("Desktop");
    desktop.attributes.width = "100%";

    loadTemplate(grapesjsEditor);

    const getBrandStyles = async () => {
      try {
        const response = await ResourceApi.brandstyle.get();
        console.log(response, "Brand styles fetched when loaded");
        dispatch(setBrandStyles(response.data));
        dispatch(setBrandColors(response.data.colors));
      } catch (err) {
        console.error(err);
      }
    };

    getBrandStyles();

    customContainers.forEach((block) => {
      blockManager.add(block.name, {
        label: block.label,
        category: block.category,
        content: block.content,
      });
      categories[block.category].push(block.name);
    });

    registerTextBlock(grapesjsEditor);
    registerLogoBlock(grapesjsEditor);
    registerImageBlock(grapesjsEditor);
    registerButtonBlock(grapesjsEditor);
    registerDividerBlock(grapesjsEditor);
    registercountdownTimerBlock(grapesjsEditor);
    registerTableBlock(grapesjsEditor);
    registerMenuBlock(grapesjsEditor);
    registerSocialBlock(grapesjsEditor);
    registerFooterBlock(grapesjsEditor);
    registerHtmlBlock(grapesjsEditor);
    const getComponentAtPosition = (editor, mouseX, mouseY) => {
      if (!editor) {
        console.error("Editor is not initialized.");
        return null;
      }

      const wrapper = editor.getWrapper();
      if (!wrapper) {
        console.error("Wrapper is not available.");
        return null;
      }

      const components = wrapper.find("*");

      const isMouseInsideRect = (rect) => {
        return (
          mouseX >= rect.left &&
          mouseX <= rect.right &&
          mouseY >= rect.top &&
          mouseY <= rect.bottom
        );
      };

      for (let component of components) {
        const el = component.view.el;
        if (!el) continue;

        const rect = el.getBoundingClientRect();
        if (isMouseInsideRect(rect)) {
          if (el.classList.contains("container")) {
            return component;
          }
        }
      }
      return null;
    };

    let targetComponent = null;

    const setPlaceholderVisibility = (visible) => {
      const placeholders = document.querySelectorAll(
        ".gjs-placeholder, .gjs-placeholder-int"
      );
      placeholders.forEach((placeholder) => {
        if (visible) {
          placeholder.classList.remove("hidden");
        } else {
          placeholder.classList.add("hidden");
        }
      });
    };

    grapesjsEditor.on("block:drag", (block, event) => {
      if (!event) return;
      const category = block.category.attributes.id;

      const mouseX = event.clientX;
      const mouseY = event.clientY;

      targetComponent = getComponentAtPosition(grapesjsEditor, mouseX, mouseY);
      const targetContainerEl = targetComponent?.view.el;
      if (
        category === "Blocks" &&
        targetContainerEl?.classList.contains("container")
      ) {
        setPlaceholderVisibility(true);
      } else if (
        (category === "Containers" ||
          category === "Prebuilt" ||
          category === "Layouts") &&
        !targetComponent
      ) {
        setPlaceholderVisibility(true);
      } else {
        setPlaceholderVisibility(false);
      }
    });

    grapesjsEditor.on("block:drag:stop", (component, blockView) => {
      if (!component) return;

      const parent = component.collection?.parent;
      const parentClasses = parent?.getClasses();
      const isInsideContainer = parentClasses?.includes("container");
      const isInsideContainerDiv = parentClasses?.includes("container-div");

      if (blockView?.get("category").id === "Blocks") {
        if (
          parent?.attributes.tagName === "body" ||
          (!isInsideContainer && !isInsideContainerDiv)
        ) {
          component?.destroy();
          const body = grapesjsEditor.DomComponents.getWrapper();
          grapesjsEditor.select(body);
        } else if (
          component?.attributes.type === "Text" ||
          component?.attributes.type === "Footer"
        ) {
          grapesjsEditor.select(component.components().at(0));
        } else {
          grapesjsEditor.select(component);
          component?.components().forEach((child) => {
            applyNonSelectability(child);
          });
        }
      }

      if (blockView?.get("category").id === "Containers") {
        if (isInsideContainer) {
          component?.destroy();
          const body = grapesjsEditor.DomComponents.getWrapper();
          grapesjsEditor.select(body);
        } else {
          blockView?.model?.addStyle({ height: "80px" });
          grapesjsEditor.select(component);
        }
      }

      if (blockView?.get("category").id === "Prebuilt") {
        if (isInsideContainer) {
          component?.destroy();
          const body = grapesjsEditor.DomComponents.getWrapper();
          grapesjsEditor.select(body);
        } else {
          grapesjsEditor.select(component);
          component?.components().forEach((child) => {
            applyNonSelectability(child);
          });
        }
      }

      if (blockView?.get("category").id === "Layouts") {
        if (isInsideContainer) {
          component?.destroy();
          const body = grapesjsEditor.DomComponents.getWrapper();
          grapesjsEditor.select(body);
        } else {
          grapesjsEditor.select(component);
          component?.components().forEach((child) => {
            applyNonSelectability(child);
          });
        }
      }

      const parentComponent = component.parent();

      if (parentComponent?.getClasses().includes("container-td")) {
        parentComponent.removeClass("container-td");
        parentComponent.addStyle("height", "auto");
      }

      if (!parentComponent) return;

      const { type } = parentComponent.attributes.attributes;
      if (type === "row") {
        const contentTable = component.find(".content-table")[0];
        if (!contentTable) return;

        const contentTableClone = contentTable.clone();

        const containerDivTable = parentComponent.find(".container-div")[0];

        if (containerDivTable) {
          const index = parentComponent.components().indexOf(containerDivTable);
          containerDivTable.remove();
          parentComponent.components().add(contentTableClone, { at: index });
        }
      }
    });

    grapesjsEditor.on("load", () => {
      const allAssets = assetManager.getAll();
      allAssets.each((asset) => {
        assetManager.remove(asset);
      });

      setupAssetManagerButtonBehavior(grapesjsEditor);
      setTemplateLoading(false);
      const toolbarEl = rte.getToolbarEl();
      const order = [1, 2, 3, 4, 12, 10, 5, 11, 0, 9, 8, 7, 6];
      toolbarEl.firstChild.childNodes.forEach(
        (child, idx) => (child.style.order = order[idx])
      );

      renderFontSizeSelect(grapesjsEditor, toolbarEl);
      customizeRTEIcons();

      const fontColorSpan = Array.from(toolbarEl.querySelectorAll("span")).find(
        (span) => span.title === "Font Color"
      );
      if (fontColorSpan) {
        fontColorSpan.appendChild(renderReactColorPicker("foreColor"));
        const fontColorPicker = fontColorSpan.querySelector(".color-picker");
        fontColorPicker.style.border = "none";
        fontColorPicker.style.width = "18px";
        fontColorPicker.style.height = "18px";
        fontColorPicker.style.padding = "0";
        fontColorPicker.querySelector("span").style.display = "none";
        fontColorPicker.querySelector(".picker-button").style.height = "100% ";
        fontColorPicker.querySelector(".picker-button").style.width = "100% ";
        fontColorPicker.querySelector(".picker-button").style.margin = "0";
      }

      const hiliteSpan = Array.from(toolbarEl.querySelectorAll("span")).find(
        (span) => span.title === "Highlight Color"
      );
      if (hiliteSpan) {
        hiliteSpan.appendChild(renderReactColorPicker("hiliteColor"));
        const hiliteColorPicker = hiliteSpan.querySelector(".color-picker");
        hiliteColorPicker.style.border = "none";
        hiliteColorPicker.style.width = "18px";
        hiliteColorPicker.style.height = "18px";
        hiliteColorPicker.style.padding = "0";
        hiliteColorPicker.querySelector("span").style.display = "none";
        hiliteColorPicker.querySelector(".picker-button").style.height =
          "100% ";
        hiliteColorPicker.querySelector(".picker-button").style.width = "100% ";
        hiliteColorPicker.querySelector(".picker-button").style.margin = "0";
      }

      const defaultPanels = grapesjsEditor.Panels.getPanels();
      defaultPanels.forEach((panel) => {
        if (panel.id !== "views-container") {
          panel.set("visible", false);
        }
      });

      moveBlocksToDivs(grapesjsEditor);
      const editorBody = grapesjsEditor.Canvas.getBody();
      editorBody?.setAttribute("style", `background-color: ${bodyBgColor}`);

      const blocks =
        grapesjsEditor.Canvas.getDocument().querySelectorAll("span.block-div");
      blocks.forEach((block) => {
        const component = grapesjsEditor.DomComponents.getWrapper().find(
          `#${block.id}`
        )[0];
        if (
          component?.attributes.type === "Text" ||
          component?.attributes.type === "Footer"
        ) {
          component.set({
            selectable: true,
            hoverable: true,
            editable: true,
          });
          component.removeClass("non-selectable");
          component.removeAttributes("data-non-selectable");
        } else {
          applyNonSelectability(component);
        }
      });
      const body = grapesjsEditor.DomComponents.getWrapper();
      grapesjsEditor.select(body);
      dispatch(setTemplateSaved(true));
    });

    grapesjsEditor.on("component:update", () => {
      dispatch(setTemplateSaved(false));
    });

    setupAssetManagerButtonBehavior(grapesjsEditor);
    grapesjsEditor.on("component:selected", (selectedComponent) => {
      dispatch(togglePropertyContainer(true));
      styleManager.clear();
      styleManager.addSector("Properties", {
        name: "Properties",
        open: true,
      });

      if (selectedComponent.attributes.tagName === "body") {
        dispatch(togglePropertyContainer(false));
        selectedComponent.set("traits", []);
      } else {
        const menuIcon = document.querySelector(".icon-more");
        if (menuIcon) {
          menuIcon.addEventListener("click", (event) => {
            const rect = menuIcon.getBoundingClientRect();
            setMenuAnchor({
              top: rect.bottom,
              left: rect.right,
            });
          });
        }

        selectedComponent.set({
          toolbar: [
            {
              attributes: {
                class: "icon-move icon",
                style: `background: url(${iconMove}) no-repeat center center; background-size: contain; width: 24px; height: 24px;`,
                title: "Move",
              },
              command: "tlb-move",
            },
            {
              attributes: {
                class: "icon-more icon",
                style: `background: url(${iconMore}) no-repeat center center; background-size: contain; width: 24px; height: 24px;`,
                title: "More",
              },
              command: "open-menu",
            },
          ],
        });

        const selectedBlock = selectedComponent?.parent()?.attributes.type;

        let properties = [];

        if (selectedComponent.views[0]?.attr.type === "section-table") {
          document.querySelector(".block-name").textContent = "Section";
          renderSectionTableTraitManager(selectedComponent, grapesjsEditor);
        }

        if (selectedComponent.views[0]?.attr.type === "column") {
          document.querySelector(".block-name").textContent = "Column";
          renderColumnTraitManager(selectedComponent, grapesjsEditor);
        }

        if (
          selectedComponent?.parent()?.parent()?.parent()?.parent()?.attributes
            ?.type === "Table"
        ) {
          document.querySelector(".block-name").textContent = "Table";
          renderTableTraitManager(selectedComponent, grapesjsEditor);
        }

        switch (selectedBlock) {
          case "Text":
            document.querySelector(".block-name").textContent = "Text";
            renderTextBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Footer":
            document.querySelector(".block-name").textContent = "Footer";
            renderFooterBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          default:
            break;
        }

        switch (selectedComponent.attributes.type) {
          case "Image":
            document.querySelector(".block-name").textContent = "Image";
            renderImageBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Logo":
            document.querySelector(".block-name").textContent = "Site Logo";
            renderLogoBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Divider":
            document.querySelector(".block-name").textContent = "Divider";
            renderDividerBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Timer":
            document.querySelector(".block-name").textContent = "Timer";
            renderTimerTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Table":
            document.querySelector(".block-name").textContent = "Table";
            renderTableTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Menu":
            document.querySelector(".block-name").textContent = "Menu";
            renderMenuTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Social":
            document.querySelector(".block-name").textContent = "Social";
            renderSocialTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "Button":
            document.querySelector(".block-name").textContent = "Button";
            renderButtonBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          case "HTML":
            document.querySelector(".block-name").textContent = "HTML";
            renderHTMLBlockTraitManager(selectedComponent, grapesjsEditor);
            break;
          default:
            break;
        }

        properties.forEach((property) => {
          styleManager.addProperty("Properties", property);
        });
      }
    });

    traitManager.addType("padding-control", {
      createInput({ trait }) {
        const el = document.createElement("div");
        el.className = "paddingContainer lock-trait-container";

        const selectedComponent = grapesjsEditor.getSelected();
        ["top", "right", "bottom", "left"].forEach((side) => {
          const div = document.createElement("div");
          div.className = "padding-box lock-input-box";

          const label = document.createElement("div");
          label.className = "padding-label lock-input-label";
          label.textContent = side;

          const input = document.createElement("input");
          input.className = "padding-input lock-input";
          input.type = "number";
          input.id = `padding-${side}`;
          input.setAttribute("data-side", `padding-${side}`);
          input.min = 0;
          if (selectedComponent?.parent()?.attributes.type === "Text") {
            input.value =
              trait.target.getStyle(`padding-${side}`)?.slice(0, -2) || 0;
          } else {
            input.value =
              trait.target.getStyle(`padding-${side}`)?.slice(0, -2) || 0;
          }

          div.appendChild(input);
          div.appendChild(label);
          el.appendChild(div);
        });

        const lockButton = document.createElement("button");
        lockButton.className = "padding-lock-btn lock-btn locked";
        lockButton.id = "toggle-lock";
        lockButton.textContent = "";
        el.appendChild(lockButton);

        let isLocked;
        if (selectedComponent.views[0]?.attr.type === "row") {
          isLocked = rowPaddingLock;
        } else {
          isLocked = true;
        }

        function updatePadding(id, value) {
          const selected = grapesjsEditor.getSelected();
          if (selected) {
            let style = {};
            style[id] = `${value}px`;
            selected.addStyle(style);
          }
        }

        function handleInputChange(event) {
          const paddingValue = event.target.value;
          const paddingId = event.target.getAttribute("data-side");

          if (isLocked) {
            ["top", "right", "bottom", "left"].forEach((side) => {
              const id = `padding-${side}`;
              el.querySelector(`#${id}`).value = paddingValue;
              updatePadding(id, paddingValue);
            });
          } else {
            updatePadding(paddingId, paddingValue);
          }
        }

        el.querySelectorAll(".padding-input").forEach((input) => {
          input.addEventListener("input", handleInputChange);
        });

        lockButton.addEventListener("click", function () {
          isLocked = !isLocked;
          this.textContent = isLocked ? "" : "";
          this.classList.toggle("locked");
          this.classList.toggle("unlocked");
          if (selectedComponent.views[0]?.attr.type === "row") {
            dispatch(toggleRowPaddingLock());
          }
        });

        return el;
      },
    });

    grapesjsEditor.Commands.add("open-menu", {
      run(editor, sender, opts) {
        const selected = editor.getSelected();
        if (selected) {
          const menuIcon = document.querySelector(".icon-more");
          if (menuIcon) {
            const rect = menuIcon.getBoundingClientRect();
            setMenuAnchor({
              top: rect.bottom,
              left: rect.right,
            });
          }
        }
      },
    });

    grapesjsEditor.Commands.add("clone-component", {
      run(editor, sender, options) {
        const selectedComponent = editor.getSelected();
        if (selectedComponent) {
          const parent = selectedComponent.parent();
          if (parent) {
            const clonedComponent = selectedComponent.clone();
            parent.append(clonedComponent);
            editor.select(clonedComponent);
          }
        }
      },
    });

    grapesjsEditor.Commands.add("delete", {
      run(editor, sender, opts) {
        const selected = editor.getSelected();
        const components = editor.DomComponents.getComponents();

        if (components.length > 1) {
          if (selected) {
            selected.remove();
          }
        } else {
          alert("You cannot delete the last element!");
        }
      },
    });

    grapesjsEditor.Commands.add("core:component-delete", {
      run: (editor) => editor.runCommand("delete"),
    });

    registerTraits(traitManager, grapesjsEditor, brandColors, dispatch);

    return () => {
      editor && editor.destroy();
    };
  }, []);

  useEffect(() => {
    const blockManager = editor?.BlockManager;

    if (blockManager) {
      blockManager.getAll().forEach((block) => {
        if (block.attributes.category === "Layouts") {
          blockManager.remove(block.id);
        }
      });

      customLayouts.forEach((layout) => {
        blockManager.add(layout.name, {
          label: layout.label,
          category: layout.category,
          content: layout.content,
          render: ({ model, el }) => {
            const blockWrapper = document.createElement("div");
            blockWrapper.className = "block-wrapper";
            const deleteBtn = document.createElement("button");
            deleteBtn.className = "btn delete-btn";
            deleteBtn.innerHTML = `<img src=${iconDelete} height='20px' width='20px'/>`;
            blockWrapper.appendChild(deleteBtn);

            deleteBtn.addEventListener("click", () => {
              setLayoutToDelete(layout.name);
              setLayoutId(layout.id);
              setDeleteLayoutDialog(true);
            });

            el.appendChild(blockWrapper);
          },
        });
      });

      prebuiltLayouts.forEach((block) => {
        blockManager.add(block.id, {
          label: block.label,
          category: block.category,
          content: block.content,
        });
        categories[block.category].push(block.name);
      });
    }

    window.addEventListener("beforeunload", function (e) {
      if (!isTemplateSaved) {
        const confirmationMessage =
          "You have unsaved changes. Are you sure you want to leave this page?";
        e.preventDefault();
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    });

    moveBlocksToDivs(editor);
  }, [customLayouts, editor, prebuiltLayouts, isTemplateSaved]);

  useEffect(() => {
    if (editor) {
      registerTextBlock(editor, brandStyles);
      registerLogoBlock(editor, brandStyles);
      registerButtonBlock(editor, brandStyles);
      registerMenuBlock(editor, brandStyles);
      registerFooterBlock(editor, brandStyles);
      registerSocialBlock(editor, brandStyles);
      registerTableBlock(editor, brandStyles);
    }
  }, [brandStyles, dispatch]);

  const handleDeleteLayout = useCallback(
    async (editor) => {
      console.log("Deleting layout", layoutToDelete);
      if (editor && layoutToDelete && layoutId) {
        editor?.BlockManager.remove(layoutToDelete);
        setDeleteLayoutDialog(false);

        try {
          console.log("Removing layout", layoutToDelete);
          const response = await template.deleteTemplate({
            ids: [layoutId],
          });

          const updatedData = layoutData.filter(
            (layout) => layout.id !== layoutId
          );
          dispatch(setLayoutData(updatedData));
        } catch (error) {
          console.log(error);
          setDeleteLayoutDialog(false);
        }
      }
    },
    [editor, layoutToDelete, layoutId, layoutData]
  );

  const loadTemplate = async (editorInstance) => {
    try {
      const response = await template.getTemplateById(templateId);
      const { htmlContent, name } = response.data;
      dispatch(setCurrentTemplateName(name));
      editorInstance.setComponents(htmlContent);

      const blocks =
        editorInstance.Canvas.getDocument().querySelectorAll("span.block-div");
      blocks.forEach((block) => {
        const component = editorInstance.DomComponents.getWrapper().find(
          `#${block.id}`
        )[0];
        if (
          component?.attributes.type === "Text" ||
          component?.attributes.type === "Footer"
        ) {
          component.set({
            selectable: true,
            hoverable: true,
            editable: true,
          });
          component.removeClass("non-selectable");
          component.removeAttributes("data-non-selectable");
        } else {
          applyNonSelectability(component);
        }
      });

      editorInstance
        .getWrapper()
        .find("a")
        .forEach((link) => {
          link.set({ "data-gjs-type": "link" });
        });

      dispatch(setTemplateSaved(true));
    } catch (err) {
      console.log(err);
    }
  };

  const handleApplyLink = (data) => {
    if (linkDataPromise) {
      linkDataPromise.resolve(data);
      setLinkTooltipVisible(false);
      setLinkDataPromise(null);
    }
  };

  const handleCancelLink = () => {
    if (linkDataPromise) {
      linkDataPromise.reject("User canceled link input");
      setLinkTooltipVisible(false);
      setLinkDataPromise(null);
    }
  };

  const closeTemplateLayout = () => {
    setTemplateLayout(false);
  };

  const tabs = ["Pre-Built Templates", "My Templates"];

  const prebuiltCards = prebuiltLayoutData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      @media only screen and (max-width:768px){
        .container{
          float: left !important;
          width: 100% !important;
        }
      }
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  const savedCards = templateData.map((template, index) => {
    return {
      name: template.name,
      content: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>
      @media only screen and (max-width:768px){
        .container{
          float: left !important;
          width: 100% !important;
        }
      }
      </style>
    </head>
    <body>
      ${template.htmlContent}
    </body>
    </html>
  `,
      showPreviewButton: true,
      id: template.id,
    };
  });

  return (
    <Provider store={store}>
      {isSaveTemplateDialog ? <SaveTemplateDialog editor={editor} /> : ""}
      {isCreateLayoutDialog ? <CreateLayoutDialog editor={editor} /> : ""}
      {deleteLayoutDialog && (
        <CustomDialog
          open={deleteLayoutDialog}
          title={"Delete Layout"}
          actions={[
            {
              label: "Cancel",
              onClick: () => setDeleteLayoutDialog(false),
              classes: "btn btn-outline dark-border",
            },
            {
              label: "Delete",
              onClick: () => handleDeleteLayout(editor),
              classes: "btn btn-delete dark-border",
            },
          ]}
          content={
            "Once you delete this layout, it will no longer be available."
          }
          onClose={() => setDeleteLayoutDialog(false)}
        />
      )}
      {templateLayout && (
        <CommonOverlay open={templateLayout} onClose={closeTemplateLayout}>
          <div className="template-layout-wrapper">
            <Header
              title={"Templates"}
              actions={[
                {
                  icon: iconClose,
                  onClick: () => setTemplateLayout(false),
                  classes: "btn common-btn",
                  isPrimary: false,
                },
              ]}
            />
            <div className="tabs">
              <DynamicTabs
                tabs={tabs}
                panels={[
                  <>
                    <div className="prebuilt-template-wrapper">
                      <div className="prebuilt-template-sidebar">
                        <CategoryFilter />
                      </div>
                      <div className="prebuilt-template-card-container">
                        <CardContainer
                          cards={prebuiltCards}
                          isStartFromBlank={false}
                          setTemplateLayout={setTemplateLayout}
                        />
                      </div>
                    </div>
                  </>,
                  <>
                    <div className="prebuilt-template-wrapper">
                      <div className="prebuilt-template-card-container">
                        <CardContainer
                          cards={savedCards}
                          isStartFromBlank={false}
                          setTemplateLayout={setTemplateLayout}
                        />
                      </div>
                    </div>
                  </>,
                ]}
              />
            </div>
          </div>
        </CommonOverlay>
      )}
      {templateLoading && <Progress />}

      <EmailEditorHeader
        editor={editor}
        setTemplateLayout={setTemplateLayout}
        templateLoading={templateLoading}
      />
      <div
        className="editor-body"
        style={{ visibility: templateLoading ? "hidden" : "visible" }}
      >
        <div className="side-toolbar">
          <EditorBlocks />
        </div>
        <div className="canvas">
          <GrapeJSEditor editorRef={editorRef} />
          {isLinkTooltipVisible && (
            <div
              style={{
                position: "absolute",
                top: `${linkTooltipPosition.y}px`,
                left: `${linkTooltipPosition.x}px`,
                zIndex: 1000,
              }}
            >
              <LinkTooltip
                onApply={handleApplyLink}
                onCancel={handleCancelLink}
              />
            </div>
          )}
        </div>
        <div className="block-properties">
          <BlockProperties editor={editor} />
        </div>
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={
            menuAnchor ? { top: menuAnchor.top, left: menuAnchor.left } : null
          }
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "menu-button",
          }}
        >
          <MenuItem onClick={() => handleMenuOptionClick("Duplicate")}>
            Duplicate
          </MenuItem>
          <MenuItem onClick={() => handleMenuOptionClick("Save as Layout")}>
            Save as Layout
          </MenuItem>
          <MenuItem onClick={() => handleMenuOptionClick("Delete")}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    </Provider>
  );
};

export default EmailBuilder;
