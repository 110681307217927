import React from 'react';
import Card from '@mui/material/Card';
import {Handle} from 'reactflow';
// import {
//  Avatar,
//  CardHeader,
//  Chip,
//  Grid,
//  IconButton,
//  Typography
// } from '@mui/material';
// import {red} from '@mui/material/colors';
// import iconMore from '../../../assets/icons/icon-more.svg';
// import iconTag from '../../../assets/icons/icon-tag-automation.svg';
// import iconClose from '../../../assets/icons/icon-cross.svg';
// import iconEmail from '../../../assets/icons/icon-email.svg';
// import iconTime from '../../../assets/icons/icon-time.svg';
// import iconElse from '../../../assets/icons/icon-Condition.svg';
// import iconSplit from '../../../assets/icons/icon-AB.svg';
// import iconContact from '../../../assets/icons/icon-contact-status.svg';
// import iconList from '../../../assets/icons/icon-order-listing.svg';
// import iconSkip from '../../../assets/icons/icon-skip-step.svg';
// import iconGoal from '../../../assets/icons/icon-target.svg';
// import iconExit from '../../../assets/icons/icon-exit.svg';
import iconDoThis from '../../../assets/icons/icon-dothis.svg';

const Node = ({data}) => {
 // Sample chip data array
 //  const bodyData = [
 //   {label: 'Active', data: 20},
 //   {label: 'Active', data: 20},
 //   {label: 'Active', data: 20}
 //  ];

 return (
  <>
   <Card
    sx={{
     boxShadow: 'none'
    }}
   >
    {data?.type === 'dot' ? (
     <div style={{border: '1px solid black'}}></div>
    ) : (
     <div
      style={{
       display: 'flex',
       gap: '4px',
       alignItems: 'center'
      }}
      className="status SUCCESS"
     >
      <img src={iconDoThis} alt="dothis" /> <div>{data.label}</div>
     </div>
    )}

    {/* <Handle
     type="source"
     position="bottom"
     id="source-handle"
     style={{background: '#555'}}
    /> */}
    <Handle
     type="target"
     position="top"
     id="target-handle"
     style={{background: '#555'}}
    />
    {data?.type === 'conditional' && (
     <Handle
      type="source"
      position="bottom"
      id="source-handle"
      style={{background: '#555'}}
     />
    )}
    {data?.type === 'dot' && (
     <Handle type="source" position="bottom" id="source-handle" />
    )}
   </Card>
  </>
 );
};

export default Node;
