import * as React from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import {
  Button,
  Tooltip,
  IconButton,
  Checkbox,
  Paper,
  Typography,
  Toolbar,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  tableCellClasses,
  TableBody,
  Table,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import DeleteIcon from "../../../../assets/icons/icon-delete.svg";
import iconMore from "../../../../assets/icons/icon-more.svg";
import { visuallyHidden } from "@mui/utils";
import { template } from "../../../../api/templateapi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setCurrentTemplateId,
  setCurrentTemplateName,
  setSelectedTemplates,
  toggleDeleteTemplateDialog,
  toggleDuplicateTemplateLoader,
  toggleRenameTemplateDialog,
} from "../../../../store/store";
import { useTemplates } from "../../../../hooks/useTemplates.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "updated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
  },
  {
    id: "contact",
    numeric: false,
    disablePadding: false,
    label: "Contact",
  },
  {
    id: "sent",
    numeric: true,
    disablePadding: false,
    label: "Sent",
  },
  {
    id: "open",
    numeric: true,
    disablePadding: false,
    label: "Open",
  },
  {
    id: "clicked",
    numeric: true,
    disablePadding: false,
    label: "Clicked",
  },
  {
    id: "unsubscribed",
    numeric: true,
    disablePadding: false,
    label: "Unsubscribed",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const sortableColumns = ["created", "updated"];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="table-head"
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {sortableColumns.includes(headCell.id) ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, deleteMultiple } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} selected
      </Typography>

      <Tooltip title="Delete">
        <IconButton onClick={deleteMultiple}>
          <img src={DeleteIcon} alt="Delete" />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  deleteMultiple: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuRowId, setMenuRowId] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  const { templateData, fetchTemplates } = useTemplates();
  const [filteredTemplates, setFilteredTemplates] = React.useState(templateData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  React.useEffect(() => {
    setFilteredTemplates(
      templateData.filter((template) =>
        template.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [templateData, searchQuery]);

  const handleMenuOpen = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredTemplates.map((n) => n.id);
      setSelected(newSelected);
      dispatch(setSelectedTemplates(newSelected));
      return;
    }
    setSelected([]);
    dispatch(setSelectedTemplates([]));
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    dispatch(setSelectedTemplates(newSelected));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDeleteDialog = () => {
    if (menuRowId) {
      const selectedRow = filteredTemplates.find((row) => row.id === menuRowId);
      dispatch(toggleDeleteTemplateDialog());
      dispatch(setCurrentTemplateId(selectedRow.id));
    }
  };

  const openRenameDialog = () => {
    if (menuRowId) {
      const selectedRow = filteredTemplates.find((row) => row.id === menuRowId);
      dispatch(toggleRenameTemplateDialog());
      dispatch(setCurrentTemplateId(selectedRow.id));
      dispatch(setCurrentTemplateName(selectedRow.name));
    }
  };

  const duplicateTemplate = async () => {
    if (menuRowId) {
      const selectedRow = filteredTemplates.find((row) => row.id === menuRowId);
      if (selectedRow) {
        try {
          dispatch(toggleDuplicateTemplateLoader());
          const response = await template.cloneTemplate({
            id: selectedRow.id,
            name: `${selectedRow.name}-Copy`,
          });
          await fetchTemplates();
          console.log(
            "Data fetched from MyTemplateListing.js after duplication"
          );
          console.log("Cloned", response.data);
        } catch (e) {
          console.log(e);
        } finally {
          dispatch(toggleDuplicateTemplateLoader());
        }
      }
    }
  };

  const openTemplate = () => {
    if (menuRowId) {
      const selectedRow = filteredTemplates.find((row) => row.id === menuRowId);
      if (selectedRow) {
        navigate(`/email-builder/${selectedRow.name}/${selectedRow.id}`);
      }
      dispatch(setCurrentTemplateId(selectedRow.id));
      dispatch(setCurrentTemplateName(selectedRow.name));
    }
  };

  const deleteMultiple = async () => {
    dispatch(toggleDeleteTemplateDialog());
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredTemplates.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredTemplates, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredTemplates]
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {selected.length > 0 ? (
            <EnhancedTableToolbar
              numSelected={selected.length}
              deleteMultiple={deleteMultiple}
            />
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <SearchBar
                value={searchQuery}
                onChange={(newValue) => setSearchQuery(newValue)}
                onCancelSearch={() => setSearchQuery("")}
                style={{ margin: "16px", flexGrow: 1 }}
              /> */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTemplates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredTemplates.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                    className="table-row table-checkbox"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell 
                      className="table-checkbox"
                        padding="checkbox"
                        onClick={(event) => handleCheckboxClick(event, row.id)}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                      className="table-cell bold"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                      <div className="menu-and-text">
                        <Button className="btn btn-icon"
                          onClick={(event) => handleMenuOpen(event, row.id)}
                        >
                          <img src={iconMore} alt="More" className="icon"/>
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          className="menu-list"
                        >
                          <MenuItem
                            onClick={() => {
                              openTemplate();
                              handleMenuClose();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setMenuRowId(row.id);
                              openRenameDialog();
                              handleMenuClose();
                            }}
                          >
                            Rename
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              duplicateTemplate();
                              handleMenuClose();
                            }}
                          >
                            Duplicate
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setMenuRowId(row.id);
                              openDeleteDialog();
                              handleMenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                        <div
                          onClick={() => {
                            setMenuRowId(row.id);
                            openTemplate();
                          }}
                          style={{ cursor: "pointer", display: "inline" }}
                        >
                          {row.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="table-cell" align="right">{row.created}</TableCell>
                      <TableCell className="table-cell" align="right">{row.updated}</TableCell>
                      <TableCell align="right" className="table-cell bold" >{row.contact}</TableCell>
                      <TableCell align="right" className="table-cell bold" >{row.sent}</TableCell>
                      <TableCell className="table-cell bold" align="right">{row.open}</TableCell>
                      <TableCell className="table-cell bold" align="right">{row.clicked}</TableCell>
                      <TableCell className="table-cell bold" align="right">{row.unsubscribed}</TableCell>
                      <TableCell align="right" >
                        <Button className="btn-grey btn">Preview</Button>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <StyledTableCell colSpan={10} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
