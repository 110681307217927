import { createStore } from "redux";

// Action Types
const EDITOR = 'EDITOR';
const TOGGLE_CONTAINER_POPUP = 'TOGGLE_CONTAINER_POPUP';
const TOGGLE_PREBUILT_POPUP = 'TOGGLE_PREBUILT_POPUP';
const TOGGLE_DEVICE = 'TOGGLE_DEVICE';
const TOGGLE_PREVIEW_DEVICE = 'TOGGLE_PREVIEW_DEVICE';
const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
const TOGGLE_RIGHT_CONTAINER = 'TOGGLE_RIGHT_CONTAINER';
const CHANGE_ROW_BACKGROUND_COLOR = 'CHANGE_ROW_BACKGROUND_COLOR';
const OPEN_COLOR_PICKER = 'OPEN_COLOR_PICKER';
const CLOSE_COLOR_PICKER = 'CLOSE_COLOR_PICKER';
const SET_COLOR_PICKER_COLOR = 'SET_COLOR_PICKER_COLOR';
const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW';
const TOGGLE_BRAND_STYLE = 'TOGGLE_BRAND_STYLE';
const TOGGLE_LAYER = 'TOGGLE_LAYER';
const TEXT_BLOCK_PADDING_LOCK = 'TEXT_BLOCK_PADDING_LOCK';
const ROW_PADDING_LOCK = 'ROW_PADDING_LOCK';
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const CURRENT_PAGE = 'CURRENT_PAGE';
const TOGGLE_CREATE_TEMPLATE_DIALOG = 'TOGGLE_CREATE_TEMPLATE_DIALOG';
const TOGGLE_DELETE_TEMPLATE_DIALOG = 'TOGGLE_DELETE_TEMPLATE_DIALOG';
const TOGGLE_RENAME_TEMPLATE_DIALOG = 'TOGGLE_RENAME_TEMPLATE_DIALOG';
const TOGGLE_SAVE_TEMPLATE_DIALOG = 'TOGGLE_SAVE_TEMPLATE_DIALOG';
const TOGGLE_CREATE_LAYOUT_DIALOG = 'TOGGLE_CREATE_LAYOUT_DIALOG';
const TOGGLE_DUPLICATE_TEMPLATE_LOADER = 'TOGGLE_DUPLICATE_TEMPLATE_LOADER';
const TOGGLE_CREATE_TEMPLATE_LOADER = 'TOGGLE_CREATE_TEMPLATE_LOADER';
const TOGGLE_DELETE_TEMPLATE_LOADER = 'TOGGLE_DELETE_TEMPLATE_LOADER';
const TOGGLE_RENAME_TEMPLATE_LOADER = 'TOGGLE_RENAME_TEMPLATE_LOADER';
const TOGGLE_SAVE_TEMPLATE_LOADER = 'TOGGLE_SAVE_TEMPLATE_LOADER';
const TOGGLE_CREATE_LAYOUT_LOADER = 'TOGGLE_CREATE_LAYOUT_LOADER';
const SELECTED_TEMPLATES = 'SELECTED_TEMPLATES';
const CURRENT_TEMPLATE_ID = 'CURRENT_TEMPLATE_ID';
const CURRENT_TEMPLATE_NAME = 'CURRENT_TEMPLATE_NAME';
const CURRENT_LAYOUT_NAME = 'CURRENT_LAYOUT_NAME';
const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
const TEMPLATE_DATA = 'TEMPLATE_DATA';
const IMAGE_DATA = 'IMAGE_DATA';
const LAYOUT_DATA = 'LAYOUT_DATA';
const CUSTOM_LAYOUTS = 'CUSTOM_LAYOUTS';
const PREBUILT_LAYOUT_DATA = 'PREBUILT_LAYOUT_DATA';
const PREBUILT_LAYOUTS = 'PREBUILT_LAYOUTS';
const PREBUILT_TEMPLATE_DATA = 'PREBUILT_TEMPLATE_DATA';
const PREBUILT_TEMPLATES = 'PREBUILT_TEMPLATES';
const TEMPLATE_SAVED = 'TEMPLATE_SAVED';
const NAME_UPDATE = 'NAME_UPDATE';
const BRAND_STYLES = 'BRAND_STYLES';
const BRAND_COLORS = 'BRAND_COLORS';
// Action Creators
export const setEditor = (val) => ({
  type: EDITOR,
  payload: val,
});

export const setBrandStyles = (val) => ({
  type: BRAND_STYLES,
  payload: val,
});

export const setBrandColors = (val) => ({
  type: BRAND_COLORS,
  payload: val,
});

export const setSelectedTemplates = val => ({
 type: SELECTED_TEMPLATES,
 payload: val
});

export const setTemplateData = val => ({
 type: TEMPLATE_DATA,
 payload: val
});

export const setImageData = (val) => ({
  type: IMAGE_DATA,
  payload: val,
})

export const setLayoutData = (val) => ({
  type: LAYOUT_DATA,
  payload: val,
});

export const setCustomLayouts = val => ({
 type: CUSTOM_LAYOUTS,
 payload: val
});

export const setPrebuiltLayoutData = val => ({
 type: PREBUILT_LAYOUT_DATA,
 payload: val
});

export const setPrebuiltLayouts = val => ({
 type: PREBUILT_LAYOUTS,
 payload: val
});

export const setPrebuiltTemplates = val => ({
 type: PREBUILT_TEMPLATES,
 payload: val
});

export const setPrebuiltTemplateData = (val) => ({
  type: PREBUILT_TEMPLATE_DATA,
  payload: val,
});

export const setCurrentPage = val => ({
 type: CURRENT_PAGE,
 payload: val
});

export const setCurrentTemplateId = val => {
 // Save to local storage
 if (val) {
  localStorage.setItem('currentTemplateId', val);
 } else {
  localStorage.removeItem('currentTemplateId');
 }
 return {
  type: CURRENT_TEMPLATE_ID,
  payload: val
 };
};

export const setCurrentTemplateName = (val) => ({
  type: CURRENT_TEMPLATE_NAME,
  payload: val,
});

export const setCurrentLayoutName = (val) => ({
  type: CURRENT_LAYOUT_NAME,
  payload: val,
});

export const setIsAuthenticated = (val) => ({
  type: AUTHENTICATED_USER,
  payload: val,
});

export const toggleCreateTemplateDialog = () => ({
  type: TOGGLE_CREATE_TEMPLATE_DIALOG,
});

export const toggleCreateTemplateLoader = () => ({
  type: TOGGLE_CREATE_TEMPLATE_LOADER,
});

export const setTemplateSaved = (val) => ({
  type: TEMPLATE_SAVED,
  payload: val,
});

export const toggleNameUpdated = () => ({
 type: NAME_UPDATE
});
export const toggleCreateLayoutDialog = () => ({
  type: TOGGLE_CREATE_LAYOUT_DIALOG,
});

export const toggleCreateLayoutLoader = () => ({
  type: TOGGLE_CREATE_LAYOUT_LOADER,
});

export const toggleDeleteTemplateDialog = () => ({
  type: TOGGLE_DELETE_TEMPLATE_DIALOG,
});

export const toggleDeleteTemplateLoader = () => ({
  type: TOGGLE_DELETE_TEMPLATE_LOADER,
});

export const toggleRenameTemplateDialog = () => ({
  type: TOGGLE_RENAME_TEMPLATE_DIALOG,
});

export const toggleRenameTemplateLoader = () => ({
  type: TOGGLE_RENAME_TEMPLATE_LOADER,
});

export const toggleSaveTemplateDialog = () => ({
  type: TOGGLE_SAVE_TEMPLATE_DIALOG,
});

export const toggleSaveTemplateLoader = () => ({
  type: TOGGLE_SAVE_TEMPLATE_LOADER,
});

export const toggleDuplicateTemplateLoader = () => ({
  type: TOGGLE_DUPLICATE_TEMPLATE_LOADER,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const toggleLayer = (state) => ({
  type: TOGGLE_LAYER,
  payload: state,
});

export const togglePreview = () => ({
  type: TOGGLE_PREVIEW,
});

export const toggleBrandStyle = () => ({
  type: TOGGLE_BRAND_STYLE,
});

export const toggleTextBlockPaddingLock = () => ({
  type: TEXT_BLOCK_PADDING_LOCK,
});

export const toggleRowPaddingLock = () => ({
  type: ROW_PADDING_LOCK,
});

export const openColorPicker = (commandName) => ({
  type: OPEN_COLOR_PICKER,
  payload: commandName,
});

export const closeColorPicker = () => ({
  type: CLOSE_COLOR_PICKER,
});

export const setColorPickerColor = (color) => ({
  type: SET_COLOR_PICKER_COLOR,
  payload: color,
});

export const toggleContainerPopup = () => ({
  type: TOGGLE_CONTAINER_POPUP,
});

export const togglePrebuiltPopup = () => ({
  type: TOGGLE_PREBUILT_POPUP,
});

export const toggleDevice = () => ({
  type: TOGGLE_DEVICE,
});

export const togglePreviewDevice = () => ({
  type: TOGGLE_PREVIEW_DEVICE,
});

export const toggleLayout = () => ({
  type: TOGGLE_LAYOUT,
});

export const changeRowColor = (color) => ({
  type: CHANGE_ROW_BACKGROUND_COLOR,
  payload: color,
});

export const togglePropertyContainer = (state) => ({
  type: TOGGLE_RIGHT_CONTAINER,
  payload: state,
});
// Initial State
const initialState = {
 editor: null,
 containerPopup: false,
 prebuiltPopup: false,
 onMobile: false,
 isAuthenticated: !!window.localStorage.getItem('loginToken'),
 brandStyles: {},
 brandColors: ['#ff385c'],
 isLayer: false,
 isPreview: false,
 isBrandStyle: false,
 isPreviewMobile: false,
 isLayout: false,
 isSidebar: true,
 isCreateTemplateDialog: false,
 isCreateTemplateLoader: false,
 isNameUpdated: false,
 isCreateLayoutDialog: false,
 isCreateLayoutLoader: false,
 isDeleteTemplateDialog: false,
 isDeleteTemplateLoader: false,
 isRenameTemplateDialog: false,
 isRenameTemplateLoader: false,
 isSaveTemplateDialog: false,
 isSaveTemplateLoader: false,
 isDuplicateTemplateLoader: false,
 isTemplateSaved: true,
 currentTemplateId: localStorage.getItem('currentTemplateId') || '',
 currentTemplateName: '',
 currentLayoutName: '',
 templateData: [],
 layoutData: [],
 imageData: [],
 customLayouts: [],
 prebuiltLayoutData: [],
 prebuiltLayouts: [],
 prebuiltTemplateData: [],
 prebuiltTemplates: [],
 selectedTemplates: [],
 currentPage: 'dashboard',
 rowBackground: 'transparent',
 propertyPopup: false,
 colorPicker: {
  isOpen: false,
  commandName: null,
  color: '#000000'
 },
 textBlockPaddingLock: true,
 rowPaddingLock: true
};

// Reducer
const rootReducer = (state = initialState, action) => {
 switch (action.type) {
  case EDITOR:
   return {...state, editor: action.payload};
  case  BRAND_STYLES:
    return { ...state, brandStyles: action.payload };
  case BRAND_COLORS:
    return { ...state, brandColors: action.payload };
  case TOGGLE_CONTAINER_POPUP:
   return {...state, containerPopup: !state.containerPopup};
  case TOGGLE_PREBUILT_POPUP:
   return {...state, prebuiltPopup: !state.prebuiltPopup};
  case TOGGLE_DEVICE:
   return {...state, onMobile: !state.onMobile};
  case TOGGLE_PREVIEW_DEVICE:
   return {...state, isPreviewMobile: !state.isPreviewMobile};
  case TOGGLE_LAYOUT:
   return {...state, isLayout: !state.isLayout};
  case TOGGLE_LAYER:
   return {...state, isLayer: action.payload};
  case TOGGLE_PREVIEW:
   return {...state, isPreview: !state.isPreview};
  case TOGGLE_BRAND_STYLE:
   return {...state, isBrandStyle: !state.isBrandStyle};
  case TOGGLE_SIDEBAR:
   return {...state, isSidebar: !state.isSidebar};
  case TOGGLE_RIGHT_CONTAINER:
   return {...state, propertyPopup: action.payload};
  case CHANGE_ROW_BACKGROUND_COLOR:
   return {...state, rowBackground: action.payload};
  case CURRENT_PAGE:
   return {...state, currentPage: action.payload};
  case OPEN_COLOR_PICKER:
   return {
    ...state,
    colorPicker: {
     ...state.colorPicker,
     isOpen: true,
     commandName: action.payload
    }
   };
  case CLOSE_COLOR_PICKER:
   return {
    ...state,
    colorPicker: {...state.colorPicker, isOpen: false, commandName: null}
   };
  case SET_COLOR_PICKER_COLOR:
   return {
    ...state,
    colorPicker: {...state.colorPicker, color: action.payload}
   };
  case TEXT_BLOCK_PADDING_LOCK:
   return {
    ...state,
    textBlockPaddingLock: !state.textBlockPaddingLock
   };
  case ROW_PADDING_LOCK:
   return {
    ...state,
    rowPaddingLock: !state.rowPaddingLock
   };
  case AUTHENTICATED_USER:
   return {...state, isAuthenticated: action.payload};
  case TOGGLE_CREATE_TEMPLATE_DIALOG:
   return {
    ...state,
    isCreateTemplateDialog: !state.isCreateTemplateDialog
   };
  case TOGGLE_CREATE_TEMPLATE_LOADER:
   return {
    ...state,
    isCreateTemplateLoader: !state.isCreateTemplateLoader
   };
  case NAME_UPDATE:
   return {...state, isNameUpdated: !state.isNameUpdated};
  case TOGGLE_CREATE_LAYOUT_DIALOG:
   return {...state, isCreateLayoutDialog: !state.isCreateLayoutDialog};
  case TOGGLE_CREATE_LAYOUT_LOADER:
   return {...state, isCreateLayoutLoader: !state.isCreateLayoutLoader};
  case TOGGLE_DELETE_TEMPLATE_DIALOG:
   return {
    ...state,
    isDeleteTemplateDialog: !state.isDeleteTemplateDialog
   };
  case TOGGLE_DELETE_TEMPLATE_LOADER:
   return {
    ...state,
    isDeleteTemplateLoader: !state.isDeleteTemplateLoader
   };
  case TOGGLE_RENAME_TEMPLATE_DIALOG:
   return {
    ...state,
    isRenameTemplateDialog: !state.isRenameTemplateDialog
   };
  case TOGGLE_RENAME_TEMPLATE_LOADER:
   return {
    ...state,
    isRenameTemplateLoader: !state.isRenameTemplateLoader
   };
  case TOGGLE_SAVE_TEMPLATE_DIALOG:
   return {...state, isSaveTemplateDialog: !state.isSaveTemplateDialog};
  case TOGGLE_DUPLICATE_TEMPLATE_LOADER:
   return {
    ...state,
    isDuplicateTemplateLoader: !state.isDuplicateTemplateLoader
   };
  case TOGGLE_SAVE_TEMPLATE_LOADER:
   return {...state, isSaveTemplateLoader: !state.isSaveTemplateLoader};
  case CURRENT_TEMPLATE_ID:
   return {...state, currentTemplateId: action.payload};
  case CURRENT_TEMPLATE_NAME:
   return {...state, currentTemplateName: action.payload};
  case CURRENT_LAYOUT_NAME:
   return {...state, currentLayoutName: action.payload};
  case TEMPLATE_DATA:
   return {...state, templateData: action.payload};
  case IMAGE_DATA:
   return {...state, imageData: action.payload};
  case LAYOUT_DATA:
   return {...state, layoutData: action.payload};
  case CUSTOM_LAYOUTS:
   return {...state, customLayouts: action.payload};
  case PREBUILT_LAYOUT_DATA:
   return {...state, prebuiltLayoutData: action.payload};
  case PREBUILT_LAYOUTS:
   return {...state, prebuiltLayouts: action.payload};
  case PREBUILT_TEMPLATE_DATA:
   return {...state, prebuiltTemplateData: action.payload};
  case PREBUILT_TEMPLATES:
   return {...state, prebuiltTemplates: action.payload};
  case SELECTED_TEMPLATES:
   return {...state, selectedTemplates: action.payload};
  case TEMPLATE_SAVED:
   return {...state, isTemplateSaved: action.payload};
  default:
   return state;
 }
};

// Create Store
const store = createStore(rootReducer);

export default store;
