import {Box, CssBaseline, Drawer} from '@mui/material';
import iconClose from '../../assets/icons/icon-cross.svg';
import CommonOverlay from '../Overlay/Overlay';
import appLogo from '../../assets/icons/app-icon.svg';
import {elements} from 'chart.js';
const EmptyEmailTemplate = ({header, sidebar, body}) => {
 return (
  <>
   <CommonOverlay open={true} onClose={false}>
    <div className="Empty-template-overlay">
     <div className="header">
      <div className="header-left">
       <img src={appLogo} alt="app Logo" /> | Choose a template for your email
      </div>
      <div className="header-center"></div>
      <div className="header-right">
       <img src={iconClose} alt="empty template" />
      </div>
     </div>
     <div className="main">
      <div className="sidebar">
       {sidebar.map(element => {
        return (
         <div className="sidebar-element" onClick={element.onClick}>
          {element.label}
         </div>
        );
       })}
      </div>
      <div className="body">
       {body.map(element => {
        return <div className="product-banner"></div>;
       })}
      </div>
     </div>
    </div>
   </CommonOverlay>
  </>
 );
};
export default EmptyEmailTemplate;
