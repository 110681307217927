import React from 'react';
import Header from '../../components/Header';
import CommonOverlay from '../../components/Overlay/Overlay';
import BackIcon from '../../assets/icons/icon-arrow.svg';
import ClockIcon from '../../assets/icons/icon-time.svg';
import UndoButton from '../../assets/icons/icon-undo.svg';
import RedoButton from '../../assets/icons/icon-redo.svg';
import iconAddCircle from '../../assets/icons/icon-add-white.svg';
import {Button, Tooltip, CircularProgress} from '@mui/material';
import DynamicTabs from '../../components/Tab/Tab';
import {useForm} from 'react-hook-form';
import {useState} from 'react';
import automationImg from '../../assets/icons/undraw_user_flow_re_bvfx 1.png';
import CustomDialog from '../../components/Dialog/Dialog';
import VerticalTabs from '../../components/Tab/SideTab';
import iconForm from '../../assets/icons/icon-form.svg';
import {useNavigate} from 'react-router-dom';
const AutomationFlow = () => {
 const navigate = useNavigate();

 const [eventDialog, setEventDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const closeEventDialog = () => {
  setEventDialog(prevConfig => ({...prevConfig, open: false}));
 };

 const {register, handleSubmit} = useForm();
 const tabs = ['Workflow', 'Contacts', 'Analytics', 'Engagements', 'Orders'];
 const panels = [
  <>
   <div className="automation-card">
    <div>
     <img src={automationImg} />
    </div>
    <div className="text">
     <div className="card-header">How will a contact start their journey?</div>
     <div className="description">
      This marks the beginning of your contact's journey. You pick where it
      starts, and then contacts who fit the criteria will join your map and
      start their journey.
     </div>
    </div>
    <div>
     <button
      className="btn btn-grey"
      onClick={() => {
       setEventDialog({
        title: 'Select an event',
        open: true,
        onClose: closeEventDialog,
        type: 'automationEvent',
        actions: []
       });
      }}
     >
      <span>
       <img src={iconAddCircle} alt="img" />
       Select an Event
      </span>
     </button>
    </div>
   </div>
  </>,
  <div className="Tab-container">
   <button
    onClick={() => {
     navigate('/workflow');
    }}
   >
    open workflow
   </button>
  </div>,
  <div className="Tab-container">Content for Tab Four</div>,
  <div className="Tab-container">Content for Tab Four</div>,
  <div className="Tab-container">Content for Tab Four</div>
 ];

 const EventSelector = () => {
  const labels = ['Contacts', 'eCommerce', 'Email Activities'];
  const contents = [
   <div className="event-contacts">
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
    <div className="journey-card">
     <div className="img-container">
      <img src={iconForm} alt="form" />
     </div>
     <div>Submits a Form</div>
    </div>
   </div>,
   <div>Content for Item Two</div>,
   <div>Content for Item Three</div>
  ];
  return (
   <>
    <VerticalTabs labels={labels} contents={contents} />
   </>
  );
 };

 return (
  <>
   <CustomDialog
    {...eventDialog}
    content={
     eventDialog.type === 'automationEvent' ? (
      <EventSelector />
     ) : (
      eventDialog.content
     )
    }
   />
   <CommonOverlay
    open={true}
    onClose={() => {
     //setshowOverlay(false);
    }}
   >
    <div className="template-layout-wrapper">
     <Header
      title={
       <>
        {' '}
        <Button
         variant="outline"
         className="back-icon common-btn"
         onClick={() => {}}
        >
         <img src={BackIcon} alt="Back button" />
        </Button>
        <span>Send email to my new subscribers</span>
       </>
      }
      actions={[
       {
        icon: ClockIcon,
        label: 'last save 1hr ago',
        onClick: () => {
         //setshowOverlay(false);
        }
       },
       {
        label: 'Draft',
        icon: {}
       },
       {
        label: 'Save',
        onClick: () => {
         //setshowOverlay(false);
        },
        isPrimary: true
       }
      ]}
     />
     <DynamicTabs tabs={tabs} panels={panels} />
    </div>
   </CommonOverlay>
  </>
 );
};
export default AutomationFlow;
