import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { togglePrebuiltPopup, setCustomLayouts, setPrebuiltLayouts } from "../../../../store/store";
import { useLayouts } from "../../../../hooks/useLayouts";
import { usePrebuiltLayouts } from "../../../../hooks/usePrebuiltLayouts";
import CloseBtn from "../../../../assets/icons/icon-cross.svg";
import ReactDOMServer from "react-dom/server";
import RadioButtonsGroup from "../../../../components/RadioGroup/Radio";

const PreBuilt = () => {
  const dispatch = useDispatch();
  const { fetchLayouts } = useLayouts();
  const { fetchPrebuiltLayout , prebuiltLayoutData} = usePrebuiltLayouts();
  const layoutData = useSelector((state) => state.layoutData);
  
  
  const [layoutRefs, setLayoutRefs] = useState([]);
  const [prebuiltRefs, setPrebuiltRefs] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Prebuilt');
  const values = ['Prebuilt', 'Saved'];

  useEffect(() => {
    fetchLayouts();
  }, [fetchLayouts]);

  useEffect(() => {
    fetchPrebuiltLayout();
  }, [fetchPrebuiltLayout]);

  useEffect(() => {
    console.log(prebuiltLayoutData.length, 'prebuilt layouts data fetched');
    if (prebuiltLayoutData && Array.isArray(prebuiltLayoutData)) {
      const newPrebuiltRefs = prebuiltLayoutData?.map(() => React.createRef());
      setPrebuiltRefs(newPrebuiltRefs);

      const updatedPrebuilt = prebuiltLayoutData.map((layout, index) => {
        console.log(layout.name, "added in prebuilt");
        const id = layout.id;
        const name = layout.name;

        // Create a temporary div to measure the content
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.width = 'auto';
        tempDiv.innerHTML = layout.htmlContent;
        document.body.appendChild(tempDiv);

        const contentWidth = tempDiv.scrollWidth;
        const contentHeight = tempDiv.scrollHeight;
        document.body.removeChild(tempDiv);

        // Assuming the container width should be fixed or known
        const containerWidth = 236; // You may need to adjust this based on your layout
        const scale = containerWidth / contentWidth;

        // Convert the JSX to a string
        const layoutLabel = ReactDOMServer.renderToStaticMarkup(
          <div className="layout-block" data-category="Layouts">
            <div className="layout-label">
              <span>{name}</span>
            </div>
            <div key={id} className="layout-container" ref={newPrebuiltRefs[index]} style={{
              width: containerWidth + 'px',
              height: `${contentHeight * scale}px`, // Set height to match scaled content
              overflow: 'hidden', // Hide any overflow
            }}>
              <div className="layout-content-wrapper" style={{
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: `${100 / scale}%`, // Adjust the width to fit the container
              }}>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: layout.htmlContent }}
                ></div>
              </div>
            </div>
          </div>
        );

        return {
          id,
          name,
          label: layoutLabel,
          content: layout.htmlContent,
          category: "Prebuilt",
        };
      });
      dispatch(setPrebuiltLayouts(updatedPrebuilt));
      console.log("Prebuilt updated");
    }

    if (layoutData && Array.isArray(layoutData)) {
      const newLayoutRefs = layoutData.map(() => React.createRef());
      setLayoutRefs(newLayoutRefs);

      const updatedLayouts = layoutData.map((layout, index) => {
        const id = layout.id;
        const name = layout.name;

        // Create a temporary div to measure the content
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.width = 'auto';
        tempDiv.innerHTML = layout.htmlContent;
        document.body.appendChild(tempDiv);

        const contentWidth = tempDiv.scrollWidth;
        const contentHeight = tempDiv.scrollHeight;
        document.body.removeChild(tempDiv);

        // Assuming the container width should be fixed or known
        const containerWidth = 236; // You may need to adjust this based on your layout
        const scale = containerWidth / contentWidth;

        // Convert the JSX to a string
        const layoutLabel = ReactDOMServer.renderToStaticMarkup(
          <div className="layout-block" data-category="Layouts">
            <div className="layout-label">
              <span>{name}</span>
            </div>
            <div key={id} className="layout-container" ref={newLayoutRefs[index]} style={{
              width: containerWidth + 'px',
              height: `${contentHeight * scale}px`, // Set height to match scaled content
              overflow: 'hidden', // Hide any overflow
            }}>
              <div className="layout-content-wrapper" style={{
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: `${100 / scale}%`, // Adjust the width to fit the container
              }}>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: layout.htmlContent }}
                ></div>
              </div>
            </div>
          </div>
        );

        return {
          id,
          name,
          label: layoutLabel,
          content: layout.htmlContent,
          category: "Layouts",
        };
      });
      dispatch(setCustomLayouts(updatedLayouts));
      console.log("Layouts updated");
    }
  }, [layoutData, dispatch, prebuiltLayoutData]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div id="container-popup">
      <div className="popup-header">
        <span className="cont-head">Layouts</span>
        <div className="close-popup-btn-container">
          <button
            className="close-popup-btn"
            onClick={() => dispatch(togglePrebuiltPopup())}
          >
            <img
              src={CloseBtn}
              alt="Close Prebuilt Popup Button"
              height="20px"
              width="20px"
            />
          </button>
        </div>
      </div>
      <RadioButtonsGroup values={values} onChange={handleRadioChange} checked={selectedValue}/>
      <div id="layouts-block" style={{display: selectedValue === 'Saved' ? 'block' : 'none'}}></div>
      <div id="prebuilt-block" style={{display: selectedValue === 'Prebuilt' ? 'block' : 'none'}}></div>
    </div>
  );
};

export default PreBuilt;
