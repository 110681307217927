import iconCompleted from '../../../assets/icons/icon-completed.svg';
import iconIncomplete from '../../../assets/icons/icon-incomplete.svg';
const ListItem = ({Text, button, status}) => {
 const label = status ? 'Edit' : button.label;
 return (
  <>
   <div className="ListItemContainer">
    <div className="content">
     {status && <img src={iconCompleted} />}
     {!status && <img src={iconIncomplete} />}

     <div className="text">
      <div className="title">{Text.title}</div>
      <div className="description">{Text.description}</div>
     </div>
    </div>
    <div className="botton">
     <button
      className="btn btn-outline dark-border btn-campaign"
      onClick={() => {
       button.onClick();
      }}
     >
      {label}
     </button>
    </div>
   </div>
  </>
 );
};
export default ListItem;
