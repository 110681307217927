import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import {useNavigate, useParams} from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '../../../../components/Icon';
import ResourceApi from '../../../../api/resourceapi';
import styles from './ContactDetails.module.scss';
import Header from '../../../../components/Header';
import Progress from '../../../../components/Progress/Progess';
import FormRenderer from '../../../../components/FormRenderer';
import CustomMenu from '../../../../components/Menu/Menu';
import MyTab from './MyTab';
const fields = {
 firstName: {
  type: 'text',
  name: 'firstName',
  placeholder: 'Type here',
  label: 'First name',
  validations: {required: 'First name is required'}
 },
 lastName: {
  type: 'text',
  name: 'lastName',
  placeholder: 'Type here',
  label: 'Last name',
  validations: {
   required: 'Last name is required'
  }
 },
 email: {
  type: 'text',
  name: 'email',
  placeholder: 'Type here',
  label: 'Email',
  validations: {
   required: 'Email is required',
   pattern: {
    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: 'Invalid email address'
   }
  }
 },
 phone: {
  type: 'text',
  name: 'phone',
  placeholder: 'Type here',
  label: 'Phone',
  validations: {
   pattern: {
    value: /^\+\d{1,2} \(\d{3}\) \d{3}-\d{4}$/,
    message: 'Phone number should be in correct format'
   }
  }
 }
};

const contactStatusOptions = [
 {
  icon: 'icon-tick',
  label: 'Subscribed',
  value: 'SUBSCRIBE'
 },
 {
  icon: 'icon-unsubscribe',
  label: 'Unsubscribed',
  value: 'UNSUBSCRIBED'
 },
 {
  icon: 'icon-blocked',
  label: 'Blocked',
  value: 'BLOCKED'
 },
 {
  icon: 'icon-bounced',
  label: 'Bounced',
  value: 'BOUNCED'
 },
 {
  icon: 'icon-bounced',
  label: 'Marked as Spam',
  value: 'MARKED-AS-SPAM'
 }
];

const ContactDetails = ({match}) => {
 const navigate = useNavigate();
 const {id} = useParams();
 const [contact, setContact] = useState({});
 const [isContactLoading, setIsContactLoading] = useState(true);
 const [editAnchorElement, setEditAnchorElement] = useState(null);
 const [statusAnchorElement, setStatusAnchorElement] = useState(null);
 const [currentField, setCurrentField] = useState(null);
 const {
  register,
  handleSubmit,
  watch,
  setError,
  setValue,
  getValues,
  formState: {errors},
  reset
 } = useForm({mode: 'onBlur'});

 useEffect(() => {
  ResourceApi.contact.getById(id).then(({data}) => {
   console.log(data);
   setIsContactLoading(false);
   setContact(data);
  });
 }, []);
 const handleEditClick = ({target}, field) => {
  setEditAnchorElement(target);
  setCurrentField(field);
  setValue(field, contact[field]);
 };
 const handleEditClose = () => {
  setEditAnchorElement(null);
 };
 const statusDetails = contactStatusOptions.find(
  statusOption => statusOption.value === contact.status
 );

 return (
  <>
   <Header
    title={
     <Stack direction="row" gap={2} alignItems="center">
      <IconButton
       aria-label="back"
       onClick={() => {
        navigate('/contacts/allContacts');
       }}
      >
       <Icon type="icon-back" />
      </IconButton>
      {!isContactLoading && (
       <>
        <Typography
         variant="h1"
         sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
        >
         {`${contact.firstName} ${contact.lastName}`}
        </Typography>
        {statusDetails && (
         <Button
          variant="contained"
          disableElevation
          disableRipple
          startIcon={<Icon type={statusDetails.icon} />}
          endIcon={<Icon type="icon-arrow" />}
          className={classNames(styles.btnStatus)}
          onClick={event => {
           setStatusAnchorElement(event.currentTarget);
          }}
         >
          {statusDetails.label}
         </Button>
        )}
       </>
      )}
     </Stack>
    }
    actions={[
     {
      label: 'Send Email',
      isPrimary: true,
      onClick: () => {}
     }
    ]}
    moreActions={[
     {
      label: 'Assign to Automation',
      value: 'assign_to_automation',
      icon: 'icon-add-circle'
     },
     {
      label: 'Delete',
      value: 'delete',
      icon: 'icon-delete'
     }
    ]}
   />
   <div
    className="main-content-container"
    style={{
     backgroundColor: '#F9FAFB',
     minHeight: 'calc(100% - 56px)'
    }}
   >
    <Stack direction="row" gap={3}>
     <Stack gap={3} sx={{width: '408px'}}>
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Overview
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        {isContactLoading ? (
         <Progress />
        ) : (
         <>
          <Stack direction="row" className={styles.row}>
           <div className={styles.name}>First Name</div>
           <div className={styles.value}>
            {contact.firstName ?? ''}
            <IconButton
             aria-label="edit"
             size="small"
             sx={{ml: '4px'}}
             onClick={event => handleEditClick(event, 'firstName')}
            >
             <Icon type="icon-edit" />
            </IconButton>
           </div>
          </Stack>
          <Stack className={styles.row}>
           <div className={styles.name}>Last Name</div>
           <div className={styles.value}>
            {contact.lastName ?? ''}
            <IconButton
             aria-label="edit"
             size="small"
             sx={{ml: '4px'}}
             onClick={event => handleEditClick(event, 'lastName')}
            >
             <Icon type="icon-edit" />
            </IconButton>
           </div>
          </Stack>
          <Stack className={styles.row}>
           <div className={styles.name}>Email</div>
           <div className={styles.value}>
            {contact.email ?? ''}
            <IconButton
             aria-label="edit"
             size="small"
             sx={{ml: '4px'}}
             onClick={event => handleEditClick(event, 'email')}
            >
             <Icon type="icon-edit" />
            </IconButton>
           </div>
          </Stack>
          <Stack className={styles.row}>
           <div className={styles.name}>Phone</div>
           <div className={styles.value}>
            {contact.phone ?? ''}
            <IconButton
             aria-label="edit"
             size="small"
             sx={{ml: '4px'}}
             onClick={event => handleEditClick(event, 'phone')}
            >
             <Icon type="icon-edit" />
            </IconButton>
           </div>
          </Stack>
          <Stack className={styles.row}>
           <div className={styles.name}>Tag</div>
           <div className={styles.value}>
            <Stack gap={1} direction="row" flexWrap="wrap">
             {contact.tagResources?.map(({name, id}) => (
              <Chip label={name} size="small" key={id} onDelete={() => {}} />
             ))}
            </Stack>
           </div>
          </Stack>
         </>
        )}
       </CardContent>
      </Card>
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Engagements
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        <Grid container rowGap={2}>
         <Grid item xs={6}>
          <div>Sep 14, 2021 at 09:30</div>
          <div className={styles.cell}>Last Updated</div>
         </Grid>
         <Grid item xs={6}>
          <div>Sep 14, 2021</div>
          <div className={styles.cell}>Last Email Sent</div>
         </Grid>
         <Grid item xs={6}>
          <div>Sep 14, 2021</div>
          <div className={styles.cell}>Last Click</div>
         </Grid>
         <Grid item xs={6}>
          <div>Sep 14, 2021</div>
          <div className={styles.cell}>Last Open</div>
         </Grid>
        </Grid>
       </CardContent>
      </Card>
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Orders
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        <Grid container rowGap={2}>
         <Grid item xs={6}>
          <div>1</div>
          <div className={styles.cell}>Order(s)</div>
         </Grid>
         <Grid item xs={6}>
          <div>$80</div>
          <div className={styles.cell}>Total Spent</div>
         </Grid>
         <Grid item xs={6}>
          <div>Sep 14, 2021</div>
          <div className={styles.cell}>Last Ordered</div>
         </Grid>
         <Grid item xs={6}>
          <div>$50</div>
          <div className={styles.cell}>AOV</div>
         </Grid>
        </Grid>
       </CardContent>
      </Card>
     </Stack>
     <Stack
      sx={{width: 'calc(100% - 408px)'}}
      gap={3}
      justifyContent="flex-start"
     >
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Email Campaigns
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        <Grid container>
         <Grid item xs={3}>
          <div>10</div>
          <div className={styles.cell}>Sent</div>
         </Grid>
         <Grid item xs={3}>
          <div>95%</div>
          <div className={styles.cell}>Delivered</div>
         </Grid>
         <Grid item xs={3}>
          <div>95%</div>
          <div className={styles.cell}>Unique Opening</div>
         </Grid>
         <Grid item xs={3}>
          <div>95%</div>
          <div className={styles.cell}>Unique Clicks</div>
         </Grid>
        </Grid>
       </CardContent>
      </Card>
      <Card variant="outlined">
       {console.log('contact id=' + contact.id)}
       <MyTab id={contact.id} />
      </Card>
     </Stack>
    </Stack>
   </div>
   <Popover
    open={!!editAnchorElement}
    anchorEl={editAnchorElement}
    onClose={handleEditClose}
    anchorOrigin={{
     vertical: 'bottom',
     horizontal: 'center'
    }}
   >
    <div className={styles.editForm}>
     <Typography
      variant="h2"
      sx={{fontSize: '16px', mb: 1, lineHeight: 1.5, fontWeight: 600}}
     >
      Edit Details
     </Typography>
     <FormRenderer
      errors={errors}
      register={register}
      watch={watch}
      fields={[fields[currentField]]}
     />
     <Stack direction="row" gap={1} marginTop={2} justifyContent="flex-end">
      <Button className="btn btn-outline dark-border" onClick={handleEditClose}>
       Cancel
      </Button>
      <Button
       className="btn btn-primary"
       onClick={event => {
        event.preventDefault();
        ResourceApi.contact
         .update(contact.id, {
          ...contact,
          [currentField]: getValues(currentField)
         })
         .then(({data}) => {
          setContact(data);
          handleEditClose();
         });
       }}
      >
       Save
      </Button>
     </Stack>
    </div>
   </Popover>

   <CustomMenu
    onClose={() => {
     setStatusAnchorElement(null);
    }}
    onSelect={value => {
     setStatusAnchorElement(null);
     // ResourceApi
     //   .contact
     //   .update(contact.id, { status: value })
     //   .then(({ data }) => {
     //     setContact(data)
     //   })
    }}
    options={contactStatusOptions}
    anchorEl={statusAnchorElement}
   />
  </>
 );
};

export default ContactDetails;
