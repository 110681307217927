import HtmlIcon from "../../../../../assets/icons/icon-code.svg";

export const registerHtmlBlock = (editor, opts = {}) => {
  editor.DomComponents.addType("HTML", {
    model: {
      defaults: {
        tagName: "span",
        attributes:{'data-gjs-type': 'HTML'},
        classes: ["block-div"],
        style: { display: "block !important", width: "100%" },
        droppable: false,
        editable: true,
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  editor.BlockManager.add("htmlBlock", {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${HtmlIcon} alt="HTML Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">HTML</div>`,
    content: {
      type: "HTML",
      content: "<strong>Hello World</strong>",
      components: [],
      editable: true,
    },
    category: "Blocks",
    draggable: "td",
  });
};
