import {apiRequest} from './http';

const CrudApi = (resource = '') => {
 return {
  create: requestBody => {
   return apiRequest('POST', `/${resource}`, requestBody);
  },
  get: () => {
   return apiRequest('GET', `/${resource}`);
  },
  getArchive: () => {
   return apiRequest('GET', `/${resource}/archived`);
  },
  getById: id => {
   return apiRequest('GET', `/${resource}/${id}`);
  },
  update: (id, requestBody) => {
   return apiRequest('PUT', `/${resource}/${id}`, requestBody);
  },
  delete: requestBody => {
   return apiRequest('DELETE', `/${resource}`, requestBody);
  },
  deleteById: id => {
   return apiRequest('DELETE', `/${resource}/${id}`);
  },
  updateUser: requestBody => {
   return apiRequest('PUT', `/${resource}`, requestBody);
  },
  resetPass: requestBody => {
   return apiRequest('PUT', `/${resource}/password/change`, requestBody);
  },
  domainAuthentication: (id, requestBody) => {
   return apiRequest('PUT', `/${resource}/auth?id=${id}`, requestBody);
  },
  domainDelete: (id, requestBody) => {
   return apiRequest('DELETE', `/${resource}?id=${id}`, requestBody);
  },
  fetchList: requestBody => {
   return apiRequest('GET', `/${resource}?tagOrList=LIST`, requestBody);
  },
  getFields: requestBody => {
   return apiRequest('GET', `/${resource}/preferences`, requestBody);
  },
  createFields: requestBody => {
   return apiRequest('POST', `/${resource}/preferences`, requestBody);
  },
  updateFields: requestBody => {
   return apiRequest('PUT', `/${resource}/field`, requestBody);
  },
  addField: requestBody => {
   return apiRequest('POST', `/${resource}/field`, requestBody);
  },
  deleteFields: requestBody => {
   return apiRequest('DELETE', `/${resource}/field`, requestBody);
  }
 };
};

const resources = [
 'users',
 'contact',
 'tag',
 'note',
 'campaign',
 'sender',
 'domain',
 'brandstyle'
];
const ResourceApi = resources.reduce(
 (acc, resource) => ({...acc, [resource]: CrudApi(resource)}),
 {}
);

export default ResourceApi;
