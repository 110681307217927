import React from "react";
import { Button } from "@mui/material";
import classNames from "classnames";

const EmptyState = ({
  title,
  description,
  options
}) => {
  return (
    <div className="empty-wrapper">
      <div className="empty-headings">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="image-container">
        {
          options.map((option) => {
            const {
              title,
              description,
              action: {
                label,
                onClick,
                icon
              },
              banner,
              bannerBackground
            } = option
            return <div className="empty-section">
                <div className="empty-section-title">
                  <h3>{title}</h3>
                  <p>{description}</p>
                  <Button
                    className="btn btn-outline dark-border"
                    variant="contained"
                    onClick={onClick}
                  >
                    <img className="icon" src={icon} />
                    {label}
                  </Button>
                </div>
                <div className={classNames('empty-section-img', bannerBackground)}>
                  <img src={banner} />
                </div>
              </div>
          })
        }
      </div>
    </div>
  );
};

export default EmptyState;
