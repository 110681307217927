import React, {useState} from 'react';
import {
 Button,
 CssBaseline,
 Link,
 Grid,
 Box,
 Typography,
 Container
} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {auth} from '../api/authapi.js';
import appIcon from '../assets/icons/app-icon.svg';
import {useNavigate} from 'react-router-dom';
import {signupSchema} from '../api/validation.js';
import * as zod from 'zod';
const defaultTheme = createTheme();

const SignUp = () => {
 const navigate = useNavigate();
 const [isVerified, setIsVerified] = useState(false);

 const [fName, setFName] = useState('');
 const [lName, setLName] = useState('');
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');
 const [errors, setErrors] = useState({});

 const handleSubmit = async event => {
  event.preventDefault();

  const formData = {
   email: email,
   firstName: fName,
   lastName: lName,
   password: password
  };
  //validate
  try {
   console.log('validating data');
   await signupSchema.parseAsync(formData);
   console.log('Valid Data:', formData);
   setErrors({});
  } catch (error) {
   if (error instanceof zod.ZodError) {
    const newErrors = {};
    error.issues.forEach(issue => {
     newErrors[issue.path[0]] = issue.message;
     console.log(issue.message);
    });
    console.log(newErrors);
    setErrors(newErrors);
    return;
   } else {
    console.error('Unexpected error:', error);
    return;
    // Handle other types of errors
   }
  }

  try {
   const response = await auth.createUser(formData);
   console.log(response, 'User signed up');
   setIsVerified(true);
  } catch (error) {
   console.log(error, 'from sign up');
  }
 };

 const openLogin = () => {
  navigate('/login');
 };

 return (
  <>
   <div className="header auth">
    <div className="header-left">
     <img className="app-icon" src={appIcon} />{' '}
     <span className="template-name heading"> adflipr </span>{' '}
    </div>{' '}
    <div className="header-center"> </div>{' '}
    <div className="header-right">
     {' '}
     {isVerified ? (
      ''
     ) : (
      <Button
       className="btn btn-primary"
       variant="contained"
       onClick={openLogin}
      >
       Login{' '}
      </Button>
     )}{' '}
    </div>{' '}
   </div>{' '}
   <ThemeProvider theme={defaultTheme}>
    {' '}
    {isVerified ? (
     <>
      <div className="verification-container">
       <h1 className="verification-heading"> Verify your email </h1>{' '}
       <p className="verification-content">
        To verify its you, we have sent you an email on {email} to verify your
        account. <br /> This link will active only 15 min to verify{' '}
       </p>{' '}
       <a className="verification-link" href="#" onClick={openLogin}>
        Back to Login{' '}
       </a>{' '}
      </div>{' '}
     </>
    ) : (
     <Container component="main" className="form" maxWidth="xs">
      <CssBaseline />
      <Box
       sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
       }}
       className="form-container"
      >
       <Typography
        className="form-heading"
        component="h1"
        variant="h5"
        gutterBottom
       >
        Signup for <strong> adflipr </strong>{' '}
       </Typography>{' '}
       <Box
        component="form"
        className="form-component signup-form"
        noValidate
        onSubmit={handleSubmit}
        sx={{mt: 3}}
       >
        <Grid className="form-grid" container spacing={2}>
         <Grid className="form-grid-item" item xs={12} sm={6}>
          <div className="input-container">
           <label className="email-dialog-label"> First Name </label>{' '}
           <input
            className={`email-dialog-input ${
             errors.firstName ? 'box-error' : ''
            }`}
            placeholder="Type here..."
            type="text"
            onChange={e => {
             setFName(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              firstName: ''
             }));
            }}
           ></input>{' '}
           {errors.firstName && (
            <div className="error-msg"> {errors.firstName} </div>
           )}{' '}
          </div>{' '}
         </Grid>{' '}
         <Grid className="form-grid-item" item xs={12} sm={6}>
          <div className="input-container">
           <label className="email-dialog-label"> Last Name </label>{' '}
           <input
            className={`email-dialog-input ${
             errors.lastName ? 'box-error' : ''
            }`}
            placeholder="Type here..."
            type="text"
            onChange={e => {
             setLName(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              lastName: ''
             }));
            }}
           ></input>{' '}
           {errors.lastName && (
            <div className="error-msg"> {errors.lastName} </div>
           )}{' '}
          </div>{' '}
         </Grid>{' '}
         <Grid className="form-grid-item" item xs={12}>
          <div className="input-container">
           <label className="email-dialog-label"> Email Id </label>{' '}
           <input
            className={`email-dialog-input ${errors.email ? 'box-error' : ''}`}
            placeholder="Type here..."
            type="text"
            onChange={e => {
             setEmail(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              email: ''
             }));
            }}
           ></input>{' '}
           {errors.email && <div className="error-msg"> {errors.email} </div>}{' '}
          </div>{' '}
         </Grid>{' '}
         <Grid className="form-grid-item" item xs={12}>
          <div className="input-container">
           <label className="email-dialog-label"> Password </label>{' '}
           <input
            className={`email-dialog-input ${
             errors.password ? 'box-error' : ''
            }`}
            placeholder="Type here..."
            type="password"
            onChange={e => {
             setPassword(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              password: ''
             }));
            }}
           ></input>{' '}
           {errors.password && (
            <div className="error-msg"> {errors.password} </div>
           )}{' '}
          </div>{' '}
         </Grid>{' '}
        </Grid>{' '}
        <Button
         type="submit"
         fullWidth
         className="btn btn-primary btn-signup"
         variant="contained"
        >
         Create Account{' '}
        </Button>{' '}
       </Box>{' '}
       <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        className="form-info"
        sx={{mt: 2}}
       >
        By clicking "Create Account" or "Continue with Google", you agree to the{' '}
        <Link href="#" color="#ff385c">
         Terms & Conditions{' '}
        </Link>
        and{' '}
        <Link href="#" color="#ff385c">
         Privacy Policy{' '}
        </Link>
        .{' '}
       </Typography>{' '}
      </Box>{' '}
     </Container>
    )}{' '}
   </ThemeProvider>{' '}
  </>
 );
};

export default SignUp;
