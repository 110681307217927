// ButtonTraitManager.jsx
import React, { useState } from "react";
import BackgroundColorTrait from "../TraitFunctions/BackgroundColor";
import FontSizeTrait from "../TraitFunctions/FontSize";
import TextColorTrait from "../TraitFunctions/TextColor";
import WidthControlTrait from "../TraitFunctions/FitToContainer";
import PaddingControlTrait from "../TraitFunctions/PaddingControl";
import BorderRadiusControlTrait from "../TraitFunctions/BorderRadiusControl";

const ButtonTraitManager = ({ onChange, buttonTraits, setButtonTraits }) => {
  const [fontSize, setFontSize] = useState(
    parseInt(buttonTraits.fontSize.replace("px", ""), 10) || 16
  );
  const [bgColor, setBgColor] = useState(
    buttonTraits.backgroundColor || "#ff385c"
  );
  const [textColor, setTextColor] = useState(
    buttonTraits.color || "#ffffff"
  );
  const [isFitToContainer, setIsFitToContainer] = useState(buttonTraits.width === "auto");
  const [customWidth, setCustomWidth] = useState(parseInt(buttonTraits.width.replace('%',''),10) || "auto");
  const [padding, setPadding] = useState({
    top: parseInt(buttonTraits.padding.split(" ")[0].trim().replace('px',''),10) || 16,
    right: parseInt(buttonTraits.padding.split(" ")[1].trim().replace('px',''),10) || 24,
    bottom: parseInt(buttonTraits.padding.split(" ")[2].trim().replace('px',''),10) || 16,
    left: parseInt(buttonTraits.padding.split(" ")[3].trim().replace('px',''),10) || 24,
  });
  const [borderRadius, setBorderRadius] = useState({
    "top-left":
      parseInt(
        buttonTraits.borderRadius.split(" ")[0].trim().replace("px", ""),
        10
      ) || 8,
    "top-right":
      parseInt(
        buttonTraits.borderRadius.split(" ")[1].trim().replace("px", ""),
        10
      ) || 8,
    "bottom-right":
      parseInt(
        buttonTraits.borderRadius.split(" ")[2].trim().replace("px", ""),
        10
      ) || 8,
    "bottom-left":
      parseInt(
        buttonTraits.borderRadius.split(" ")[3].trim().replace("px", ""),
        10
      ) || 8,
  });

  const handleFontSizeChange = (event) => {
    const newFontSize = `${event.target.value}px`;
    setFontSize(parseInt(event.target.value, 10));
    onChange({ fontSize: newFontSize });
    setButtonTraits({
      ...buttonTraits,
      fontSize: newFontSize,
    });
  };

  const handleColorChange = (color) => {
    setBgColor(color);
    setButtonTraits({
      ...buttonTraits,
      backgroundColor: color,
    });
    onChange({ backgroundColor: color });
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setButtonTraits({
      ...buttonTraits,
      color: color,
    });
    onChange({ color: color });
  };

  const handleWidthToggleChange = (newValue) => {
    setIsFitToContainer(newValue);
    const widthValue = newValue ? "auto" : `${customWidth}%`;
    setButtonTraits({
      ...buttonTraits,
      width: widthValue,
    });
    onChange({ width: widthValue });
  };

  const handleWidthChange = (newWidth) => {
    setCustomWidth(newWidth);
    const widthValue = `${newWidth}%`;
    setButtonTraits({
      ...buttonTraits,
      width: widthValue,
    });
    onChange({ width: widthValue });
  };

  const handlePaddingChange = (newPadding) => {
    setPadding(newPadding);
    const paddingString = `${newPadding.top}px ${newPadding.right}px ${newPadding.bottom}px ${newPadding.left}px`;
    setButtonTraits({
      ...buttonTraits,
      padding: paddingString,
    });
    onChange({ padding: paddingString });
  };

  const handleBorderRadiusChange = (newBorderRadius) => {
    setBorderRadius(newBorderRadius);
    const radiusString = `${newBorderRadius["top-left"]}px ${newBorderRadius["top-right"]}px ${newBorderRadius["bottom-right"]}px ${newBorderRadius["bottom-left"]}px`;
    setButtonTraits({
      ...buttonTraits,
      borderRadius: radiusString,
    });
    onChange({ borderRadius: radiusString });
  };

  return (
    <div>
      <BackgroundColorTrait
        label="Button background"
        color={bgColor}
        onChange={handleColorChange}
      />
      <FontSizeTrait
        label="Font Size"
        value={fontSize}
        onChange={handleFontSizeChange}
      />
      <TextColorTrait
        label="Text color"
        color={textColor}
        onChange={handleTextColorChange}
      />
      <WidthControlTrait
        label="Auto Width"
        isFitToContainer={isFitToContainer}
        customWidth={customWidth}
        onToggleChange={handleWidthToggleChange}
        onWidthChange={handleWidthChange}
      />
      <PaddingControlTrait value={padding} onChange={handlePaddingChange} />
      <BorderRadiusControlTrait
        value={borderRadius}
        onChange={handleBorderRadiusChange}
      />
    </div>
  );
};

export default ButtonTraitManager;
