import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import {useNavigate} from 'react-router-dom';
import Header from '../../../components/Header/Header';
import MyDrawer from './component/MyDrawer';

import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import iconAddCircle from '../../../assets/icons/icon-add.svg';
import iconUpload from '../../../assets/icons/icon-upload.svg';
import iconDowload from '../../../assets/icons/icon-download.svg';
import iconPreference from '../../../assets/icons/icon-preference.svg';
import importBanner from '../../../assets/icons/template_blank.png';
import createBanner from '../../../assets/icons/use_prebuilt.png';
import EmptyState from '../../../components/EmptyState';
import CustomDialog from '../../../components/Dialog';
import FormRenderer from '../../../components/FormRenderer';
import ResourceApi from '../../../api/resourceapi';
import Message from '../../../components/Message';
import Table from '../../../components/Table';
import formatDate from '../../../utils/date-utils';
const AllContacts = () => {
 const [contacts, setContacts] = useState([]);
 const [isContactLoading, setIsContactLoading] = useState(true);
 const [tags, setTags] = useState([]);
 const [alert, setAlert] = useState({
  type: '',
  show: false,
  message: ''
 });
 const [dialogConfig, setDialogConfig] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });

 const navigate = useNavigate();

 const {
  register,
  handleSubmit,
  watch,
  setError,
  formState: {errors},
  reset
 } = useForm({mode: 'onBlur'});

 //
 const [isDrawerOpen, setIsDrawerOpen] = useState(false);
 const handleDrawerOpen = () => {
  setIsDrawerOpen(true);
 };
 const handleDrawerClose = () => {
  setIsDrawerOpen(false);
 };
 const handleContactResponse = data => {
  data.sort((contact1, contact2) => {
   const date1 = new Date(contact1.updated);
   const date2 = new Date(contact2.updated);
   return date2.getTime() - date1.getTime();
  });
  setContacts(data);
 };

 const onCloseDialog = () => {
  setDialogConfig(prevConfig => ({...prevConfig, open: false}));
 };

 const onContactFetch = () => {
  ResourceApi.contact.get().then(({data}) => {
   setIsContactLoading(false);
   handleContactResponse(data);
  });
 };

 const onContactFormClose = () => {
  onCloseDialog();
  reset();
 };

 useEffect(() => {
  onContactFetch();
 }, []);

 const onContactCreate = data => {
  const {tagResources = []} = data;
  const apiPayload = {
   ...data, 
   tagResources: tagResources.map(({ id }) => ({ id }))
  };

  ResourceApi.contact
   .create(apiPayload)
   .then(() => {
    onContactFormClose();
    setAlert({
     type: 'success',
     show: true,
     message: 'Contact created successfully'
    });
    onContactFetch();
   })
   .catch(error => {
    setError('email', {
     type: 'manual',
     message: error.response?.data?.message || ''
    });
   });
 };

 const onContactDelete = ids => {
  onCloseDialog();
  ResourceApi.contact
   .delete({
    ids
   })
   .then(() => {
    setAlert({
     type: 'success',
     show: true,
     message: `${ids.length} contact(s) deleted successfully`
    });
    onContactFetch();
   });
 };

 const ContactCreateForm = () => {
  return (
   <FormRenderer
    register={register}
    watch={watch}
    errors={errors}
    fields={[
     {
      type: 'text',
      name: 'email',
      placeHolder: 'Type here',
      label: 'Email',

      validations: {
       required: 'Email is required',
       pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address'
       }
      }
     },
     {
      type: 'text',
      name: 'firstName',
      placeHolder: 'Type here',
      label: 'First name',
      validations: {required: 'First name is required'}
     },
     {
      type: 'text',
      name: 'lastName',
      placeHolder: 'Type here',
      label: 'Last name',
      validations: {
       required: 'Last name is required'
      }
     },
     {
      type: 'text',
      name: 'phone',
      placeHolder: 'Type here',
      label: 'Phone',
      validations: {
       pattern: {
        value: /^\+?\d+$/,
        message: 'Phone number should be in correct format'
       }
      }
     },
     {
      type: 'dropdown',
      name: 'tagResources',
      placeHolder: 'Search to select',
      label: 'Tags'
     },
     {
      type: 'checkbox',
      name: 'isSubscribed',
      label: 'Mark contact as subscribed'
     }
    ]}
   />
  );
 };

 const onContactFormOpen = () => {
  setDialogConfig({
   title: 'Create Contact',
   open: true,
   onClose: onContactFormClose,
   type: 'contact',
   actions: [
    {
     label: 'Cancel',
     onClick: onContactFormClose,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Create',
     onClick: () => handleSubmit(onContactCreate)(),
     classes: 'btn-primary'
    }
   ]
  });
  ResourceApi.tag.get().then(({data}) => {
   setTags(data || []);
  });
 };

 const showEmptyState = !isContactLoading && contacts.length === 0;

 return (
  <>
   <CustomDialog
    {...dialogConfig}
    content={
     dialogConfig.type === 'contact' ? (
      <ContactCreateForm  />
     ) : (
      dialogConfig.content
     )
    }
   />
   <Header
    title="All Contacts"
    actions={[
     {
      label: '',
      isPrimary: false,
      icon: iconPreference,
      onClick: handleDrawerOpen
     },
     {
      label: '',
      isPrimary: false,
      icon: iconDowload
     },
     {
      label: 'Import Contacts',
      isPrimary: false,
      icon: iconUpload
     },
     {
      label: 'Create Contact',
      isPrimary: true,
      icon: iconAddCircleWhite,
      onClick: onContactFormOpen
     }
    ]}
   />
   {!showEmptyState && (
    <div className="main-content-container">
     <Table
      headers={[
       {
        numeric: false,
        key: 'name',
        disablePadding: false,
        onClick: row => navigate(`/contacts/allContacts/${row.id}`),
        label: 'Name',
        render: row => `${row.firstName} ${row.lastName}`
       },
       {
        numeric: false,
        key: 'email',
        disablePadding: false,
        label: 'Email'
       },
       {
        numeric: false,
        key: 'phone',
        disablePadding: false,
        label: 'Phone'
       },
       {
        numeric: false,
        key: 'created',
        disablePadding: false,
        label: 'Created On',
        render: row => {
         return <div>{formatDate(row.created)}</div>;
        }
       },
       {
        numeric: false,
        key: 'tagResources',
        disablePadding: false,
        label: 'Tags',
        render: row => {
         return (
          <Stack direction="row" spacing={1}>
           {/* {row.tagResources.length
            ? row.tagResources.map(({name, id}) => (
               <Chip size="small" label={name} key={id} />
              ))
            : '_'} */}
          </Stack>
         );
        }
       },
       {
        numeric: false,
        key: 'status',
        disablePadding: false,
        label: 'Status',
        render: ({status}) =>
         status ? (
          <Chip
           size="small"
           label={status}
           sx={{backgroundColor: '#DCF2ED', color: '#09B29C'}}
          />
         ) : (
          '_'
         )
       }
      ]}
      rows={contacts}
      isLoading={isContactLoading}
      actions={[
       {
        label: 'Delete',
        onClick: ids => {
         setDialogConfig({
          open: true,
          title: 'Delete Contacts',
          onClose: onCloseDialog,
          content: `Are you sure you want to delete ${ids.length} contact(s)?`,
          actions: [
           {
            label: 'Cancel',
            onClick: onCloseDialog,
            classes: 'btn-outline dark-border'
           },
           {
            label: 'Delete',
            onClick: () => onContactDelete(ids),
            classes: 'btn-primary'
           }
          ]
         });
        }
       }
      ]}
     />
    </div>
   )}
   {showEmptyState && (
    <EmptyState
     title="Manage Your Contacts"
     description="View, organize, and handle them individually or in groups to engage customers"
     options={[
      {
       title: 'Import Contacts',
       description:
        'Easily Import Multiple Contacts with a Single Click to add',
       action: {
        label: 'Import Contacts',
        icon: iconUpload,
        onClick: () => {}
       },
       banner: importBanner,
       bannerBackground: 'yellow'
      },
      {
       title: 'Create Contact Manually',
       description:
        'Enter Contact Details to Build Your Network One Person at a Time',
       action: {
        label: 'Create Contact',
        icon: iconAddCircle,
        onClick: onContactFormOpen
       },
       banner: createBanner,
       bannerBackground: 'green'
      }
     ]}
    />
   )}
   <Message
    open={alert.show}
    type={alert.type}
    message={alert.message}
    onMessageClose={() => {
     setAlert({
      type: '',
      show: false,
      message: ''
     });
    }}
   />
   <MyDrawer isOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
  </>
 );
};

export default AllContacts;
