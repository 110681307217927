import ButtonIcon from "../../../../../assets/icons/icon-button.svg";
export const registerButtonBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for buttonBlock

  editor.DomComponents.addType("Button", {
    model: {
      defaults: {
        tagName: "span",
        attributes:{'data-gjs-type': 'Button'},
        classes: ["block-div"],
        style: { display: "block !important", width: "100%", "text-align": "center" },
        components: [
          {
            tagName: "a",
            attributes: { href: "", target: "_blank" },
            components: [
              {
                tagName: "span",
                type: "text", // Using 'text' type to enable RTE
                content: "Button", // Default button text
                classes: ["button"],
                editable: true, // Enable editing of the button text
                style: {
                  display: "inline-block",
                  padding: brandStyles?.button?.padding || "8px 16px",
                  "background-color": brandStyles?.button?.backgroundColor || '#ff385c',
                  "border-radius": brandStyles?.button?.borderRadius || "8px",
                  "text-align": "center",
                  color: brandStyles?.button?.color || '#ffffff',
                  "font-size": brandStyles?.button?.fontSize || '16px',
                  width: brandStyles?.button?.width || 'auto',
                },
              }
            ],
            droppable: false,            
          },
        ],
        droppable: false, 
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for buttonBlock
  editor.BlockManager.add("buttonBlock", {
    // label: `<div data-category="Block"><img src=${ButtonIcon} alt="Button Icon" height="24px" width="24px"/><div class="block-label">Button</div></div>`,
    label: `
      <div class="gjs-block-label-container" data-category="Block">
        <img src="${ButtonIcon}" alt="Button Icon" height="24" width="24">
      </div>
      <div class="block-label-text">Button</div>
    `,
    content: { type: "Button" },
    category: "Blocks",
    draggable: "td",
  });
};
