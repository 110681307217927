import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { template } from "../api/templateapi";
import { format } from "date-fns";
import { setTemplateData } from "../store/store.js";

export const useTemplates = () => {
  const dispatch = useDispatch();
  const templateData = useSelector((state) => state.templateData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await template.getTemplate();
      const data = response.data.map((item) => ({
        id: item.id,
        name: item.name,
        created: format(new Date(item.created), "MMMM dd, yyyy h:mm a"),
        updated: format(new Date(item.updated), "MMMM dd, yyyy h:mm a"),
        contact: item.contact || 0,
        sent: item.sent || 0,
        open: item.open || 0,
        clicked: item.clicked || 0,
        unsubscribed: item.unsubscribed || 0,
        htmlContent: item.htmlContent,
      }));
      dispatch(setTemplateData(data));
    } catch (err) {
      setError("Error fetching data");
      console.error("Error fetching data", err);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);


  return { templateData, loading, error, fetchTemplates };
};
