import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setBrandColors } from "../../../../../../store/store";
import GradientPicker from "react-best-gradient-color-picker";
import iconAdd from "../../../../../../assets/icons/icon-add-circle.svg";

const ColorTraitManager = ({ brandColors, setColorTraits }) => {
  const dispatch = useDispatch();
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [hoveredColor, setHoveredColor] = useState(null);

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleAddColorClick = () => {
    setShowPicker(true);
  };

  const handleSaveColor = () => {
    setShowPicker(false);
    if (!brandColors.includes(selectedColor)) {
      const updatedColors = [...brandColors, selectedColor];
      dispatch(setBrandColors(updatedColors));
      setColorTraits(updatedColors);
    }
  };

  const handleRemoveColor = (colorToRemove) => {
    const updatedColors = brandColors.filter(
      (color) => color !== colorToRemove
    );
    dispatch(setBrandColors(updatedColors));
    setColorTraits(updatedColors);
  };

  return (
    <div className="global-trait vertical">
      <label>
        Saved colors will appear every time you open the color picker.
      </label>
      <div className="color-options">
        {brandColors.map((color) => (
          <div
            key={color}
            className="color-option-wrapper"
            onMouseEnter={() => setHoveredColor(color)}
            onMouseLeave={() => setHoveredColor(null)}
            style={{ position: "relative" }}
          >
            <button
              key={color}
              className="color-option"
              style={{ background: color }}
              onClick={() => handleColorChange(color)}
            ></button>
            {hoveredColor === color && (
              <span
                onClick={() => handleRemoveColor(color)}
                
              >
                x
              </span>
            )}
          </div>
        ))}
        <button onClick={handleAddColorClick} className="add-color-button">
          <img src={iconAdd} />
        </button>
      </div>
      {showPicker && (
        <div className="color-picker-modal" style={{ marginTop: "8px" }}>
          <div className="color-palette">
            <GradientPicker
              value={selectedColor}
              onChange={handleColorChange}
              hideOpacity={true}
              hideAdvancedSliders={true}
              hideColorGuide={true}
              hideInputType={true}
              hideColorTypeBtns={false}
              hideInputs={true}
            />
          </div>
          <button onClick={handleSaveColor} className="save-color-button">
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default ColorTraitManager;
