import React from 'react'

const AbandonedCart = () => {
  return (
    <div>
    <div className="header">
      <div className="header-left">
        <span className="template-name"> AbandonedCart </span>
      </div>
      <div className="header-center"></div>

      <div className="header-right"></div>
    </div>
    <div>AbandonedCart</div>
  </div>
    
  )
}

export default AbandonedCart