import LogoIcon from "../../../../../assets/icons/icon-site.svg";
export const registerLogoBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for imageBlock
  editor.DomComponents.addType("Logo", {
    model: {
      defaults: {
        tagName: "span",
        attributes: { "data-gjs-type": "Logo" },
        classes: ["block-div"],
        style: {
          display: "block !important",
          width: "100%",
          padding: brandStyles?.padding
        },
        components: [
          {
            tagName: "a",
            attributes: { href: brandStyles?.link, target: "_blank" },
            components: [
              {
                tagName: "img",
                type: "image",
                attributes: { src: brandStyles?.logo, alt: "" },
                editable: false,
                style: {
                  display: "inline-block",
                  border: "none",
                  outline: "none",
                  width: `${brandStyles?.widthInPercentage}%`,
                  "border-radius": brandStyles?.borderRadius,
                },
              },
            ],
          },
        ],
        // Enable inline styling
        stylable: true,
        droppable: false,
        script: function () {
          this.addEventListener(
            "dblclick",
            function (event) {
              event.stopPropagation(); // Stops the double click event
            },
            true
          );
        },
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for imageBlock
  editor.BlockManager.add("logoBlock", {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${LogoIcon} alt="Image Icon" height="24px" width="24px"/></div>
      <div class="block-label-text">SiteLogo</div>`,
    content: { type: "Logo" },
    category: "Blocks",
    draggable: "td", // Specifies where the block can be dropped
  });
};
