import React, {useEffect} from 'react';
import Card from '@mui/material/Card';
import {Handle, useReactFlow} from 'reactflow';
import {
 Avatar,
 CardHeader,
 Chip,
 Grid,
 IconButton,
 Typography
} from '@mui/material';
import {red} from '@mui/material/colors';
import iconMore from '../../../assets/icons/icon-more.svg';
import iconTag from '../../../assets/icons/icon-tag-automation.svg';
import iconClose from '../../../assets/icons/icon-cross.svg';
import iconEmail from '../../../assets/icons/icon-email.svg';
import iconTime from '../../../assets/icons/icon-time.svg';
import iconElse from '../../../assets/icons/icon-Condition.svg';
import iconSplit from '../../../assets/icons/icon-AB.svg';
import iconContact from '../../../assets/icons/icon-contact-status.svg';
import iconList from '../../../assets/icons/icon-order-listing.svg';
import iconSkip from '../../../assets/icons/icon-skip-step.svg';
import iconGoal from '../../../assets/icons/icon-target.svg';
import iconExit from '../../../assets/icons/icon-exit.svg';
import iconDoThis from '../../../assets/icons/icon-dothis.svg';
import iconLight from '../../../assets/icons/icon-light.svg';
import {useCallback, useState} from 'react';
import {type} from '@testing-library/user-event/dist/type';
import CustomMenu from '../../../components/Menu/Menu';
const Node = ({id, data}) => {
 // Sample chip data array
 const {setNodes, getNodes} = useReactFlow();
 const {setEdges, getEdges} = useReactFlow();
 const [statusAnchorElement, setStatusAnchorElement] = useState(null);
 const nodes = getNodes();
 const currentNode = nodes.filter(node => node.id === id);
 console.log(currentNode);
 const currentNodeIndex = nodes.findIndex(node => node.id === id);
 let options;
 if ('type' in currentNode[0].data && currentNode[0].data.type === 'event') {
  options = [
   {
    icon: 'icon-refresh',
    label: 'Change event',
    value: 'add sender'
   }
  ];
 } else {
  options = [
   {
    label: 'Delete',
    value: 'delete'
   },
   {
    label: 'Duplicate',
    value: 'duplicate'
   }
  ];
 }

 const duplicateNode2 = () => {
  let nodeAfter, nodeBefore;
  nodeBefore = nodes[currentNodeIndex - 1];
  const edges = getEdges();
  const connectedEdges = edges.filter(
   edge => edge.source === currentNode[0].id
  )[0];
  nodeAfter = nodes.filter(nd => nd.id === connectedEdges.target)[0];
  const newNodes = [
   {
    ...currentNode[0],
    id: `${currentNode[0].id}.${nodeAfter.id}`, // New unique ID
    position: {x: currentNode[0].position.x, y: currentNode[0].position.y + 200} // Adjust position slightly
   }
  ];
  const remainingNodes = nodes.slice(currentNodeIndex + 1).map(node => ({
   ...node,
   position: {...node.position, y: node.position.y + 200}
  }));

  const updatedNodes = [
   ...nodes.slice(0, currentNodeIndex + 1),
   ...newNodes,
   ...remainingNodes
  ];

  setNodes(updatedNodes);

  const newEdges = [
   {
    id: `${currentNode[0].id}-${currentNode[0].id}.${nodeAfter.id}`,
    type: 'custom-edge',
    source: currentNode[0].id,
    target: `${currentNode[0].id}.${nodeAfter.id}`,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   },
   {
    id: `${currentNode[0].id}.${nodeAfter.id}-${nodeAfter.id}`,
    type: 'custom-edge',
    source: `${currentNode[0].id}.${nodeAfter.id}`,
    target: nodeAfter.id,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   }
  ];

  setEdges(eds => {
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(
    edges => edges.source !== currentNode[0].id
   );
   return [...updatedEdges, ...newEdges];
  });
 };

 const duplicateNode = count => {
  let edges = getEdges();
  console.log(count);
  const nodesToDuplicate = nodes.slice(
   currentNodeIndex,
   currentNodeIndex + count
  );
  let n = nodesToDuplicate.length - 1;
  let yPosition =
   nodesToDuplicate[n].position.y - nodesToDuplicate[0].position.y + 100;
  // Create new nodes as duplicates with unique IDs
  console.log(yPosition);
  const newNode = nodesToDuplicate.map((node, index) => {
   return {
    ...node,
    id: `${node.id}-duplicate`, // Give new unique IDs
    position: {x: node.position.x, y: node.position.y + yPosition} // Adjust position slightly
   };
  });
  console.log(newNode);

  // Create a mapping from old node IDs to new node IDs
  let newEdges1 = [];
  // Duplicate the edges between the nodes
  edges.forEach(edge => {
   // Check if the edge connects two nodes that are being duplicated
   const sourceNodeIndex = nodesToDuplicate.findIndex(
    node => node.id === edge.source
   );
   const targetNodeIndex = nodesToDuplicate.findIndex(
    node => node.id === edge.target
   );

   if (sourceNodeIndex !== -1 && targetNodeIndex !== -1) {
    // Create a new edge that connects the duplicated nodes
    const newEdge = {
     ...edge,
     id: `${edge.id}-duplicate`,
     source: `${edge.source}-duplicate`,
     target: `${edge.target}-duplicate`
    };

    newEdges1.push(newEdge);
   }
  });

  if (newNode.length > 0) {
   const firstNode = newNode[0];
   const lastNode = newNode[newNode.length - 1];

   // Create an edge from the first duplicated node to the firstNodeTargetId
   const firstNodeEdge = {
    id: `${nodesToDuplicate[n].id}-to-${firstNode.id}`,
    source: nodesToDuplicate[n].id,
    target: firstNode.id,
    type: 'custom-edge'
   };

   // Create an edge from the last duplicated node to the lastNodeTargetId
   const lastNodeEdge = {
    id: `${lastNode.id}-to-${nodes[currentNodeIndex + count].id}`,
    source: lastNode.id,
    target: nodes[currentNodeIndex + count].id,
    type: 'custom-edge'
   };

   newEdges1.push(firstNodeEdge);
   newEdges1.push(lastNodeEdge);
  }
  console.log(newEdges1);

  const remainingNodes = nodes.slice(currentNodeIndex + count).map(node => ({
   ...node,
   position: {...node.position, y: node.position.y + yPosition}
  }));

  const updatedNodes = [
   ...nodes.slice(0, currentNodeIndex + count),
   ...newNode,
   ...remainingNodes
  ];

  setNodes(updatedNodes);
  let id1 = nodesToDuplicate[n].id;
  let id2 = nodes[currentNodeIndex + count].id;
  setEdges(eds => {
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(
    ed => ed.source !== id1 && ed.target !== id2
   );
   return [...updatedEdges, ...newEdges1];
  });
 };

 const deleteNode = nodeNumber => {
  let nodeBefore, nodeAfter;
  setNodes(nds => {
   // Delete the specified nodes
   const updatedNodes = [...nds];
   nodeBefore = updatedNodes[currentNodeIndex - 1];
   nodeAfter = updatedNodes[currentNodeIndex + nodeNumber];
   const nodeAfterIndex = currentNodeIndex + nodeNumber;
   const nodeAfter2 = updatedNodes[nodeAfterIndex];

   let xPosition = 0;
   let yPosition = 0;
   if (nodeNumber === 1) {
    xPosition = 4;
    yPosition = 100;
   }
   // Update the positions of the remaining nodes
   console.log(updatedNodes.length);
   if (nodeAfterIndex === updatedNodes.length - 1) {
    // Only one node is left after the deleted nodes
    if (nodeAfter2) {
     updatedNodes[updatedNodes.length - 1].position = {
      x: nodeAfter2.position.x - 4 + xPosition,
      y: nodeAfter2.position.y - 350 + yPosition * 1.5 // Adjust the position of the last node
     };
    }
   } else {
    // Update the positions of the remaining nodes after the deleted range
    for (let i = currentNodeIndex; i < updatedNodes.length; i++) {
     updatedNodes[i].position = {
      ...updatedNodes[i].position,
      y: updatedNodes[i].position.y - 400 + 2 * yPosition
     };
    }
   }
   updatedNodes.splice(currentNodeIndex, nodeNumber);
   return updatedNodes;
  });

  setEdges(eds => {
   // Remove edges connected to the deleted nodes
   const deletedNodeIds = nodes
    .slice(currentNodeIndex, currentNodeIndex + nodeNumber)
    .map(node => node.id);
   let updatedEdges = eds.filter(
    edge =>
     !deletedNodeIds.includes(edge.source) &&
     !deletedNodeIds.includes(edge.target)
   );

   // Add a new edge connecting the node before to the node after
   if (nodeBefore && nodeAfter) {
    const newEdge = {
     id: `${nodeBefore.id}-${nodeAfter.id}`,
     source: nodeBefore.id,
     target: nodeAfter.id,
     type: 'custom-edge'
    };
    updatedEdges = [...updatedEdges, newEdge];
   }

   return updatedEdges;
  });
 };

 return (
  <>
   <Card
    sx={{
     minWidth: '240px',
     padding: '12px 0px 8px 0px',
     borderRadius: '12px',
     border: '0.5px solid #dfdded'
    }}
   >
    {!data.addStep && (
     <>
      <div
       style={{
        position: 'absolute',
        top: '-27px', // Position it 2px above the Card
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
        display: 'flex',
        gap: '4px',
        alignItems: 'center'
       }}
       className="status start"
      >
       <img src={iconLight} alt="dothis" /> <div>Start When</div>
      </div>

      <div className="node">
       <div className="node-header">
        <div className="node-label">
         <div className="icon-label">
          <div className="icon">
           <img
            src={iconTag}
            alt="tag"
            style={{width: '15px', height: '15px'}}
           />
          </div>
          <div>{data.header}</div>
         </div>
         <div
          onClick={event => {
           console.log('iconMore is clicked');
           setStatusAnchorElement(event.currentTarget);
          }}
         >
          <img src={iconMore} alt="more" />
         </div>
        </div>
        <div className="header-description">
         <div className="text">{data.headerDescription}</div>
         <div className="status ERROR">{data.status}</div>
        </div>
       </div>
       <div className="horizontal-line"></div>
       <div className="node-body">
        {data.bodyContent.map(data => {
         return (
          <>
           <div className="body-element">
            <div className="label">{data.label}</div>
            <div className="number">{data.data}</div>
           </div>
          </>
         );
        })}
       </div>
      </div>
      <Handle
       type="source"
       position="bottom"
       id="source-handle"
       style={{background: '#555'}}
      />
      <Handle
       type="target"
       position="top"
       id="target-handle"
       style={{background: '#555'}}
      />
     </>
    )}
   </Card>
   <CustomMenu
    onClose={() => {
     setStatusAnchorElement(null);
    }}
    onSelect={value => {
     setStatusAnchorElement(null);
     if (value === 'delete') {
      if (
       'flag' in currentNode[0].data &&
       currentNode[0].data.flag === 'conditionalStart'
      ) {
       deleteNode(4);
      } else {
       deleteNode(1);
      }
     } else if (value === 'duplicate') {
      if (currentNode[0].data.flag === 'conditionalStart') {
       const firstChar = currentNode[0].id[0];
       let count = 0;

       nodes.forEach(node => {
        if (node.id[0] === firstChar) {
         count++;
        }
       });
       duplicateNode(count);
      } else {
       duplicateNode2();
      }
     }
    }}
    options={options}
    anchorEl={statusAnchorElement}
    inputBox={true}
   />
  </>
 );
};

export default Node;
