import * as zod from 'zod';

const loginSchema = zod.object({
 email: zod
  .string()
  .email({message: 'Please enter valid email id'})
  .min(1, {message: 'Please enter email id'}),
 password: zod
  .string()
  .min(6, {message: 'Password must be at least 6 characters long'})
  .min(1, {message: 'Please enter password'})
});

const resetPassSchema = zod
 .object({
  password: zod
   .string()
   .min(6, {message: 'Password must be at least 6 characters long'})
   .min(1, {message: 'Please enter password'}),
  confirmPassword: zod.string().min(1, {message: 'Please enter password'})
 })
 .refine(data => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ['confirmPassword'] // path of error m
 });

const resetSchema = zod.object({
 email: zod
  .string()
  .email({message: 'Please enter valid email id'})
  .min(1, {message: 'Please enter email id'})
});

const signupSchema = zod.object({
 firstName: zod.string().min(1, {message: 'Please enter first name'}),
 lastName: zod.string().min(1, {message: 'Please enter last name'}),
 email: zod
  .string()
  .email({message: 'Please enter valid email id'})
  .min(1, {message: 'Please enter email id'}),
 password: zod
  .string()
  .min(6, {message: 'Password must be at least 6 characters long'})
  .min(1, {message: 'Please enter password'})
});

export {loginSchema, signupSchema, resetSchema, resetPassSchema};
