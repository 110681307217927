// hooks/useLayouts.js
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLayoutData } from '../store/store';
import { template } from '../api/templateapi';

export const useLayouts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const layoutData = useSelector(state => state.layoutData);

  const fetchLayouts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      console.log("Fetching saved layouts");
      const response = await template.getLayout();
      const data = response.data.map((item) => ({
        id: item.id,
        name: item.name,
        htmlContent: item.htmlContent,
        cssContent: item.cssContent,
      }));
      dispatch(setLayoutData(data));
      console.log("saved layouts data fetched");
    } catch (err) {
      setError('Error fetching layouts');
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  return { layoutData, loading, error, fetchLayouts };
};
