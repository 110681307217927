import React, {useState} from 'react';
import {Bar} from 'react-chartjs-2';
import {
 Chart as ChartJS,
 CategoryScale,
 LinearScale,
 BarElement,
 Title,
 Tooltip,
 Legend
} from 'chart.js';

// Register necessary Chart.js components
ChartJS.register(
 CategoryScale,
 LinearScale,
 BarElement,
 Title,
 Tooltip,
 Legend
);

const generateData = filter => {
 const now = new Date();
 let labels = [];
 let data = [];
 const currentDataIndex = filter === 'week' ? 6 : filter === 'month' ? 29 : 11;

 switch (filter) {
  case 'week':
   for (let i = 6; i >= 0; i--) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    labels.push(date.toISOString().split('T')[0]);
    data.push(Math.floor(Math.random() * 100)); // Replace with your actual data logic
   }
   break;
  case 'month':
   for (let i = 29; i >= 0; i--) {
    const date = new Date(now);
    date.setDate(now.getDate() - i);
    labels.push(date.toISOString().split('T')[0]);
    data.push(Math.floor(Math.random() * 100)); // Replace with your actual data logic
   }
   break;
  case 'year':
   for (let i = 11; i >= 0; i--) {
    const date = new Date(now);
    date.setMonth(now.getMonth() - i);
    labels.push(date.toISOString().split('T')[0].slice(0, 7));
    data.push(Math.floor(Math.random() * 100)); // Replace with your actual data logic
   }
   break;
  default:
   break;
 }

 return {
  labels,
  datasets: [
   {
    label: 'Dataset',
    data,
    backgroundColor: '#F0E5FC',
    hoverBackgroundColor: '#962DFF',
    borderColor: '#F0E5FC',
    borderWidth: 1,
    borderRadius: 7
   }
  ]
 };
};

const BarChart = () => {
 const [filter, setFilter] = useState('week');

 const data = generateData(filter);
 const options = {
  responsive: true,
  plugins: {
   legend: {
    position: 'top'
   },
   title: {
    display: true,
    text: 'Bar Chart'
   }
  },
  scales: {
   x: {
    grid: {
     display: false
    },
    barPercentage: 0.4, // Adjust bar percentage to control bar width
    categoryPercentage: filter === 'week' ? 0.5 : 0.8 // Adjust the gap between bars
   },
   y: {
    grid: {
     color: 'rgba(128, 128, 128, 0.2)',
     borderDash: [5, 0] // Dashed lines
    }
   }
  }
 };

 return (
  <div className="barGraph">
   <div className="filters">
    <button
     className={`filter-button ${filter === 'week' ? 'selected' : ''}`}
     onClick={() => setFilter('week')}
    >
     Week
    </button>
    <button
     className={`filter-button ${filter === 'month' ? 'selected' : ''}`}
     onClick={() => setFilter('month')}
    >
     Month
    </button>
    <button
     className={`filter-button ${filter === 'year' ? 'selected' : ''}`}
     onClick={() => setFilter('year')}
    >
     Year
    </button>
   </div>
   <Bar data={data} options={options} />
  </div>
 );
};

export default BarChart;
