import React, { useEffect, useState } from "react";
import Sortable from "sortablejs";
import AddSocialIconTrait from "../TraitFunctions/AddSocialIcon";
import SocialLinkCombinedTrait from "../TraitFunctions/SocialLinkCombined";
import { socialTypes } from "../../customBlocks/socialTypes";

const SocialTraitManager = ({
  htmlContent,
  updateHtmlContent,
  socialTraits,
  setSocialTraits,
}) => {
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const sortedLinks = Object.keys(socialTraits).map((key) => {
      const socialType = socialTypes.find((s) => s.id === key);
      return {
        id: key,
        url: socialTraits[key],
        icon: socialType ? socialType.icon : "",
        name: socialType ? socialType.name : "",
      };
    });
    setSocialLinks(sortedLinks);
  }, [socialTraits]);

  useEffect(() => {
    const el = document.getElementById("sortable-div");
    if (el && !el.dataset.initialized) {
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          const { newIndex, oldIndex } = evt;
          if (newIndex !== oldIndex) {
            setSocialLinks((prevSocialLinks) => {
              const updatedSocialLinks = [...prevSocialLinks];
              const movedLink = updatedSocialLinks.splice(oldIndex, 1)[0];
              updatedSocialLinks.splice(newIndex, 0, movedLink);

              // Update socialTraits and htmlContent based on new order
              const updatedSocialTraits = {};
              updatedSocialLinks.forEach((link) => {
                updatedSocialTraits[link.id] = link.url;
              });

              setSocialTraits(updatedSocialTraits);
              updateSocialHTML(updatedSocialTraits);

              return updatedSocialLinks;
            });
          }
        },
      });
      el.dataset.initialized = "true";
    }
  }, [socialLinks, setSocialTraits]);

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  const updateSocialHTML = (updatedTraits) => {
    const doc = parseHtml(htmlContent);
    const socialBlock = doc.querySelector(".social-block");
    if (socialBlock) {
      socialBlock.innerHTML = ""; // Clear existing content
      Object.keys(updatedTraits).forEach((socialId) => {
        const social = socialTypes.find((s) => s.id === socialId);
        if (social) {
          const newAnchor = doc.createElement("a");
          newAnchor.href = updatedTraits[socialId];
          newAnchor.target = "_blank";
          newAnchor.style.boxSizing = "border-box";
          newAnchor.style.marginLeft = "4px";
          newAnchor.style.marginRight = "4px";

          const newImage = doc.createElement("img");
          newImage.src = social.icon;
          newImage.alt = socialId;
          newImage.width = 24;
          newImage.style.boxSizing = "border-box";
          newImage.style.width = "24px";
          newImage.style.borderRadius = "4px";

          newAnchor.appendChild(newImage);
          socialBlock.appendChild(newAnchor);
        }
      });
      updateHtmlContent(serializeHtml(doc));
    }
  };

  const handleSelect = (social) => {
    const updatedTraits = {
      ...socialTraits,
      [social.id]: social.url,
    };

    setSocialTraits(updatedTraits);
    updateSocialHTML(updatedTraits);
  };

  const handleDelete = (index) => {
    const updatedSocialTraits = { ...socialTraits };
    const socialKey = Object.keys(updatedSocialTraits)[index];
    delete updatedSocialTraits[socialKey];
    setSocialTraits(updatedSocialTraits);

    updateSocialHTML(updatedSocialTraits);
  };

  const handleInputChange = (socialId, newUrl) => {
    const updatedTraits = {
      ...socialTraits,
      [socialId]: newUrl,
    };
    setSocialTraits(updatedTraits);

    updateSocialHTML(updatedTraits);
  };

  return (
    <div className="social-trait-manager">
      <AddSocialIconTrait onSelect={handleSelect} socialTraits={socialTraits} />
      <div id="sortable-div" className="traits-container">
        {socialLinks.map((link, index) => (
          <SocialLinkCombinedTrait
            key={link.id} // Ensure that the key is stable and tied to the id
            trait={{
              index,
              id: link.id,
              value: link.url,
              icon: link.icon,
              alt: link.name,
            }}
            onDelete={() => handleDelete(index)} // Pass index directly
            onChange={handleInputChange} // Pass id directly
          />
        ))}
      </div>
    </div>
  );
};

export default SocialTraitManager;
