import React, {useEffect, useState} from 'react';
import Header from '../../components/Header/Header';
import EmptyState from '../../components/EmptyState';
import iconAddCircleWhite from '../../assets/icons/icon-add-white.svg';
import iconAddUser from '../../assets/icons/icon-add-circle.svg';
import iconPreview from '../../assets/icons/icon-preview.svg';
import importBanner from '../../assets/icons/undraw_innovative_re_rr5i 1.png';
import createBanner from '../../assets/icons/undraw_asset_selection_re_k5fj 1.png';
import {Stack, IconButton, Icon, Typography, Card} from '@mui/material';
import iconBack from '../../assets/icons/icon-back.svg';
import ResourceApi from '../../api/resourceapi';
import {Navigate, useNavigate} from 'react-router-dom';
import Table from '../../components/Table';
import Chip from '@mui/material/Chip';
import formatDate from '../../utils/date-utils';
import DynamicTabs from '../../components/Tab/Tab';
import Charts from './Components/Charts';
import DateTimePickerComponent from '../../components/Calendar/Calendar';
import EmptyEmailTemplate from '../../components/EmptyEmailTemplated/EmptyTemplate';
import CustomDialog from '../../components/Dialog/Dialog';
import {auth} from '../../api/authapi';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import Status from '../../components/Status/Status';
const Campaigns = () => {
 const [showBanner, setShowBanner] = useState(true);
 const [startCampaign, setStartCampaign] = useState(false);
 const [campaign, setCampaign] = useState({});
 const [data, setData] = useState([]);
 const [archivedData, setArchivedData] = useState([]);
 const navigate = useNavigate();
 const [isCampaignLoading, setIsCampaignLoading] = useState(true);
 const [deleteDialog, setDeleteDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const {register, handleSubmit} = useForm();
 const [archiveDialog, setArchiveDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 useEffect(() => {
  fetchCampaign();
  fetchArchiveCampaign();
  console.log(campaign);
 }, []);
 const fetchCampaign = (id = false) => {
  ResourceApi.campaign.get().then(({data}) => {
   setIsCampaignLoading(false);
   setData(data);
   console.log(data);
   console.log('data is fetched for current campaign');
  });
 };
 const fetchArchiveCampaign = () => {
  ResourceApi.campaign.getArchive().then(({data}) => {
   console.log('archived campaign is fetched');
   console.log(data);
   setArchivedData(data);
  });
 };

 const deleteCampaign = ids => {
  console.log(ids);
  const data = {
   ids: ids
  };
  ResourceApi.campaign.delete(data).then(() => {
   console.log('campaign deleted');
   fetchCampaign();
   fetchArchiveCampaign();
   CloseDeleteDialog();
  });
 };
 const CloseDeleteDialog = () => {
  setDeleteDialog(prevConfig => ({...prevConfig, open: false}));
 };

 const archiveCampaign = async ids => {
  console.log(ids[0]);
  const token = localStorage.getItem('loginToken');
  try {
   const response = await auth.archiveCampaign(ids, {
    Authorization: `${token}`
   });
   console.log('campaign archived successfully');
   console.log(response);
   CloseArchiveDialog();
   fetchCampaign();
   fetchArchiveCampaign();
  } catch (error) {
   console.log(error);
  }
 };
 const CloseArchiveDialog = () => {
  setArchiveDialog(prevConfig => ({...prevConfig, open: false}));
 };

 const onCampaignCreate = data => {
  const campaign = {name: data.campaignName};
  setCampaign(prevState => ({
   ...prevState,
   name: data.campaignName
  }));
  ResourceApi.campaign
   .create(campaign)
   .then(({data}) => {
    console.log(data);
    navigate(`/campaigns/${data.id}`);

    fetchCampaign();
    console.log('campaign created successfully');

    //    setAlert({
    //     type: 'success',
    //     show: true,
    //     message: 'Contact created successfully'
    //    });
   })
   .catch(error => {
    // setError('email', {
    //  type: 'manual',
    //  message: error.response?.data?.message || ''
    // });
   });
 };
 const addFieldToCampaign = (fieldName, fieldValue) => {
  setCampaign(prevState => ({
   ...prevState,
   [fieldName]: fieldValue
  }));
  onCampaignCreate();
 };
 const updateName = data => {
  console.log(data.campaignName);
  addFieldToCampaign('name', data.campaignName);
 };

 const tabs = ['All', 'Ongoing', 'Scheduled', 'Completed', 'Pause', 'Archive'];
 const panels = [
  <Table
   headers={[
    {
     numeric: false,
     key: 'name',
     disablePadding: false,
     label: 'Name',
     onClick: row => {
      navigate(`/campaigns/${row.id}`);
     },
     render: row => `${row.name}`
    },
    {
     numeric: false,
     key: 'created',
     disablePadding: false,
     label: 'Created On',
     render: row => {
      return <div>{formatDate(row.created, true)}</div>;
     }
    },

    {
     numeric: false,
     key: 'contacts',
     disablePadding: false,
     label: 'Contacts'
    },
    {
     numeric: false,
     key: 'sent',
     disablePadding: false,
     label: 'Sent'
    },
    {
     numeric: false,
     key: 'open',
     disablePadding: false,
     label: 'Open'
    },
    {
     numeric: false,
     key: 'click',
     disablePadding: false,
     label: 'Click'
    },
    {
     numeric: false,
     key: 'unsubscribe',
     disablePadding: false,
     label: 'Unsubscribe'
    },
    {
     numeric: false,
     key: 'spam',
     disablePadding: false,
     label: 'Marked as spam'
    },

    {
     numeric: false,
     key: 'status',
     disablePadding: false,
     label: 'Status',
     render: ({status}) => (status ? <Status status={status} /> : '_')
    }
   ]}
   rows={data}
   isLoading={isCampaignLoading}
   actions={[
    {
     label: 'Delete',
     onClick: ids => {
      setDeleteDialog({
       open: true,
       title: 'Delete Campaigns',
       onClose: CloseDeleteDialog,
       type: 'deleteCampaign',
       content: `Are you sure you want to delete ${ids.length} campaigns(s)?`,
       actions: [
        {
         label: 'Cancel',
         onClick: CloseDeleteDialog,
         classes: 'btn-outline dark-border'
        },
        {
         label: 'Delete',
         onClick: () => deleteCampaign(ids),
         classes: 'btn-primary'
        }
       ]
      });
     }
    },
    {
     label: 'Archive',
     onClick: ids => {
      setArchiveDialog({
       open: true,
       title: 'Archive Campaigns',
       onClose: CloseArchiveDialog,
       type: 'archiveCampaign',
       content: `Archiving this deal will remove it from active status and it will no longer be visible in your current deals list. You can still access it in the archive section.`,
       actions: [
        {
         label: 'Cancel',
         onClick: CloseArchiveDialog,
         classes: 'btn-outline dark-border'
        },
        {
         label: 'Archive',
         onClick: () => archiveCampaign(ids),
         classes: 'btn-primary'
        }
       ]
      });
     }
    }
   ]}
  />,

  <div>Content for Tab Two</div>,
  <div>Content for Tab three</div>,
  <div>Content for Tab Four</div>,
  <div>Content for Tab Five</div>,
  <Table
   headers={[
    {
     numeric: false,
     key: 'name',
     disablePadding: false,
     label: 'Name',
     onClick: row => {
      navigate(`/campaigns/${row.id}`);
     },
     render: row => `${row.name}`
    },
    {
     numeric: false,
     key: 'created',
     disablePadding: false,
     label: 'Created On',
     render: row => {
      return <div>{formatDate(row.created, true)}</div>;
     }
    },

    {
     numeric: false,
     key: 'contacts',
     disablePadding: false,
     label: 'Contacts'
    },
    {
     numeric: false,
     key: 'sent',
     disablePadding: false,
     label: 'Sent'
    },
    {
     numeric: false,
     key: 'open',
     disablePadding: false,
     label: 'Open'
    },
    {
     numeric: false,
     key: 'click',
     disablePadding: false,
     label: 'Click'
    },
    {
     numeric: false,
     key: 'unsubscribe',
     disablePadding: false,
     label: 'Unsubscribe'
    },
    {
     numeric: false,
     key: 'spam',
     disablePadding: false,
     label: 'Marked as spam'
    },

    {
     numeric: false,
     key: 'status',
     disablePadding: false,
     label: 'Status',
     render: ({status}) =>
      status ? (
       <Chip
        size="small"
        label={status}
        sx={{backgroundColor: '#DCF2ED', color: '#09B29C'}}
       />
      ) : (
       '_'
      )
    }
   ]}
   rows={archivedData}
   //  isLoading={isContactLoading}
   actions={[
    {
     label: 'Delete',
     onClick: ids => {
      setDeleteDialog({
       open: true,
       title: 'Delete Campaigns',
       onClose: CloseDeleteDialog,
       type: 'deleteCampaign',
       content: `Are you sure you want to delete ${ids.length} campaigns(s)?`,
       actions: [
        {
         label: 'Cancel',
         onClick: CloseDeleteDialog,
         classes: 'btn-outline dark-border'
        },
        {
         label: 'Delete',
         onClick: () => deleteCampaign(ids),
         classes: 'btn-primary'
        }
       ]
      });
     }
    }
   ]}
  />
 ];
 const emptyState = !isCampaignLoading && campaign.length === 0;

 return (
  <>
   <CustomDialog
    {...deleteDialog}
    content={deleteDialog.type === 'deleteCampaign' ? deleteDialog.content : ''}
   />{' '}
   <CustomDialog
    {...archiveDialog}
    content={
     archiveDialog.type === 'archiveCampaign' ? archiveDialog.content : ''
    }
   />
   {showBanner && (
    <>
     <Header
      title="Email Campaign"
      actions={[
       {
        label: 'Create email campaign',
        isPrimary: true,
        icon: iconAddCircleWhite,
        onClick: () => {
         setStartCampaign(true);
         setShowBanner(false);
        }
       }
      ]}
     />
     {emptyState && (
      <EmptyState
       title="Create Email Campaign"
       description="Engage with your customers, and boost conversions"
       options={[
        {
         title: 'Build Your Campaign',
         description:
          'Create an email campaign that engages your audience and achieves your goals.',
         action: {
          label: 'Create Email Campaign',
          icon: iconAddUser,
          onClick: () => {
           setShowBanner(false);
           setStartCampaign(true);
          }
         },
         banner: importBanner,
         bannerBackground: 'yellow'
        },
        {
         title: 'Explore Prebuilt Email Templates',
         description:
          'Access a Library of Ready-Made Designs to Enhance Your Email Campaigns',
         action: {
          label: 'Explore Templates',
          icon: iconPreview
          //onClick: onContactFormOpen
         },
         banner: createBanner,
         bannerBackground: 'green'
        }
       ]}
      />
     )}
     {!emptyState && <DynamicTabs tabs={tabs} panels={panels} />}
     {/* <DateTimePickerComponent /> */}
     <button
      onClick={() => {
       navigate('/campaigns/charts');
      }}
     >
      Open charts
     </button>
    </>
   )}
   {/* <EmptyEmailTemplate
    sidebar={[
     {
      label: 'Adflipr template',
      onclick: () => {}
     },
     {
      label: 'Saved template',
      onclick: () => {}
     }
    ]}
    body={[]}
   /> */}
   {startCampaign && (
    <>
     <Header
      title={
       <Stack direction="row" gap={2} alignItems="center">
        <img
         src={iconBack}
         onClick={() => {
          setShowBanner(true);
          setStartCampaign(false);
         }}
         alt="iconBack"
        />

        <Typography sx={{fontWeight: '600'}}>Create Email Campaign</Typography>
       </Stack>
      }
      actions={[]}
     />

     <div className="campaign-card">
      <div className="card-header">Start Email Campaign</div>
      <div className="description">
       Keep your subscribers interested by sharing new updates, highlighting top
       products, and telling them about upcoming events.
      </div>
      <form onSubmit={handleSubmit(onCampaignCreate)}>
       <div className="input-container">
        <div className="email-dialog-label">Campaign name</div>
        <input
         type="text"
         placeholder="Welcome email to new users"
         className="email-dialog-input"
         {...register('campaignName', {
          required: 'Campaign name is required'
         })}
        ></input>
       </div>
       <div className="buttons">
        <button className="btn btn-primary" type="submit">
         Create
        </button>
        <button
         className="btn btn-outline dark-border"
         onClick={() => {
          setShowBanner(true);
          setStartCampaign(false);
         }}
        >
         Cancel
        </button>
       </div>
      </form>
     </div>
    </>
   )}
  </>
 );
};

export default Campaigns;
