// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormRenderer_inputContainer__YWnEC {
  margin-bottom: 16px !important;
}
.FormRenderer_inputContainer__YWnEC:last-child {
  margin-bottom: 0 !important;
}
.FormRenderer_inputContainer__YWnEC .FormRenderer_checkbox__ysZ6N {
  margin: 0 8px 0 0;
  width: 20px;
  height: 20px;
}
.FormRenderer_inputContainer__YWnEC .FormRenderer_radioButton__17Hyp {
  width: 20px;
  height: 20px;
}
.FormRenderer_inputContainer__YWnEC .FormRenderer_label__\\+A2oq {
  margin-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/FormRenderer/FormRenderer.module.scss"],"names":[],"mappings":"AAAA;EACC,8BAAA;AACD;AAAC;EACC,2BAAA;AAEF;AACC;EACC,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AACC;EACC,WAAA;EACA,YAAA;AACF;AAEC;EACC,2BAAA;AAAF","sourcesContent":[".inputContainer {\n margin-bottom: 16px !important;\n &:last-child {\n  margin-bottom: 0 !important;\n }\n\n .checkbox {\n  margin: 0 8px 0 0;\n  width: 20px;\n  height: 20px;\n }\n .radioButton {\n  width: 20px;\n  height: 20px;\n }\n\n .label {\n  margin-bottom: 0 !important;\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `FormRenderer_inputContainer__YWnEC`,
	"checkbox": `FormRenderer_checkbox__ysZ6N`,
	"radioButton": `FormRenderer_radioButton__17Hyp`,
	"label": `FormRenderer_label__+A2oq`
};
export default ___CSS_LOADER_EXPORT___;
