import { useState, useEffect, useCallback } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { debounce } from '@mui/material/utils';
import { apiRequest } from '../../api/http'

const CustomAutocomplete = ({
  label,
  placeholder,
  onChange = () => {},
  fetchUrl,
  createUrl,
  classes = {}, 
  autoCompleteValue = []
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const handleAutoCompleteValueChange = (value) => {
    onChange(value)
  }

  const fetch = useCallback(debounce((q) => {
    apiRequest('GET', `${fetchUrl}?q=${q}`).then(({ data }) => {
      setOptions(q ? (data || []) : [])
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, 300), [])

  const create = async (valueToCreate) => {
    setLoading(true)
    setOptions([])
    const { data: newValue } = await apiRequest('POST', createUrl, { name: valueToCreate })
    fetch(inputValue)
    handleAutoCompleteValueChange([newValue, ...autoCompleteValue])
  } 

  useEffect(() => {
    setLoading(true)
    setOptions([])
    fetch(inputValue)
  }, [fetchUrl, inputValue])

  return <div className={classNames('input-container', classes.container)}>
    <Autocomplete
      multiple
      size='small'
      inputValue={inputValue}
      value={autoCompleteValue}
      loading={loading}
      clearOnBlur={false}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={
        (options) => {
          const isExisting = options.some((option) => inputValue === option.name);
          if (!isExisting && inputValue !== '' && !loading) {
            options.push({
              value: inputValue,
              name: `Add "${inputValue}"`,
            });
          }
          return options
        }
      }
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.name
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newInputValue)
        }
      }}
      onChange={(event, newValue, reason) => {
        if (reason === 'selectOption') {
          const newAddedOption = newValue.find((value) => !value.id)
          if (newAddedOption) {
            create(newAddedOption.value)
          } else {
            handleAutoCompleteValueChange(newValue)
          }
        } else {
          handleAutoCompleteValueChange(newValue)
        }
      }}
      options={options}
      renderInput={(params) => {
        return <>
          <label className="email-dialog-label">
            {label}
          </label>
          <TextField
            {...params} 
            placeholder={placeholder}
          />  
        </>
      }}
    />
  </div>
}

export default CustomAutocomplete