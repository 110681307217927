import React, {useEffect, useState} from 'react';
import '../../style/components/components.scss';
import {useNavigate, useLocation} from 'react-router-dom';

//Icons
import UndoButton from '../../../../assets/icons/icon-undo.svg';
import RedoButton from '../../../../assets/icons/icon-redo.svg';
import BackIcon from '../../../../assets/icons/icon-arrow.svg';
import ClockIcon from '../../../../assets/icons/icon-time.svg';
import LayerIcon from '../../../../assets/icons/icon-layer.svg';
import DesktopIcon from '../../../../assets/icons/icon-desktop.svg';
import DesktopIconWhite from '../../../../assets/icons/icon-desktop-white.svg';
import MobileIcon from '../../../../assets/icons/icon-Mobile.svg';
import MobileIconWhite from '../../../../assets/icons/icon-Mobile-white.svg';
import htmlIcon from '../../../../assets/icons/icon-code.svg';

//Redux
import {
 setCurrentTemplateId,
 setSelectedTemplates,
 setTemplateSaved,
 toggleDevice,
 toggleLayer,
 togglePreview,
 toggleSaveTemplateDialog,
 toggleSaveTemplateLoader
} from '../../../../store/store.js';
import {useDispatch, useSelector} from 'react-redux';

//Components
import Toggler from '../Toggler/Toggler';
import {Button, Tooltip, CircularProgress} from '@mui/material';
import PreviewModal from '../PreviewOverlay/PreviewModal.js';
import {template} from '../../../../api/templateapi.js';

const EmailEditorHeader = ({editor, setTemplateLayout, templateLoading}) => {
 const deviceState = useSelector(state => state.onMobile);
 const isPreview = useSelector(state => state.isPreview);
 const isLayer = useSelector(state => state.isLayer);
 const templateId = useSelector(state => state.currentTemplateId);
 const templateName = useSelector(state => state.currentTemplateName);
 const isSaveTemplateLoader = useSelector(state => state.isSaveTemplateLoader);
 const isTemplateSaved = useSelector(state => state.isTemplateSaved);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 // Add state to hold the email HTML content
 const [emailContent, setEmailContent] = useState('');

 const switchDevice = () => {
  // Dispatch action to toggle device state
  const newDevice = deviceState ? 'Desktop' : 'Mobile';
  if (editor) {
   editor.setDevice(newDevice);
  }
  // Dispatch action to toggle device state in Redux
  dispatch(toggleDevice());
 };

 //Show HTML Code
 // const showHTML = () => {
 //   if (editor) {
 //     editor.runCommand("export-template");
 //   }
 // };

 //Preview handler
 const handlePreview = () => {
  if (editor) {
   // Get the HTML from the GrapesJS editor
   const htmlContent = editor.runCommand('gjs-get-inlined-html');
   const cssStyles = `
      @media only screen and (max-width:768px){
        .container{
          float: left !important;
          width: 100% !important;
        }
      }
      `;

   // Construct the complete HTML document
   const fullHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Preview</title>
      <style>${cssStyles}</style>
    </head>
    <body>
      ${htmlContent}
    </body>
    </html>
  `;
   setEmailContent(fullHtml);
   dispatch(togglePreview());
  }
 };

 const updateTemplate = async () => {
  console.log(templateId, 'curr temp id');
  try {
   dispatch(toggleSaveTemplateLoader());
   // Get the inlined HTML
   const htmlContent = editor.runCommand('gjs-get-inlined-html');

   const response = await template.updateTemplate(templateId, {
    htmlContent: htmlContent
   });

      dispatch(setTemplateSaved(true));
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(toggleSaveTemplateLoader());
    }
  };

 //Layer handler
 const handleLayer = () => {
  console.log(isLayer);
  dispatch(toggleLayer(true));
 };

 // Undo action
 const handleUndo = () => {
  if (editor) {
   editor.UndoManager.undo();
  }
 };

 // Redo action
 const handleRedo = () => {
  if (editor) {
   editor.UndoManager.redo();
  }
 };
 useEffect(() => {
  console.log(location.state?.from);
 }, []);

 const openSaveDialog = () => {
  if (isTemplateSaved) {
   dispatch(setSelectedTemplates([]));
   dispatch(setCurrentTemplateId(''));
   if (location.state && location.state.from) {
    navigate(location.state.from);
   } else {
    navigate('/templates/my-templates');
   }
  } else {
   dispatch(toggleSaveTemplateDialog());
  }
 };

 const openTemplates = () => {
  setTemplateLayout(true);
 };

 return (
  <div
   className="header email-builder-header"
   style={{visibility: templateLoading ? 'hidden' : 'visible'}}
  >
   <div className="header-left">
    <Button
     variant="outline"
     className="back-icon common-btn"
     onClick={openSaveDialog}
    >
     <img src={BackIcon} alt="Back button" />
    </Button>
    <span className="template-name email-builder-template-name">
     {templateName}
    </span>

    <Button
     variant="outline"
     className="layer-icon common-btn"
     onClick={handleLayer}
    >
     <img src={LayerIcon} alt="Layer Button" />
    </Button>
    <Button
     variant="outline"
     className="btn btn-outline template-overlay-btn"
     onClick={openTemplates}
    >
     Templates
    </Button>
   </div>
   <div className="header-center">
    <Toggler
     isOn={deviceState}
     onToggle={switchDevice}
     icons={{
      on: (
       <img src={deviceState ? DesktopIconWhite : DesktopIcon} alt="Desktop" />
      ),
      off: (
       <img
        src={deviceState ? MobileIcon : MobileIconWhite}
        alt="Mobile"
        type={'device-toggler'}
       />
      )
     }}
    />
   </div>

   <div className="header-right">
    <span className="last-save">
     <span className="clock-icon">
      <img src={ClockIcon} alt="clock-icon" />
     </span>
     Last save 1hr ago
    </span>
    <div className="common-btn-container">
     <Tooltip title="Undo">
      <button className="common-btn" onClick={handleUndo}>
       <img src={UndoButton} alt="Undo button" />
      </button>
     </Tooltip>
     <Tooltip title="Redo">
      <button className="common-btn" onClick={handleRedo}>
       <img src={RedoButton} alt="Redo button" />
      </button>
     </Tooltip>
     {/* <Tooltip title="Code">
            <button className="common-btn" onClick={showHTML}>
              <img src={htmlIcon} alt="Show HTML Code Button" />
            </button>
          </Tooltip> */}
    </div>
    <div className="btn-container">
     <Button
      className="preview btn btn-outline dark-border"
      onClick={handlePreview}
     >
      Preview
     </Button>

     <Button
      className="btn-primary"
      variant="contained"
      onClick={updateTemplate}
     >
      {isSaveTemplateLoader ? <CircularProgress className="loader-btn" /> : ''}
      <span style={{visibility: isSaveTemplateLoader ? 'hidden' : 'visible'}}>
       Save
      </span>
     </Button>
    </div>
   </div>
   <PreviewModal isOpen={isPreview} content={emailContent} />
  </div>
 );
};

export default EmailEditorHeader;
