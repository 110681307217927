import React from 'react';
import TextEditor from "../TraitFunctions/TextEditor";

const FooterTraitManager = ({ htmlContent, updateHtmlContent, footerTraits, setFooterTraits }) => {
  const handleFooterChange = (newFooterHtml) => {
    // Parse the existing HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find and update the footer block
    const footerBlock = doc.querySelector('.footer-block');
    if (footerBlock) {
      footerBlock.innerHTML = newFooterHtml;
    }

    setFooterTraits({
      content: newFooterHtml
    })
    // Update the HTML content with the new footer
    updateHtmlContent(doc.documentElement.outerHTML);
  };

  return (
    <div>
      <TextEditor onChange={handleFooterChange} footerTraits={footerTraits} />
    </div>
  );
};

export default FooterTraitManager;
