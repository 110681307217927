import React, {useEffect, useRef, useState} from 'react';
import Sortable from 'sortablejs';
import CustomizedSwitches from './Switch';
import IconList from '../../../../assets/icons/icon-order-listing.svg';
import './component.css';

const SortableList = () => {
 const [items, setItems] = useState([
  {id: 0, label: 'Name'},
  {id: 1, label: 'Email'},
  {id: 2, label: 'Phone'},
  {id: 3, label: 'Created On'},
  {id: 4, label: 'Tags'},
  {id: 5, label: 'Status'},
  {id: 6, label: 'Date of Birth'},
  {id: 7, label: 'Timezone'},
  {id: 8, label: 'Address'},
  {id: 9, label: 'Company'}
 ]);
 const listRef = useRef(null);

 useEffect(() => {
  const sortable = Sortable.create(listRef.current, {
   animation: 150,
   handle: '.handle',
   onEnd: event => {
    // Handle sorting
    const newItems = [...items];
    const [removed] = newItems.splice(event.oldIndex, 1);
    newItems.splice(event.newIndex, 0, removed);
    setItems(newItems);
   }
  });

  // Clean up Sortable instance on component unmount
  return () => {
   sortable.destroy();
  };
 }, [items]);

 return (
  <ul ref={listRef}>
   {' '}
   {items.map(item => (
    <li key={item.id} className="listItemContainer">
     <span className="handle SortableItem">
      <img src={IconList} alt="icon" />
      <span> {item.label} </span>{' '}
     </span>{' '}
     <span>
      <CustomizedSwitches />
     </span>{' '}
    </li>
   ))}{' '}
  </ul>
 );
};

export default SortableList;
