import React, { useEffect, useRef } from "react";
import { Button, DialogContent } from "@mui/material";
import PropTypes from "prop-types";

const cardStyle = {
  display: "flex",
  padding: "0px",
  width: "100%",
  height: "auto",
  overflow: "hidden",
  maxHeight: "236px",
  position: "relative",
};

const Card = ({ card, openPreview }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const onLoad = () => {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const contentWidth = iframeDoc.documentElement.scrollWidth;
      const contentHeight = iframeDoc.documentElement.scrollHeight;
      const scale = Math.min(
        iframe.clientWidth / contentWidth,
        iframe.clientHeight / contentHeight
      );

      iframe.style.transform = `scale(${scale})`;
      iframe.style.transformOrigin = "0 0";
      iframe.style.width = `${contentWidth}px`;
      iframe.style.height = `${contentHeight}px`;
    };

    iframe.addEventListener("load", onLoad);
    return () => {
      iframe.removeEventListener("load", onLoad);
    };
  }, [card.content]);

  return (
    <div className="email-template-card">
      <DialogContent style={cardStyle}>
        <iframe
          ref={iframeRef}
          srcDoc={card.content}
          frameBorder="0"
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            border: "none",
            position: "absolute",
          }}
        />
      </DialogContent>
      {card.name && <div className="template-name">{card.name}</div>}
      {card.showPreviewButton && (
        <div className="template-hover-btn">
          <Button className="btn btn-primary" onClick={() => openPreview(card)}>
            Preview
          </Button>
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string.isRequired,
    showPreviewButton: PropTypes.bool,
  }).isRequired,
  openPreview: PropTypes.func.isRequired,
};

export default Card;
