import React, { useState } from "react";
import iconUpload from "../../../../../../assets/icons/icon-upload.svg";
import iconEdit from "../../../../../../assets/icons/icon-edit.svg"; // Assuming you have an edit icon
import { useSelector } from "react-redux";

const AssetManagerTrait = ({ htmlContent, updateHtmlContent, brandLogo, setBrandLogo}) => {
  const grapesjsEditor = useSelector((state) => state.editor);
  const [imageSrc, setImageSrc] = useState(brandLogo || "");
  const [imageName, setImageName] = useState("");

  const openAssetManager = () => {
    const doc = parseHtml(htmlContent);
    const imageBlockElement = doc.querySelector(".image-block");
    grapesjsEditor.runCommand("open-assets", {
      target: imageBlockElement,
      onSelect: (asset) => {
        const src = asset.get("src");
        const alt = asset.get("name") || "Image";

        if (imageBlockElement) {
          imageBlockElement.setAttribute("src", src);
          imageBlockElement.setAttribute("alt", alt);
          updateHtmlContent(serializeHtml(doc));
          
          // Update component state
          setBrandLogo(src);
          setImageSrc(src);
          setImageName(alt);
        }

        grapesjsEditor.Modal.close();
        return false;
      },
    });
  };

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="asset-manager-wrapper global-trait" onClick={openAssetManager}>
      {imageSrc ? (
        <>
          <div className="open-asset-manager filled">
            <img src={imageSrc} className="uploaded-image" alt={imageName} width="100%"  />
            
          </div>
          <div className="image-info">
            <span className="image-name">{imageName}</span>
            <button className="edit-button-container" onClick={openAssetManager}>
              <img src={iconEdit} className="edit-icon" alt="Edit" />
              <span className="edit-image-btn">Edit</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="open-asset-manager empty">
            <img src={iconUpload} className="drop-icon" alt="Upload Icon" />
            <span className="image-upload-label">Upload Image</span>
          </div>
          <span className="image-upload-info-text">
            Allow only PNG, JPG, JPEG, and GIF only. Max file size 3 MB
          </span>
        </>
      )}
    </div>
  );
};

export default AssetManagerTrait;
