import {Routes, Route} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';
// import Dashboard from "./dashboard/Dashboard.js";
import Dashboard from '../pages/dashboard/Dashboard.js';
import AllContacts from '../pages/contacts/AllContacts/AllContacts.js';
import ContactDetails from '../pages/contacts/AllContacts/ContactDetails';
import Segments from '../pages/contacts/Segments/Segments.js';
import Tags from '../pages/contacts/Tags/Tags.js';
import List from '../pages/contacts/List/List.js';
import Fields from '../pages/contacts/Fields/Fields.js';
import Automations from '../pages/automations/Automation.js';
import AutomationFlow from '../pages/automations/AutomationFlow.js';
import Analytics from '../pages/analytics/Analytics.js';
import MyTemplates from '../pages/templates/MyTemplate/MyTemplates.js';
import PrebuiltTemplates from '../pages/templates/PrebuiltTemplates/PrebuiltTemplates.js';
import AbandonedCart from '../pages/abandonedCart/AbandonedCart.js';
import Notifications from '../pages/notifications/Notifications.js';
import Documentation from '../pages/documentation/Documentation.js';
import Settings from '../pages/settings/Settings.js';
import Campaigns from '../pages/campaigns/Campaigns.jsx';
import CampaignCreate from '../pages/campaigns/CampaignCreate.jsx';
import Charts from '../pages/campaigns/Components/Charts.jsx';
import Profile from '../pages/Profile/Profile.jsx';
const MainContent = () => {
 const drawerOpen = useSelector(state => state.isSidebar);
 return (
  <>
   <div
    className="main-content"
    style={{
     width: drawerOpen ? 'calc(100% - 240px)' : 'calc(100% - 60px)'
    }}
   >
    <Routes>
     <Route path="/dashboard" element={<Dashboard />} />
     <Route path="/contacts/allContacts/:id" element={<ContactDetails />} />
     <Route path="/contacts/allContacts" element={<AllContacts />} />
     <Route path="/contacts/segments" element={<Segments />} />
     <Route path="/contacts/:type" element={<Tags />} />
     {/* <Route path="/contacts/list" element={<List />} /> */}
     <Route path="/contacts/fields" element={<Fields />} />
     <Route path="/automations" element={<Automations />} />
     <Route path="/automations/flow" element={<AutomationFlow />} />
     <Route path="/abandoned-cart" element={<AbandonedCart />} />
     <Route path="/analytics" element={<Analytics />} />
     <Route path="/campaigns" element={<Campaigns />} />
     <Route path="/campaigns/:id" element={<CampaignCreate />} />
     <Route path="campaigns/charts" element={<Charts />} />
     <Route path="/templates/my-templates" element={<MyTemplates />} />
     <Route
      path="/templates/prebuilt-templates"
      element={<PrebuiltTemplates />}
     />
     <Route path="/notifications" element={<Notifications />} />
     <Route path="/documentation" element={<Documentation />} />
     <Route path="/settings" element={<Settings />} />
     <Route path="/profile" element={<Profile />} />
     <Route path="*" element={<Dashboard />} />
    </Routes>
   </div>
  </>
 );
};

export default MainContent;
