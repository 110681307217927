import React, {useState} from 'react';
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table';
import {Typography} from '@mui/material';
import {Button} from '@mui/material';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import ResourceApi from '../../../api/resourceapi';
import {useEffect} from 'react';
import formatDate from '../../../utils/date-utils';
import iconBack from '../../../assets/icons/icon-back.svg';
import CustomDialog from '../../../components/Dialog/Dialog';
import {useForm} from 'react-hook-form';
import CustomMenu from '../../../components/Menu/Menu';
import {TextField} from '@mui/material';
import {IconButton} from '@mui/material';
import {ArrowDropDownIcon} from '@mui/x-date-pickers';
import iconArrow from '../../../assets/icons/icon-arrow.svg';
import iconDelete from '../../../assets/icons/icon-delete.svg';
const Fields = () => {
 const [fields, setFields] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const {register, handleSubmit, setValue} = useForm();
 const [dialogConfig, setDialogConfig] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });

 const [deleteDialog, setDeleteDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });

 const onCloseDelete = () => {
  setDeleteDialog(prevConfig => ({...prevConfig, open: false}));
 };
 const onCloseDialog = () => {
  setDialogConfig(prevConfig => ({...prevConfig, open: false}));
 };

 useEffect(() => {
  getFields();
 }, []);
 const getFields = () => {
  ResourceApi.users.getFields().then(({data}) => {
   console.log(data);
   setIsLoading(false);
   setFields(data.dynamicFields);
  });
 };

 const addField = data => {
  console.log(data);
  const apiData = {
   label: data.label,
   type: data.type,
   mergeTag: data.label
  };
  console.log(apiData);
  ResourceApi.contact.addField(apiData).then(({data}) => {
   console.log(data);
   getFields();
   onCloseDialog();
  });
 };

 const deleteFields = () => {
  const apiData = {
   label: 'd3'
  };
  ResourceApi.contact.deleteFields(apiData).then(({data}) => {
   console.log(data);
   onCloseDelete();
   getFields();
  });
 };

 const updateFields = () => {
  const apiData = {
   label: 'd4',
   type: 'TEXT_INPUT',
   mergeTag: 'd4'
  };
  ResourceApi.contact.updateFields(apiData).then(({data}) => {
   console.log(data);
   getFields();
  });
 };

 const onSubmission = data => {
  console.log(data);
  addField(data);
 };

 const FieldForm = () => {
  const [statusAnchorElement, setStatusAnchorElement] = useState(null);
  const [type, setType] = useState('TEXT_INPUT');
  const [isOption, setIsOption] = useState(false);
  const [optionNumber, setOptionNumber] = useState(0);

  useEffect(() => {
   //console.log(type);
   setValue('type', type);
   if (type === 'DROPDOWN') {
    setIsOption(true);
   } else if (type === 'RADIOBUTTON') {
    setIsOption(true);
   } else if (type === 'CHECKBOXES') {
    setIsOption(true);
   } else {
    setIsOption(false);
   }
  }, [type]);

  const sender = [
   {
    label: 'Text Input',
    value: 'TEXT_INPUT'
   },
   {
    label: 'Number',
    value: 'NUMBER'
   },
   {
    label: 'Text Area',
    value: 'TEXT_AREA'
   },
   {
    label: 'Drop Down',
    value: 'DROP_DOWN'
   },
   {
    label: 'Radio Button',
    value: 'RADIO_BUTTON'
   },
   {
    label: 'Check Boxes',
    value: 'CHECK_BOX'
   },
   {
    label: 'Date',
    value: 'DATE'
   }
  ];

  return (
   <form id="createField">
    <div className="input-container">
     <div className="email-dialog-label">Name</div>
     <input
      type="text"
      placeholder="Custom Field"
      className="email-dialog-input"
      {...register('label', {required: 'name is required'})}
     />
    </div>
    <div className="input-container">
     <div className="email-dialog-label">Type</div>
     <TextField
      variant="outlined"
      placeholder="Text"
      value={type}
      InputProps={{
       readOnly: true,
       endAdornment: (
        <img src={iconArrow} style={{width: '16px', height: '16px'}} />
       )
      }}
      onClick={event => {
       setStatusAnchorElement(event.currentTarget);
      }}
     />{' '}
     <input type="hidden" {...register('type')} value={type} />
     {/* <input
      type="text"
      value={type}
      className="email-dialog-input"
      onClick={event => {
       setStatusAnchorElement(event.currentTarget);
      }}
     /> */}
    </div>

    {optionNumber > 0 && (
     <>
      <div className="input-container">
       <div className="email-dialog-label">Options</div>
       <div className="options">
        {Array.from({length: optionNumber}).map((_, index) => (
         //   <p key={index}>This is element {index + 1}</p>
         <div className="input-options" key={index}>
          <input
           type="text"
           placeholder="Add Option Name"
           {...register('option')}
           className="email-dialog-input"
          ></input>
          <img
           src={iconDelete}
           alt="delete"
           style={{width: '24px', height: '24px'}}
          />
         </div>
        ))}
       </div>
      </div>
     </>
    )}
    {isOption && (
     <button
      className="btn btn-outline dark-border"
      onClick={() => {
       setOptionNumber(optionNumber + 1);
      }}
     >
      Add Option
     </button>
    )}

    <CustomMenu
     onClose={() => {
      setStatusAnchorElement(null);
     }}
     onSelect={value => {
      setStatusAnchorElement(null);
      setType(value);
     }}
     options={sender}
     anchorEl={statusAnchorElement}
     inputBox={true}
    />
   </form>
  );
 };

 const title = `Fields (${fields?.length})`;

 return (
  <div>
   <CustomDialog
    {...dialogConfig}
    content={
     dialogConfig.type === 'createField' ? <FieldForm /> : dialogConfig.content
    }
   />
   <CustomDialog
    {...deleteDialog}
    content={deleteDialog.type === 'deleteFields' ? deleteDialog.content : ''}
   />
   <Header
    title={title}
    actions={[
     {
      label: 'Create Fields',
      isPrimary: true,
      icon: iconAddCircleWhite,
      onClick: () => {
       setDialogConfig({
        title: 'Create Field',
        open: true,
        onClose: onCloseDialog,
        type: 'createField',
        actions: [
         {
          label: 'Cancel',
          onClick: onCloseDialog,
          classes: 'btn-outline dark-border'
         },
         {
          label: 'Create',
          onClick: () => {},
          classes: 'btn-primary',
          render: (
           <Button
            className="btn btn-primary"
            id="createField"
            variant="contained"
            onClick={handleSubmit(onSubmission)}
           >
            Create
           </Button>
          )
         }
        ]
       });
      }
     }
    ]}
   />
   <div className="main-content-container">
    <Table
     headers={[
      {
       numeric: false,
       key: 'name',
       disablePadding: false,
       //onClick: row => navigate(`/contacts/tags/${row.id}`),
       label: 'Name',
       render: row => {
        return <Typography>{row.label}</Typography>;
       }
      },
      {
       numeric: false,
       key: 'Type',
       disablePadding: false,
       //onClick: row => navigate(`/contacts/tags/${row.id}`),
       label: 'Type',
       render: row => {
        return <Typography>{row.type}</Typography>;
       }
      },
      {
       numeric: false,
       key: 'created',
       disablePadding: false,
       label: (
        <span
         style={{display: 'inline-flex', alignItems: 'center', gap: '8px'}}
        >
         Created on{' '}
        </span>
       ),
       render: row => {
        return formatDate(row.created, true);
       }
      },
      {
       numeric: false,
       key: 'mergeTags',
       disablePadding: false,
       label: 'Merge Tags',
       render: row => {
        return <Typography>%{row.mergeTag}%</Typography>;
       }
      }
     ]}
     rows={fields}
     isLoading={isLoading}
     actions={[
      {
       label: 'Delete',
       onClick: ids => {
        setDeleteDialog({
         open: true,
         title: 'Delete Fields',
         type: 'deleteFields',
         onClose: onCloseDelete,
         content: `Are you sure you want to delete ${ids.length} fields(s)?`,
         actions: [
          {
           label: 'Cancel',
           onClick: onCloseDelete,
           classes: 'btn-outline dark-border'
          },
          {
           label: 'Delete',
           onClick: () => {
            deleteFields();
           },
           classes: 'btn-primary'
          }
         ]
        });
       }
      },
      {
       label: 'Edit',
       onClick: ids => {
        updateFields();
       }
      }
     ]}
    />
   </div>
  </div>
 );
};

export default Fields;
