import React, {useCallback, useEffect} from 'react';
import ReactFlow, {
 MiniMap,
 Controls,
 Background,
 useNodesState,
 useEdgesState,
 addEdge,
 applyNodeChanges,
 applyEdgeChanges
} from 'reactflow';

import 'reactflow/dist/style.css';
import CustomCurvedEdge from './components/CurvedEdge';
import Node from './components/Node';
import CustomEdge from './components/CustomEdge';
import End from './components/End';
const nodeTypes = {
 textUpdater: Node,
 endNode: End
};

const edgeTypes = {
 'custom-edge': CustomEdge,
 curvedEdge: CustomCurvedEdge
};

const initialNodes = [
 {
  id: '1',
  type: 'textUpdater',
  data: {
   id: '1',
   header: 'Tag is added',
   type: 'event',
   headerIcon: 'tag',
   headerDescription: 'Has included',
   bodyContent: [
    {label: 'Active', data: 20},
    {label: 'Active', data: 20},
    {label: 'Active', data: 20}
   ]
  },
  // draggable: false,
  position: {x: 250, y: 5}
 },
 {
  id: '1.5',
  type: 'endNode',
  data: {label: 'Do This'},
  position: {x: 332, y: 200}
  //draggable: false
 }
 //  {
 //   id: '2',
 //   type: 'textUpdater',
 //    data: {
 //     header: 'Tag is added',
 //     headerIcon: 'tag',
 //     headerDescription: 'Has included',
 //     bodyContent: [
 //      {label: 'Active', data: 20},
 //      {label: 'Active', data: 20},
 //      {label: 'Active', data: 20}
 //     ]
 //    },
 //   draggable: false,
 //   position: {x: 250, y: 233}
 //  },
 //   {
 //    id: '2.5',
 //    type: 'endNode',
 //    data: {label: 'Do This'},
 //    position: {x: 332, y: 475},
 //    draggable: false
 //   },
 //  {
 //   id: '3',
 //   type: 'textUpdater',
 //   data: {
 //    header: 'Tag is added',
 //    headerIcon: 'tag',
 //    headerDescription: 'Has included',
 //    bodyContent: [
 //     {label: 'Active', data: 20},
 //     {label: 'Active', data: 20},
 //     {label: 'Active', data: 20}
 //    ]
 //   },
 //   draggable: false,
 //   position: {x: 250, y: 503}
 //  },
 //  {
 //   id: '3.5',
 //   type: 'endNode',
 //   data: {label: 'Do This'},
 //   position: {x: 332, y: 703},
 //   draggable: false
 //  },

 //  {
 //   id: '4',
 //   type: 'textUpdater',
 //   data: {
 //    addStep: true,
 //    header: 'Tag is added',
 //    headerIcon: 'tag',
 //    headerDescription: 'Has included',
 //    bodyContent: [{label: 'Active', data: 20}]
 //   },
 //   draggable: false,
 //   position: {x: 180, y: 730}
 //  }
];

const initialEdges = [
 {
  id: '1-1.5',
  type: 'custom-edge',
  source: '1',
  target: '1.5',
  sourceHandle: 'bottom-handle',
  targetHandle: 'top-handle'
 }
 //  {
 //   id: '2-2.5',
 //   type: 'custom-edge',
 //   source: '2',
 //   target: '2.5',
 //   sourceHandle: 'bottom-handle',
 //   targetHandle: 'top-handle'
 //  },
 //  {
 //   id: '3-3.5',
 //   type: 'custom-edge',
 //   source: '3',
 //   target: '3.5',
 //   sourceHandle: 'bottom-handle',
 //   targetHandle: 'top-handle'
 //  }
 //  {
 //   id: '2-3',
 //   type: 'curvedEdge',
 //   source: '2',
 //   target: '3',
 //   sourceHandle: 'bottom-handle',
 //   targetHandle: 'top-handle'
 //  }
 //  {
 //   id: 'e1-2',
 //   source: '1',
 //   target: '2',
 //   type: 'curvedEdge',
 //   data: {label: 'Yes/No'}
 //  }
];

export default function App() {
 const [nodes, setNodes] = useNodesState(initialNodes);
 const [edges, setEdges] = useEdgesState(initialEdges);
 useEffect(() => {
  console.log(nodes);
  // updateNodePositions();
 }, [nodes]);
 // const onConnect = useCallback(
 //   (params) => setEdges((eds) => addEdge(params, eds)),
 //   [setEdges],
 // );
 useEffect(() => {
  console.log(edges);
 }, [edges]);
 const onNodesChange = useCallback(
  changes => setNodes(nds => applyNodeChanges(changes, nds)),
  [setNodes]
 );
 const onEdgesChange = useCallback(
  changes => setEdges(eds => applyEdgeChanges(changes, eds)),
  [setEdges]
 );

 const updateNodePositions = () => {
  setNodes(nds => {
   return nds.map((node, index) => {
    const newPosition = {
     x: (index % 5) * 200, // Spread nodes horizontally with 200 units apart
     y: Math.floor(index / 5) * 200 // Spread nodes vertically with 200 units apart
    };
    return {
     ...node,
     position: newPosition
    };
   });
  });
 };

 return (
  <div style={{width: '100vw', height: '100vh'}}>
   <ReactFlow
    nodes={nodes}
    edges={edges}
    onNodesChange={onNodesChange}
    onEdgesChange={onEdgesChange}
    //onConnect={onConnect}
    nodeTypes={nodeTypes}
    edgeTypes={edgeTypes}
    minZoom={0.25} // Prevents zooming out too much
    maxZoom={1.5} // Prevents zooming in too much
    zoomOnScroll={false} // Disable zooming on scroll
    zoomOnDoubleClick={false} // Disable zooming on double click
    defaultViewport={{x: 350, y: 25, zoom: 1}} // Start the view at the top-left corner
   />
   {/* <Controls /> */}
   {/* <MiniMap /> */}
   {/* <Background variant="dots" gap={12} size={1} /> */}
  </div>
 );
}
