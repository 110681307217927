import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';

const PieChart = ({data, width = 400, height = 400}) => {
 const ref = useRef();

 useEffect(() => {
  // Remove any existing SVG to avoid duplicates
  d3.select(ref.current).select('svg').remove();

  const svg = d3
   .select(ref.current)
   .append('svg')
   .attr('width', width + 150) // Add extra width for legend
   .attr('height', height)
   .append('g')
   .attr('transform', `translate(${width / 2}, ${height / 2})`);

  const radius = Math.min(width, height) / 2;

  const pie = d3.pie().value(d => d.value);

  const arc = d3
   .arc()
   .innerRadius(radius / 2) // Adjust inner radius for donut chart
   .outerRadius(radius);

  const color = d3
   .scaleOrdinal()
   .domain(data.map(d => d.label))
   .range(['#636E72', '#00B894', '#E17055', '#FDCB6E']);

  const arcs = svg
   .selectAll('arc')
   .data(pie(data))
   .enter()
   .append('g')
   .attr('class', 'arc');

  arcs
   .append('path')
   .attr('d', arc)
   .attr('fill', d => color(d.data.label));

  // Add legend
  const legend = svg
   .append('g')
   .attr('transform', `translate(${radius + 20}, -${radius})`);

  legend
   .selectAll('rect')
   .data(data)
   .enter()
   .append('rect')
   .attr('x', radius + 10)
   .attr('y', (d, i) => i * 20)
   .attr('width', 10)
   .attr('height', 10)
   .attr('fill', d => color(d.label));

  legend
   .selectAll('text')
   .data(data)
   .enter()
   .append('text')
   .attr('x', radius + 30)
   .attr('y', (d, i) => i * 20 + 9)
   .text(d => d.label)
   .attr('alignment-baseline', 'middle')
   .style('font-size', '12px');
 }, [data, height, width]);

 return <div ref={ref}></div>;
};

export default PieChart;
