import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Box,
  Typography,
  createTheme,
  Link,
  Grid,
  CssBaseline,
  Container,
} from '@mui/material';
import { auth } from '../api/authapi';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated } from '../store/store';
import appIcon from '../assets/icons/app-icon.svg';
import { ThemeProvider } from '@emotion/react';
import { loginSchema, resetSchema } from '../api/validation';
import * as zod from 'zod';

const defaultTheme = createTheme();

const Login = ({ setAuth }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [isVerified, setIsVerified] = useState(true);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const isAuthenticated = useSelector((state) => state.isAuthenticated);

  function checkLoginStatus() {
    const loginToken = localStorage.getItem('loginToken');
    const tokenExpiry = localStorage.getItem('tokenExpiry');

    if (loginToken && tokenExpiry && Date.now() < tokenExpiry) {
      dispatch(setIsAuthenticated(true));
      navigate('/dashboard');
    }
  }

  const handleForgotPassword = () => {
    setIsVerified(false);
  };

  const handleCancel = () => {
    setIsVerified(true);
  };

  const handleResetPassword = async (event) => {
    let isValid = false;
    event.preventDefault();

    try {
      await resetSchema.parseAsync({ email });
      setErrors({});
      isValid = true;
    } catch (error) {
      if (error instanceof zod.ZodError) {
        const newErrors = {};
        error.issues.forEach((issue) => {
          newErrors[issue.path[0]] = issue.message;
        });
        setErrors(newErrors);
        return;
      } else {
        console.error('Unexpected error:', error);
        return;
      }
    }
    if (isValid) {
      try {
        const response = await auth.forgetPassword({ email });
        console.log(response.data, 'Reset Password api is called');
      } catch (err) {
        console.log(err);
        setError('Invalid credentials');
      }
      setIsReset(true);
    }
  };

  const openLogin = () => {
    setIsVerified(true);
    setIsReset(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await loginSchema.parseAsync({ email, password });
      setErrors({});
    } catch (error) {
      if (error instanceof zod.ZodError) {
        const newErrors = {};
        error.issues.forEach((issue) => {
          newErrors[issue.path[0]] = issue.message;
        });
        setErrors(newErrors);
        return;
      } else {
        console.error('Unexpected error:', error);
        return;
      }
    }

    try {
      const response = await auth.loginUser({
        email,
        password,
      });
      const { loginToken } = response.data;

      // Store loginToken in local storage
      localStorage.setItem('loginToken', loginToken);

      // Set loginToken as a cookie with the appropriate attributes
      const domain = '.adflipr.com'; // replace with your domain
      const secureFlag = window.location.protocol === 'https:' ? 'Secure;' : '';
      document.cookie = `loginToken=${loginToken}; domain=${domain}; path=/; ${secureFlag} SameSite=None;`;

      dispatch(setIsAuthenticated(true));
      navigate('/dashboard');
    } catch (err) {
      console.log(err);
      setError('Please enter valid login credentials');
    }
  };

  const openSignup = () => {
    navigate('/signup');
  };

  return (
    <>
      <div className="header auth">
        <div className="header-left">
          <img className="app-icon" alt="appIcon" src={appIcon} />
          <span className="template-name heading">adflipr</span>
        </div>
        <div className="header-center"></div>
        <div className="header-right">
          <Button
            className="btn btn-primary"
            variant="contained"
            onClick={openSignup}
          >
            Create Account
          </Button>
        </div>
      </div>
      {isVerified ? (
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" className="form" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              className="form-container"
            >
              <Typography
                className="form-heading"
                component="h1"
                variant="h5"
                gutterBottom
              >
                Login for <strong>adflipr</strong>
              </Typography>
              <Box
                component="form"
                className="form-component login-form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid className="form-grid" container spacing={2}>
                  <Grid className="form-grid-item" item xs={12}>
                    <div className="input-container">
                      {error && (
                        <Typography className="error-info">{error}</Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid className="form-grid-item" item xs={12}>
                    <div className="input-container">
                      <label className="email-dialog-label">Email Id</label>
                      <input
                        className={`email-dialog-input ${
                          errors.email ? 'box-error' : ''
                        }`}
                        placeholder="Type here..."
                        type="text"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError('');
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: '',
                          }));
                        }}
                      ></input>{' '}
                      {errors.email && (
                        <div className="error-msg">{errors.email}</div>
                      )}{' '}
                    </div>{' '}
                  </Grid>{' '}
                  <Grid className="form-grid-item" item xs={12}>
                    <div className="input-container">
                      <label className="email-dialog-label">Password</label>
                      <input
                        className={`email-dialog-input ${
                          errors.password ? 'box-error' : ''
                        }`}
                        placeholder="Type here..."
                        type="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError('');
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            password: '',
                          }));
                        }}
                      ></input>{' '}
                      {errors.password && (
                        <div className="error-msg">{errors.password}</div>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  className="btn btn-primary btn-signup"
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
              <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                className="form-info"
                sx={{ mt: 2 }}
              >
                <Link href="#" color="#ff385c" onClick={handleForgotPassword}>
                  Forgot Password?
                </Link>
              </Typography>
            </Box>
          </Container>
        </ThemeProvider>
      ) : (
        <>
          {isReset ? (
            <>
              <div className="verification-container">
                <h1 className="verification-heading">
                  Sent link to Reset password
                </h1>
                <p className="verification-content">
                  If an account exists for {email}, you will get an email with
                  instructions on resetting your password. If it doesn{' '}
                  {'t arrive, be sure to check your spam folder.'}
                </p>
                <a className="verification-link" onClick={openLogin}>
                  Back to Login
                </a>
              </div>
            </>
          ) : (
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" className="form" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className="form-container"
                >
                  <Typography
                    className="form-heading"
                    component="h1"
                    variant="h5"
                    gutterBottom
                  >
                    Enter email to reset password
                  </Typography>
                  <Box
                    component="form"
                    className="form-component forget-pass"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                  >
                    <Grid className="form-grid" container spacing={2}>
                      <Grid className="form-grid-item" item xs={12}>
                        <div className="input-container">
                          <label className="email-dialog-label">
                            Email Address
                          </label>
                          <input
                            className={`email-dialog-input ${
                              errors.email ? 'box-error' : ''
                            }`}
                            placeholder="Type here..."
                            type="text"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                email: '',
                              }));
                            }}
                          ></input>{' '}
                          {errors.email && (
                            <div className="error-msg">{errors.email}</div>
                          )}{' '}
                        </div>{' '}
                      </Grid>{' '}
                    </Grid>{' '}
                    <Button
                      type="submit"
                      fullWidth
                      className="btn btn-primary btn-signup"
                      variant="contained"
                      onClick={handleResetPassword}
                    >
                      Reset password
                    </Button>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                    className="form-info"
                    sx={{ mt: 2 }}
                  >
                    <Link href="#" color="#ff385c" onClick={handleCancel}>
                      Cancel
                    </Link>
                  </Typography>
                </Box>
              </Container>
            </ThemeProvider>
          )}
        </>
      )}
    </>
  );
};

export default Login;
