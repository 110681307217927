import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Icon from '../Icon';
import {useState, useEffect} from 'react';
const CustomMenu = ({
 anchorEl,
 onSelect,
 options = [],
 onClose,
 verticalAlighnment = 'bottom',
 horizontalAlignment = 'left',
 inputBox
}) => {
 const [menuStyles, setMenuStyles] = useState({});
 useEffect(() => {
  //   if (anchorEl && inputBox) {
  //    const {width} = anchorEl.getBoundingClientRect();
  //    setMenuStyles({width: `${width}px`});
  //   }
 }, [anchorEl]);

 return (
  <Menu
   anchorEl={anchorEl}
   open={!!anchorEl}
   onClose={onClose}
   anchorOrigin={{
    vertical: verticalAlighnment,
    horizontal: horizontalAlignment
   }}
   className="dropdown"
   PaperProps={{style: menuStyles}}
  >
   {options.map(({icon, label, value}) => {
    return (
     <MenuItem
      sx={{py: 1, px: 2, height: '32px'}}
      onClick={() => onSelect(value)}
     >
      {icon && <Icon type={icon} />}
      <ListItemText>{label}</ListItemText>
     </MenuItem>
    );
   })}
  </Menu>
 );
};

export default CustomMenu;
