import React from 'react';
import Header from '../../components/Header/Header';
import EmptyState from '../../components/EmptyState';
import iconAddCircleWhite from '../../assets/icons/icon-add-white.svg';
import createAuto from '../../assets/icons/undraw_preferences_re_49in 1.png';
import iconAddCircle from '../../assets/icons/icon-add-circle.svg';
import iconSettings from '../../assets/icons/icon-settings.svg';
import followingUser from '../../assets/icons/undraw_innovative_re_rr5i 1.png';
import CustomDialog from '../../components/Dialog/Dialog';
import {useState} from 'react';
import FormRenderer from '../../components/FormRenderer';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
const Automation = () => {
 const [showEmptyState, setShowEmptyState] = useState(true);
 const navigate = useNavigate();
 const {
  register,
  handleSubmit,
  watch,
  setValue,
  setError,
  formState: {errors},
  reset
 } = useForm({mode: 'onBlur'});
 const [createDialog, setCreateDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const onCloseDialog = () => {
  setCreateDialog(prevConfig => ({...prevConfig, open: false}));
 };

 const createAutomationDialog = () => {
  setCreateDialog({
   title: 'Create Automation',
   open: true,
   onClose: onCloseDialog,
   type: 'createAutomation',
   actions: [
    {
     label: 'Cancel',
     onClick: onCloseDialog,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Create',
     onClick: () => {
      handleSubmit(createAutomation)();
     },
     classes: 'btn-primary'
    }
   ]
  });
 };

 const createAutomation = data => {
  console.log(data);
  navigate('/automations/flow');
 };

 return (
  <>
   <CustomDialog
    {...createDialog}
    content={
     createDialog.type === 'createAutomation' ? (
      <FormRenderer
       register={register}
       watch={watch}
       errors={errors}
       fields={[
        {
         type: 'text',
         name: 'name',
         placeHolder: 'type here',
         label: 'Name',
         validations: {required: 'Name is required'}
        }
       ]}
      />
     ) : (
      createDialog.content
     )
    }
   />
   <Header
    title="Automations"
    actions={[
     {
      label: 'Create Automations',
      isPrimary: true,
      icon: iconAddCircleWhite,
      onClick: () => {
       createAutomationDialog();
      }
     }
    ]}
   />

   {showEmptyState && (
    <EmptyState
     title="Create Automation"
     description="Connect with customers via automated welcome emails, product promotions, greetings, and special offers."
     options={[
      {
       title: 'Setup your email deliverability',
       description:
        'Launch and personalize a site using our easy-to-learn website builder.',
       action: {
        label: 'Setup email',
        icon: iconSettings,
        onClick: () => {}
       },
       banner: createAuto,
       bannerBackground: 'yellow'
      },
      {
       title: 'Start designing email',
       description:
        'Launch and personalize a site using our easy-to-learn website builder.',
       action: {
        label: 'Create email',
        icon: iconAddCircle,
        onClick: () => {}
       },
       banner: followingUser,
       bannerBackground: 'green'
      }
     ]}
    />
   )}
  </>
 );
};

export default Automation;
