import Typography from '@mui/material/Typography';
import Icon from '../Icon';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Progress from '../Progress/Progess';
import {useState, useRef, useEffect} from 'react';
import iconMore from '../../assets/icons/icon-more.svg';
import iconEdit from '../../assets/icons/icon-edit.svg';
import iconDelete from '../../assets/icons/icon-delete.svg';
import classNames from 'classnames';
import {Menu} from '@mui/material';
import './Table.css';
const TableToolBar = ({selectedCount, actions = [], onClearAllClick}) => {
 return (
  selectedCount > 0 && (
   <Stack direction="row" spacing={1} sx={{mb: 2}} alignItems="center">
    <Typography variant="subtitle2" component={'span'}>
     {selectedCount} items selected in the list
    </Typography>
    <Button className={'btn'} sx={{mr: '12px'}} onClick={onClearAllClick}>
     <Icon type="cross" sx={{mr: '5px'}} />
     <span>Clear All</span>
    </Button>
    {actions.map(action => {
     if (action.label !== 'Edit') {
      const {label, onClick} = action;
      return (
       <Button className={'btn'} sx={{background: '#EBEBEB'}} onClick={onClick}>
        {label}
       </Button>
      );
     }
    })}
   </Stack>
  )
 );
};

const EnhancedTableHead = ({
 headers = [],
 onSelectAllClick,
 numSelected = 0,
 rowCount = 0
}) => {
 return (
  <TableHead>
   <TableRow>
    <EnhancedTableCell>
     <Checkbox
      color="primary"
      checked={rowCount > 0 && numSelected === rowCount}
      onChange={onSelectAllClick}
     />
    </EnhancedTableCell>
    {headers.map(header => (
     <EnhancedTableCell
      key={header.key}
      className={classNames('TableHeader', header.customClass)}
     >
      {header.label}
     </EnhancedTableCell>
    ))}
   </TableRow>
  </TableHead>
 );
};

const EnhancedTableCell = ({children, onClick, ...rest}) => {
 let tableCellProps = {
  ...rest,
  sx: {
   borderRight: 1,
   borderColor: ({palette}) => palette.grey[200],
   cursor: onClick ? 'pointer' : 'default'
  }
 };
 if (onClick) {
  tableCellProps = {...tableCellProps, onClick};
 }
 return <TableCell {...tableCellProps}>{children}</TableCell>;
};

const TableComp = ({
 headers = [],
 isLoading = false,
 rows = [],
 actions = []
}) => {
 const [selected, setSelected] = useState([]);
 const [rowSelected, setRowSelected] = useState([]);
 const [anchorE1, setAnchorE1] = useState(null);
 const onSelectAllClick = event => {
  if (event.target.checked) {
   const newSelected = rows.map(n => n.id);
   setSelected(newSelected);
   return;
  }

  setSelected([]);
 };

 const handleMoreClick = event => {
  setAnchorE1(event.currentTarget);
 };
 const handleMenuClose = () => {
  setAnchorE1(null);
 };

 const handleEditTag = () => {
  const action = actions.find(action => action.label === 'Edit');
  action.onClick(rowSelected);
 };
 const handleDeleteTag = () => {
  const action = actions.find(action => action.label === 'Delete');
  action.onClick([rowSelected]);
 };

 const onClearAllClick = () => {
  setSelected([]);
 };

 const onSelectRow = id => {
  let newSelected = [];
  const isSelected = selected.some(selectedId => selectedId === id);
  if (isSelected) {
   newSelected = selected.filter(selectedId => selectedId !== id);
  } else {
   newSelected = selected.concat(id);
  }
  setSelected(newSelected);
 };

 if (isLoading) {
  return <Progress />;
 }

 return (
  <>
   <Collapse orientation="vertical" in={selected.length > 0}>
    <TableToolBar
     selectedCount={selected.length}
     onClearAllClick={onClearAllClick}
     actions={actions.map(action => {
      return {
       ...action,
       onClick: () => {
        action.onClick(selected);
       }
      };
     })}
    />
   </Collapse>
   <Box
    sx={{
     borderRadius: 2,
     border: 1,
     borderColor: ({palette}) => palette.grey[200]
    }}
   >
    <TableContainer>
     <Table>
      <EnhancedTableHead
       headers={headers}
       onSelectAllClick={onSelectAllClick}
       numSelected={selected.length}
       rowCount={rows.length}
      />
      <TableBody>
       {rows.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
         <TableRow
          key={row.id}
          sx={{
           cursor: 'pointer',
           backgroundColor: () => (index % 2 === 0 ? '#F9FAFB' : '')
          }}
         >
          <EnhancedTableCell className="firstCol">
           <div style={{display: 'flex', alignItems: 'center'}}>
            <Checkbox
             color="primary"
             onClick={() => onSelectRow(row.id)}
             checked={selected.indexOf(row.id) !== -1}
            />{' '}
            <img
             src={iconMore}
             alt="icon-more"
             onClick={event => {
              handleMoreClick(event);
              setRowSelected(row.id);
             }}
            />
           </div>
          </EnhancedTableCell>
          {headers.map(({numeric, key, render, onClick}) => {
           const RenderComp = render;
           return (
            <EnhancedTableCell
             onClick={onClick ? () => onClick(row) : ''}
             align={numeric ? 'right' : 'left'}
             key={key}
            >
             {RenderComp ? <RenderComp {...row} /> : row[key] || '_'}
            </EnhancedTableCell>
           );
          })}
         </TableRow>
        );
       })}
      </TableBody>
     </Table>
    </TableContainer>

    <Menu
     anchorEl={anchorE1}
     open={Boolean(anchorE1)}
     onClose={handleMenuClose}
     anchorOrigin={{vertical: 'top', horizontal: 'right'}}
     transformOrigin={{vertical: 'top', horizontal: 'left'}}
    >
     <div className="Menu">
      <div
       className="Menu-Item"
       onClick={() => {
        setAnchorE1(null);
        handleEditTag();
       }}
      >
       <img src={iconEdit} alt="edit icon" />
       Edit
      </div>

      <div
       className="Menu-Item"
       onClick={() => {
        setAnchorE1(null);
        handleDeleteTag();
       }}
      >
       <img src={iconDelete} alt="delete icon" />
       Delete
      </div>
     </div>
    </Menu>
   </Box>
  </>
 );
};

export default TableComp;
