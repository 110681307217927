import React from "react";
import PrebuiltTemplateHeader from "./components/PrebuiltTemplateHeader";
import PrebuiltTemplateBody from "./components/PrebuiltTemplateBody";
import CreateEmailTemplateDialog from "./components/CreateEmailDialog";
import { useSelector } from "react-redux";

const PrebuiltTemplates = () => {
  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog
  );
  return (
    <>
      {isCreateTemplateDialog ? <CreateEmailTemplateDialog /> : ""}
      <PrebuiltTemplateHeader />
      <PrebuiltTemplateBody />
    </>
  );
};

export default PrebuiltTemplates;
