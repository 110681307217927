import {
 BaseEdge,
 EdgeLabelRenderer,
 getStraightPath,
 getSimpleBezierPath,
 useReactFlow
} from 'reactflow';
import iconAdd from '../../../assets/icons/icon-plus.svg';
import iconTag from '../../../assets/icons/icon-tag-automation.svg';
import iconClose from '../../../assets/icons/icon-cross.svg';
import iconEmail from '../../../assets/icons/icon-email.svg';
import iconTime from '../../../assets/icons/icon-time.svg';
import iconElse from '../../../assets/icons/icon-Condition.svg';
import iconSplit from '../../../assets/icons/icon-AB.svg';
import iconContact from '../../../assets/icons/icon-contact-status.svg';
import iconList from '../../../assets/icons/icon-order-listing.svg';
import iconSkip from '../../../assets/icons/icon-skip-step.svg';
import iconGoal from '../../../assets/icons/icon-target.svg';
import iconExit from '../../../assets/icons/icon-exit.svg';
import iconArrow from '../../../assets/icons/icon-arrow.svg';
import {useEffect, useState} from 'react';
import CustomDialog from '../../../components/Dialog/Dialog';
import {useForm} from 'react-hook-form';
import CustomMenu from '../../../components/Menu/Menu';
import {
 Checkbox,
 FormControlLabel,
 FormGroup,
 Radio,
 TextField
} from '@mui/material';
import RadioButtonsGroup from '../../../components/RadioGroup/Radio';
export default function CustomEdge({
 id,
 source,
 target,
 sourceX,
 sourceY,
 targetX,
 targetY
}) {
 const {setEdges, getNode, getNodes, getEdges} = useReactFlow();
 const {setNodes} = useReactFlow();
 const [isPopupVisible, setIsPopupVisible] = useState(false);
 const [edgePath, labelX, labelY] = getSimpleBezierPath({
  sourceX,
  sourceY,
  targetX,
  targetY
 });
 const sourceNode = getNode(source); // Get the source node
 const targetNode = getNode(target); // Get the target node
 console.log(sourceNode, targetNode, id);
 const {register, handleSubmit, setValue} = useForm();
 const [status, setStatus] = useState('');
 const [statusAnchorElement, setStatusAnchorElement] = useState(null);
 const [contactStatus, setContactStatus] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const [selectedValue, setSelectedValue] = useState('Add');
 const values = ['Add', 'Remove'];
 const [tagDialog, setTagDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const [emailDialog, setEmailDialog] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const closestatusDialog = () => {
  setContactStatus(prevConfig => ({...prevConfig, open: false}));
 };
 useEffect(() => {
  setValue('actionType', 'Add');
 }, []);
 const closeTagDialog = () => {
  setTagDialog(prevConfig => ({...prevConfig, open: false}));
 };
 const closeEmailDialog = () => {
  setEmailDialog(prevConfig => ({...prevConfig, open: false}));
 };
 const handleRadioChange = event => {
  setSelectedValue(event.target.value);
  setValue('actionType', event.target.value);
 };
 useEffect(() => {
  console.log(status);
 }, [setStatus]);
 const contactStatusOptions = [
  {
   label: 'Subscribed',
   value: 'Subscribed'
  },
  {
   label: 'Unsubscribed',
   value: 'Unsubscribed'
  },
  {
   label: 'Blocked',
   value: 'Blocked'
  },
  {
   label: 'Bounced',
   value: 'Bounced'
  }
 ];

 const addTagNode = Data => {
  console.log(Data);
  const data = {
   header: `Add/Remove ${Data.type}`,
   headerIcon: 'iconTag',
   headerDescription: `${Data.actionType} ${Data.type}`,
   status: Data.tag,
   bodyContent: [
    {label: 'Active', data: 20},
    {label: 'Completed', data: 20}
   ]
  };

  if (
   sourceNode.data &&
   'flag' in sourceNode.data &&
   'flag' in targetNode.data
  ) {
   handleClick3(data);
  } else {
   if (targetNode.type === 'endNode') {
    // Current node is the last node
    handleClick(data);
   } else {
    // Current node is not the last node
    handleClick2(data);
   }
  }
 };

 const addStatusNode = Data => {
  console.log(Data.status);
  const data = {
   header: 'Change Contact Status',
   headerIcon: 'iconContact',
   headerDescription: 'Status updated to',
   status: Data.status,
   bodyContent: [
    {label: 'Active', data: 20},
    {label: 'Completed', data: 20}
   ]
  };
  if (
   sourceNode.data &&
   'flag' in sourceNode.data &&
   'flag' in targetNode.data
  ) {
   handleClick3(data);
  } else {
   if (targetNode.type === 'endNode') {
    // Current node is the last node
    handleClick(data);
   } else {
    // Current node is not the last node
    handleClick2(data);
   }
  }
  setIsPopupVisible(!isPopupVisible);
 };

 const handleClick = data => {
  console.log('handleClick', id);
  let newNodePosition = {x: 250, y: 5};
  let Id = 1;
  Id = sourceNode.id;
  console.log(status);
  setNodes(nds => {
   let updatedNodes = [...nds];
   updatedNodes = updatedNodes.filter(node => node.id !== targetNode.id);
   if (sourceNode.data.flag === 'conditional') {
    newNodePosition = {
     x: sourceNode.position.x - 120, // Keep the same x position
     y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
    };
   } else if (sourceNode.data && 'flag' in sourceNode.data) {
    newNodePosition = {
     x: sourceNode.position.x - 120, // Keep the same x position
     y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
    };
   } else {
    newNodePosition = {
     x: sourceNode.position.x, // Keep the same x position
     y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
    };
   }
   let newNode;
   if (data) {
    console.log('data');
    newNode = {
     id: `${parseInt(Id) + 1}`,
     type: 'textUpdater',
     data: {
      header: data.header,
      headerIcon: data.headerIcon,
      headerDescription: data.headerDescription,
      status: data.status,
      bodyContent: data.bodyContent
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   } else {
    console.log('data not');
    newNode = {
     id: `${parseInt(Id) + 1}`,
     type: 'textUpdater',
     data: {
      header: 'Tag is added',
      headerIcon: 'tag',
      headerDescription: 'Has included',
      bodyContent: [
       {label: 'Active', data: 20},
       {label: 'Active', data: 20},
       {label: 'Active', data: 20}
      ]
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   }

   newNodePosition = {
    x: newNodePosition.x + 83,
    y: newNodePosition.y + 200
   };

   const newNode2 = {
    id: `${parseInt(Id) + 1 + 0.5}`,
    type: 'endNode',
    data: {label: 'Do This'},
    position: newNodePosition
    //draggable: false
   };
   return [...updatedNodes, newNode, newNode2];
  });

  const newEdge2 = {
   id: `${parseInt(Id)}-${parseInt(Id) + 1}`,
   type: 'custom-edge',
   source: Id,
   target: `${parseInt(Id) + 1}`,
   sourceHandle: 'bottom-handle',
   targetHandle: 'top-handle'
  };

  const newEdge = {
   id: `${parseInt(Id) + 1}-${parseInt(Id) + 1 + 0.5}`,
   type: 'custom-edge',
   source: `${parseInt(Id) + 1}`,
   target: `${parseInt(Id) + 1 + 0.5}`,
   sourceHandle: 'bottom-handle',
   targetHandle: 'top-handle'
  };
  setEdges(eds => {
   let updatedEdges = [...eds];
   updatedEdges = updatedEdges.filter(edge => edge.id !== id);
   return [...updatedEdges, newEdge2, newEdge];
  });
 };

 const handleClick3 = data => {
  console.log('handleClick3');
  let previousNode;
  let nextNode;

  setNodes(nds => {
   const updatedNodes = [...nds];
   const insertIndex = updatedNodes.findIndex(
    node => node.id === targetNode.id
   );

   // Get the previous and next nodes
   previousNode = sourceNode;
   nextNode = targetNode;
   console.log(previousNode.id, nextNode.id);

   // Insert the new node between previousNode and nextNode
   let newNodePosition;
   if (sourceNode.data && sourceNode.data.flag === true) {
    if (sourceNode.type === 'endNode') {
     newNodePosition = {
      x: sourceNode.position.x - 240, // Keep the same x position
      y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
     };
    } else {
     newNodePosition = {
      x: sourceNode.position.x - 60, // Keep the same x position
      y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
     };
    }
   } else {
    newNodePosition = {
     x: sourceNode.position.x + 60, // Keep the same x position
     y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
    };
   }

   //console.log(ID);
   let newNode;
   if (data) {
    console.log('data');
    newNode = {
     id: `${previousNode.id}.${nextNode.id}`,
     type: 'textUpdater',
     data: {
      header: data.header,
      headerIcon: data.headerIcon,
      headerDescription: data.headerDescription,
      status: data.status,
      bodyContent: data.bodyContent
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   } else {
    console.log('data not');
    newNode = {
     id: `${previousNode.id}.${nextNode.id}`,
     type: 'textUpdater',
     data: {
      header: 'Tag is added',
      headerIcon: 'tag',
      headerDescription: 'Has included',
      bodyContent: [
       {label: 'Active', data: 20},
       {label: 'Active', data: 20},
       {label: 'Active', data: 20}
      ]
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   }
   //    const newNode = {
   //     id: `${previousNode.id}.${nextNode.id}`, // Generate a unique ID
   //     type: 'textUpdater',
   //     data: {
   //      header: 'Tag is inserted',
   //      flag: previousNode.data.flag,
   //      headerIcon: 'tag',
   //      headerDescription: 'Has included',
   //      bodyContent: [
   //       {label: 'Active', data: 20},
   //       {label: 'Active', data: 20},
   //       {label: 'Active', data: 20}
   //      ]
   //     },
   //     //draggable: false,
   //     position: newNodePosition // Use dynamically calculated position
   //    };

   console.log(insertIndex, previousNode.id, nextNode.id);
   // Push all nodes below the current node downward
   for (let i = insertIndex; i < updatedNodes.length; i++) {
    updatedNodes[i].position = {
     x: updatedNodes[i].position.x,
     y: updatedNodes[i].position.y + 150 // Push each node downward
    };
   }
   updatedNodes.splice(insertIndex, 0, newNode); // Insert new node
   return updatedNodes;
  });

  setEdges(eds => {
   const updatedEdges = [...eds];
   //    const currentEdgeIndex = updatedEdges.findIndex(edge => edge === id);

   //    if (currentEdgeIndex !== -1) {
   //     // Remove the old edge connected to the current node
   //     updatedEdges.splice(currentEdgeIndex, 1);
   //    }

   const edgeBetweenPrevAndNext = updatedEdges.find(
    edge => edge.source === previousNode.id && edge.target === nextNode.id
   );
   if (edgeBetweenPrevAndNext) {
    const index = updatedEdges.indexOf(edgeBetweenPrevAndNext);
    if (index !== -1) {
     updatedEdges.splice(index, 1);
    }
   }

   const newEdge1 = {
    id: `${parseInt(previousNode.id)}-${previousNode.id}.${nextNode.id}`,
    type: 'custom-edge',
    source: previousNode.id,
    target: `${previousNode.id}.${nextNode.id}`,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   };

   const newEdge2 = {
    id: `${previousNode.id}.${nextNode.id}-${nextNode.id}`,
    type: 'custom-edge',
    source: `${previousNode.id}.${nextNode.id}`,
    target: nextNode.id,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   };

   return [...updatedEdges, newEdge1, newEdge2]; // Add new edges
  });
 };

 const handleClick2 = data => {
  console.log('handleClick2');
  let previousNode;
  let nextNode;
  console.log(id);
  setNodes(nds => {
   const updatedNodes = [...nds];

   // Get the previous and next nodes
   previousNode = sourceNode;
   nextNode = targetNode;
   console.log(previousNode.id, nextNode.id);

   // Remove the current node

   // Insert the new node between previousNode and nextNode
   let newNodePosition;
   if (sourceNode.data.flag === 'conditional') {
    newNodePosition = {
     x: sourceNode.position.x - 120, // Keep the same x position
     y: sourceNode.position.y + 100 // Position 100px below the last node (adjust as needed)
    };
   } else if (sourceNode.data && 'flag' in sourceNode.data) {
    newNodePosition = {
     x: sourceNode.position.x - 120, // Keep the same x position
     y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
    };
   } else {
    newNodePosition = {
     x: sourceNode.position.x, // Keep the same x position
     y: sourceNode.position.y + 200 // Position 100px below the last node (adjust as needed)
    };
   }

   //console.log(ID);
   let newNode;
   if (data) {
    console.log('data');
    newNode = {
     id: `${previousNode.id}.${nextNode.id}`,
     type: 'textUpdater',
     data: {
      header: data.header,
      headerIcon: data.headerIcon,
      headerDescription: data.headerDescription,
      status: data.status,
      bodyContent: data.bodyContent
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   } else {
    console.log('data not');
    newNode = {
     id: `${previousNode.id}.${nextNode.id}`,
     type: 'textUpdater',
     data: {
      header: 'Tag is added',
      headerIcon: 'tag',
      headerDescription: 'Has included',
      bodyContent: [
       {label: 'Active', data: 20},
       {label: 'Active', data: 20},
       {label: 'Active', data: 20}
      ]
     },
     //draggable: false,
     position: newNodePosition // Use dynamically calculated position
    };
   }
   //    const newNode = {
   //     id: `${previousNode.id}.${nextNode.id}`, // Generate a unique ID
   //     type: 'textUpdater',
   //     data: {
   //      header: 'Tag is inserted',
   //      headerIcon: 'tag',
   //      headerDescription: 'Has included',
   //      bodyContent: [
   //       {label: 'Active', data: 20},
   //       {label: 'Active', data: 20},
   //       {label: 'Active', data: 20}
   //      ]
   //     },
   //     //draggable: false,
   //     position: newNodePosition // Use dynamically calculated position
   //    };
   const insertIndex = updatedNodes.findIndex(node => node === targetNode);

   console.log(insertIndex, previousNode.id, nextNode.id);
   // Push all nodes below the current node downward
   for (let i = insertIndex; i < updatedNodes.length; i++) {
    updatedNodes[i].position = {
     x: updatedNodes[i].position.x,
     y: updatedNodes[i].position.y + 200 // Push each node downward
    };
   }
   updatedNodes.splice(insertIndex, 0, newNode); // Insert new node
   return updatedNodes;
  });

  setEdges(eds => {
   const updatedEdges = [...eds];
   const edgeBetweenPrevAndNext = updatedEdges.find(
    edge => edge.source === previousNode.id && edge.target === nextNode.id
   );
   if (edgeBetweenPrevAndNext) {
    const index = updatedEdges.indexOf(edgeBetweenPrevAndNext);
    if (index !== -1) {
     updatedEdges.splice(index, 1);
    }
   }

   const newEdge1 = {
    id: `${parseInt(previousNode.id)}-${previousNode.id}.${nextNode.id}`,
    type: 'custom-edge',
    source: previousNode.id,
    target: `${previousNode.id}.${nextNode.id}`,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   };

   const newEdge2 = {
    id: `${previousNode.id}.${nextNode.id}-${nextNode.id}`,
    type: 'custom-edge',
    source: `${previousNode.id}.${nextNode.id}`,
    target: nextNode.id,
    sourceHandle: 'bottom-handle',
    targetHandle: 'top-handle'
   };

   return [...updatedEdges, newEdge1, newEdge2]; // Add new edges
  });
 };

 const addConditional2 = () => {
  let newNodePosition = {x: 0, y: 0};
  let Id;
  console.log(id);
  console.log('Conditional2');
  let nodeId = id;
  const node = new Array(2).fill(null);
  console.log(nodeId);

  node[0] = sourceNode.id;
  node[1] = targetNode.id;

  console.log(node);

  setNodes(nds => {
   let updatedNodes = [...nds];

   // Determine position based on the last node in the array

   Id = sourceNode.id;
   newNodePosition = {
    x: sourceNode.position.x, // Keep the same x position
    y: sourceNode.position.y // Position 100px below the last node (adjust as needed)
   };

   let xPosition, yPosition;

   if ('flag' in sourceNode.data) {
    if (sourceNode.data.flag === 'conditional') {
     xPosition = 120;
     yPosition = 100;
    } else {
     xPosition = 0;
     yPosition = 0;
    }
   } else {
    xPosition = 0;
    yPosition = 0;
   }

   const newNodes = [
    {
     id: `${node[0]}.${node[1]}`,
     type: 'textUpdater',
     data: {
      header: 'If/Else Condition',
      headerIcon: 'conditional',
      flag: 'conditionalStart',
      headerDescription: 'Status Purchase is',
      status: 'yes',
      bodyContent: [{label: 'Active', data: 20}]
     },
     //draggable: false,
     position: {
      x: newNodePosition.x - xPosition,
      y: newNodePosition.y + 200 - yPosition
     } // Use dynamically calculated position
    },
    {
     id: `${node[0]}.${node[1]}y`,
     type: 'endNode',
     data: {label: 'Yes', type: 'conditional', flag: true},
     position: {
      x: newNodePosition.x - 10 - xPosition,
      y: newNodePosition.y + 400 - yPosition
     }
     //draggable: false
    },
    {
     id: `${node[0]}.${node[1]}n`,
     type: 'endNode',
     data: {label: 'No', type: 'conditional', flag: false},
     position: {
      x: newNodePosition.x + 200 - xPosition,
      y: newNodePosition.y + 400 - yPosition
     }
     //draggable: false
    },
    {
     id: `${node[0]}.${node[1]}e`,
     type: 'endNode',
     data: {label: 'End Node', type: 'dot', flag: 'conditional'},
     position: {
      x: newNodePosition.x + 120 - xPosition,
      y: newNodePosition.y + 500 - yPosition
     }
     //draggable: false
    }
   ];

   const insertIndex = updatedNodes.findIndex(
    node => node.id === targetNode.id
   );
   console.log(insertIndex);
   for (let i = insertIndex; i < updatedNodes.length; i++) {
    updatedNodes[i].position = {
     x: updatedNodes[i].position.x,
     y: updatedNodes[i].position.y + 400 // Push each node downward
    };
   }
   const beforeInsert = updatedNodes.slice(0, insertIndex); // Nodes before the insertion point
   const afterInsert = updatedNodes.slice(insertIndex); // Nodes after the insertion point
   return [...beforeInsert, ...newNodes, ...afterInsert];
  });

  console.log(Id);

  console.log(node[1]);
  const newEdges = [
   {
    id: `${node[0]}-${node[0]}.${node[1]}`,
    source: `${node[0]}`,
    target: `${node[0]}.${node[1]}`,
    type: 'custom-edge'
   },
   {
    id: `${node[0]}.${node[1]}-${node[0]}.${node[1]}y`,
    source: `${node[0]}.${node[1]}`,
    target: `${node[0]}.${node[1]}y`,
    animated: false
   },
   {
    id: `${node[0]}.${node[1]}-${node[0]}.${node[1]}n`,
    source: `${node[0]}.${node[1]}`,
    target: `${node[0]}.${node[1]}n`,
    animated: false
   },
   {
    id: `${node[0]}.${node[1]}y-${node[0]}.${node[1]}e`,
    source: `${node[0]}.${node[1]}y`,
    target: `${node[0]}.${node[1]}e`,
    type: 'custom-edge'
   },
   {
    id: `${node[0]}.${node[1]}n-${node[0]}.${node[1]}e`,
    source: `${node[0]}.${node[1]}n`,
    target: `${node[0]}.${node[1]}e`,
    type: 'custom-edge'
   },
   {
    id: `${node[0]}.${node[1]}e-${node[1]}`,
    source: `${node[0]}.${node[1]}e`,
    target: node[1],
    type: 'custom-edge'
   }
  ];

  setEdges(eds => {
   let updatedEdges = [...eds];
   const edgeIndex = updatedEdges.findIndex(edge => edge === id);
   updatedEdges.splice(edgeIndex, 1);
   updatedEdges = eds.filter(
    edge => edge.source !== node[0] // Second edge to delete
   );
   return [...updatedEdges, ...newEdges];
  });
 };

 const addConditional = () => {
  let newNodePosition = {x: 0, y: 0};
  console.log(id);
  let Id;
  setNodes(nds => {
   let updatedNodes = [...nds];

   // Determine position based on the last node in the array
   if (updatedNodes.length > 0) {
    Id = sourceNode.id;
    newNodePosition = {
     x: sourceNode.position.x, // Keep the same x position
     y: sourceNode.position.y // Position 100px below the last node (adjust as needed)
    };
    updatedNodes.pop();
    console.log(updatedNodes);
    // Remove the last node
   }
   const newNodes = [
    {
     id: `${parseInt(Id) + 1}`,
     type: 'textUpdater',
     data: {
      header: 'If/Else Condition',
      headerIcon: 'conditional',
      flag: 'conditionalStart',
      headerDescription: 'Status Purchase is',
      status: 'yes',
      bodyContent: [{label: 'Active', data: 20}]
     },
     //draggable: false,
     position: {x: newNodePosition.x, y: newNodePosition.y + 200} // Use dynamically calculated position
    },
    {
     id: `${parseInt(Id) + 1}y`,
     type: 'endNode',
     data: {label: 'Yes', type: 'conditional', flag: true},
     position: {x: newNodePosition.x - 10, y: newNodePosition.y + 400}
     //draggable: false
    },
    {
     id: `${parseInt(Id) + 1}n`,
     type: 'endNode',
     data: {label: 'No', type: 'conditional', flag: false},
     position: {x: newNodePosition.x + 200, y: newNodePosition.y + 400}
     // draggable: false
    },
    {
     id: `${parseInt(Id) + 1}e`,
     type: 'endNode',
     data: {label: 'End Node', type: 'dot', flag: 'conditional'},
     position: {x: newNodePosition.x + 120, y: newNodePosition.y + 500}
     // draggable: false
    },
    {
     id: `${parseInt(Id) + 2}`,
     type: 'endNode',
     data: {label: 'End Node'},
     position: {x: newNodePosition.x + 80, y: newNodePosition.y + 550}
     // draggable: false
    }
    //   {id: '3', data: {label: 'Condition 1'}, position: {x: 150, y: 200}},
    //   {id: '4', data: {label: 'Condition 2'}, position: {x: 450, y: 200}}
   ];
   return [...updatedNodes, ...newNodes];
  });

  console.log(Id);
  // Add two new nodes
  // deleteNodeAndEdges(id);

  // Add edges from the previous node to the new nodes
  const newEdges = [
   {
    id: `${parseInt(Id)}-${parseInt(Id) + 1}`,
    source: `${parseInt(Id)}`,
    target: `${parseInt(Id) + 1}`,
    type: 'custom-edge'
   },
   {
    id: `${parseInt(Id) + 1}-${parseInt(Id) + 1}y`,
    source: `${parseInt(Id) + 1}`,
    target: `${parseInt(Id) + 1}y`,
    animated: false
   },
   {
    id: `${parseInt(Id) + 1}-${parseInt(Id) + 1}n`,
    source: `${parseInt(Id) + 1}`,
    target: `${parseInt(Id) + 1}n`,
    animated: false
   },
   {
    id: `${parseInt(Id) + 1}y-${parseInt(Id) + 1}e`,
    source: `${parseInt(Id) + 1}y`,
    target: `${parseInt(Id) + 1}e`,
    type: 'custom-edge'
   },
   {
    id: `${parseInt(Id) + 1}n-${parseInt(Id) + 1}e`,
    source: `${parseInt(Id) + 1}n`,
    target: `${parseInt(Id) + 1}e`,
    type: 'custom-edge'
   },
   {
    id: `${parseInt(Id) + 1}e-${parseInt(Id) + 2}`,
    source: `${parseInt(Id) + 1}e`,
    target: `${parseInt(Id) + 2}`,
    type: 'custom-edge'
   }
  ];

  // Update state
  // setNodes(nds => [...nds, ...newNodes]);
  console.log(id);
  setEdges(eds => {
   const updatedEdges = [...eds];
   const edgeIndex = updatedEdges.findIndex(edge => edge.id === id);
   updatedEdges.splice(edgeIndex, 1);
   return [...updatedEdges, ...newEdges];
  });
 };

 const handleIconClick = () => {
  setIsPopupVisible(!isPopupVisible); // Toggle the popup visibility
 };

 const tagStart = label => {
  setTagDialog({
   title: `Add/Remove ${label}`,
   open: true,
   onClose: closeTagDialog,
   type: 'tag/list',
   actions: [
    {
     label: 'Cancel',
     onClick: () => handleSubmit(closeTagDialog)(),
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Save',
     onClick: () => handleSubmit(addTagNode)(),
     classes: 'btn-primary'
    }
   ]
  });
 };

 return (
  <>
   <BaseEdge id={id} path={edgePath} />
   <EdgeLabelRenderer>
    <div
     style={{
      position: 'absolute',
      transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
      pointerEvents: 'all',
      border: '3px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 24px 0px #0000001F'
     }}
     className="nodrag nopan"
     onClick={handleIconClick}
     //  onClick={() => {
     //   setEdges(es => es.filter(e => e.id !== id));
     //  }}
    >
     <img src={iconAdd} alt="add" />
    </div>
    {isPopupVisible && (
     <div
      style={{
       position: 'fixed', // Use fixed position to ensure it appears above other elements
       top: `${labelY + 20}px`,
       left: `${labelX}px`,
       transform: 'translate(-50%, 0)',
       zIndex: 100, // Higher z-index to ensure it appears above all elements
       pointerEvents: 'all',
       backgroundColor: '#FFF',
       boxShadow: '0px 4px 24px 0px #0000001F',
       minWidth: '240px',
       padding: '12px 0px 8px 0px',
       borderRadius: '12px',
       border: '0.5px solid #dfdded'
      }}
      className="popup"
     >
      <div className="node-addStep">
       <div className="addStep-header">
        <div>Add Step</div>
        <img
         src={iconClose}
         alt="close"
         style={{width: '20px', height: '20px'}}
         onClick={() => {
          setIsPopupVisible(!isPopupVisible);
         }}
        />
       </div>
       <div className="horizontal-line"></div>
       <div className="addStep-body">
        <div
         className="body-element"
         onClick={() => {
          setEmailDialog({
           title: `Send Email`,
           open: true,
           onClose: closeEmailDialog,
           type: 'email',
           actions: [
            {
             label: 'Cancel',
             onClick: () => handleSubmit(closeEmailDialog)(),
             classes: 'btn-outline dark-border'
            },
            {
             label: 'Save',
             onClick: () => handleSubmit()(),
             classes: 'btn-primary'
            }
           ]
          });
          //   if (
          //    targetNode.data &&
          //    'flag' in targetNode.data &&
          //    targetNode.data.flag !== 'conditionalStart'
          //   ) {
          //    handleClick3();
          //   } else {
          //    if (targetNode.type === 'endNode') {
          //     // Current node is the last node
          //     handleClick();
          //    } else {
          //     // Current node is not the last node
          //     handleClick2();
          //    }
          //   }
          //   setIsPopupVisible(!isPopupVisible);
         }}
        >
         <img src={iconEmail} alt="email" />
         <div>Send Email</div>
        </div>
        <div className="body-element">
         <img src={iconTime} alt="email" />
         <div>Wait</div>
        </div>
        <div
         className="body-element"
         onClick={() => {
          const nodes = getNodes();
          const currentNodeIndex = nodes.findIndex(node => node.id === id);

          if (
           targetNode.type === 'endNode' &&
           !('flag' in targetNode.data) &&
           !('flag' in sourceNode.data)
          ) {
           // Current node is the last node
           addConditional();
          } else {
           // Current node is not the last node
           addConditional2();
          }
          setIsPopupVisible(!isPopupVisible);
         }}
        >
         <img src={iconElse} alt="email" />
         <div>If/Else Conditions</div>
        </div>
        <div className="body-element">
         <img src={iconSplit} alt="email" />
         <div>A/B Split Path</div>
        </div>
        <div
         className="body-element"
         onClick={() => {
          setContactStatus({
           title: 'Change Contact Status',
           open: true,
           onClose: closestatusDialog,
           type: 'changeContactStatus',
           actions: [
            {
             label: 'Cancel',
             onClick: () => handleSubmit(closestatusDialog)(),
             classes: 'btn-outline dark-border'
            },
            {
             label: 'Save',
             onClick: () => handleSubmit(addStatusNode)(),
             classes: 'btn-primary'
            }
           ]
          });
         }}
        >
         <img src={iconContact} alt="email" />
         <div>Change Contact status</div>
        </div>
        <div
         className="body-element"
         onClick={() => {
          setValue('type', 'Tag');
          tagStart('Tag');
         }}
        >
         <img src={iconTag} alt="email" />
         <div>Add/Remove Tag</div>
        </div>
        <div
         className="body-element"
         onClick={() => {
          setValue('type', 'List');
          tagStart('List');
         }}
        >
         <img src={iconList} alt="email" />
         <div>Add/Remove to List</div>
        </div>
        <div className="body-element">
         <img src={iconSkip} alt="email" />
         <div>Jump Step</div>
        </div>
        <div className="body-element">
         <img src={iconGoal} alt="email" />
         <div>Goal</div>
        </div>
        <div className="body-element">
         <img src={iconExit} alt="email" />
         <div>Exit</div>
        </div>
       </div>
      </div>
     </div>
    )}
   </EdgeLabelRenderer>
   <CustomDialog
    {...contactStatus}
    content={
     contactStatus.type === 'changeContactStatus' ? (
      <>
       <form onSubmit={handleSubmit()}>
        <div className="input-container" style={{marginBottom: '0px'}}>
         <label className="email-dialog-label">Status</label>
         <TextField
          variant="outlined"
          placeholder="Select"
          value={status}
          InputProps={{
           readOnly: true,
           endAdornment: (
            <img src={iconArrow} style={{width: '16px', height: '16px'}} />
           )
          }}
          onClick={event => {
           setStatusAnchorElement(event.currentTarget);
          }}
         />
        </div>
       </form>
      </>
     ) : (
      contactStatus.content
     )
    }
   />
   <CustomDialog
    {...emailDialog}
    content={
     emailDialog.type === 'email' ? (
      <>
       <form onSubmit={handleSubmit()}>
        <div className="input-container">
         <div className="email-dialog-label">To</div>
         <input
          type="text"
          className="email-dialog-input"
          {...register('to', {required: 'required field'})}
         />
        </div>
        <div className="input-container">
         <div className="email-dialog-label">Subject</div>
         <textarea
          className="email-dialog-input"
          placeholder="Type here..."
          {...register('subject', {required: 'required field'})}
         />
        </div>
        <div className="input-container">
         <div className="email-dialog-label">Preview Text</div>
         <textarea
          className="email-dialog-input"
          placeholder="Type here..."
          {...register('previewText', {required: 'required field'})}
         />
        </div>
        <FormGroup>
         <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Label"
         />
         <FormControlLabel required control={<Checkbox />} label="Required" />
         <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
        </FormGroup>
       </form>
      </>
     ) : (
      emailDialog.content
     )
    }
   />
   <CustomDialog
    {...tagDialog}
    content={
     tagDialog.type === 'tag/list' ? (
      <>
       <form onSubmit={handleSubmit()}>
        <div className="input-container">
         <div className="email-dialog-label">
          What action you want to apply ?
         </div>
         <div>
          <RadioButtonsGroup
           values={values}
           onChange={handleRadioChange}
           checked={selectedValue}
          />
         </div>
        </div>
        <div className="input-container" style={{marginBottom: '0px'}}>
         <label className="email-dialog-label">Tag</label>
         <input
          type="text"
          className="email-dialog-input"
          {...register('tag', {required: 'tag is required'})}
         />
        </div>
       </form>
      </>
     ) : (
      tagDialog.content
     )
    }
   />
   <CustomMenu
    onClose={() => {
     setStatusAnchorElement(null);
    }}
    onSelect={value => {
     setStatusAnchorElement(null);
     setStatus(value);
     setValue('status', value);
    }}
    options={contactStatusOptions}
    anchorEl={statusAnchorElement}
    inputBox={true}
   />
  </>
 );
}
