import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTemplateId,
  setSelectedTemplates,
  toggleDeleteTemplateDialog,
  toggleDeleteTemplateLoader,
} from "../../../../store/store.js";
import iconClose from "../../../../assets/icons/icon-cross.svg";
import { template } from "../../../../api/templateapi.js";
import { useTemplates } from "../../../../hooks/useTemplates.js";

const DeleteTemplateDialog = () => {
  const dispatch = useDispatch();

  const { fetchTemplates } = useTemplates();
  // const history = useHistory();
  const isDeleteTemplateDialog = useSelector(
    (state) => state.isDeleteTemplateDialog
  );

  const isDeleteTemplateLoader = useSelector(
    (state) => state.isDeleteTemplateLoader
  )
  const templateId = useSelector((state) => state.currentTemplateId);

  const selectedIds = useSelector((state) => state.selectedTemplates);

  console.log(templateId, "curr temp id", selectedIds, "multiple temp ids");

  const closeDeleteTemplateDialog = () => {
    dispatch(toggleDeleteTemplateDialog());
    dispatch(setSelectedTemplates([]));
    dispatch(setCurrentTemplateId(""));
  };

  const deleteTemplate = async () => {
    try {
      dispatch(toggleDeleteTemplateLoader());
      let response;
      if(selectedIds.length > 0){
        response = await template.deleteTemplate({
          ids: [...selectedIds],
        })
      }else{
        response = await template.deleteTemplate({
          ids: [templateId],
        })
      }

      fetchTemplates();
      console.log("Data fetched from Delete Template Dialog after deleting");
      closeDeleteTemplateDialog();
    } catch (err) {
      console.log(err);
      closeDeleteTemplateDialog();
    } finally {
      dispatch(toggleDeleteTemplateLoader());
    }
  };

  return (
    <div className="email-dialog">
      <Dialog open={isDeleteTemplateDialog} onClose={closeDeleteTemplateDialog}>
        <div className="email-dialog-header">
          <DialogTitle>Delete Templates?</DialogTitle>
          <Button
            onClick={closeDeleteTemplateDialog}
            className="email-dialog-close-btn"
          >
            <img src={iconClose} />
          </Button>
        </div>
        <div className="email-dialog-body">
          <div className="input-container">
            <label className="email-dialog-label">
              Once you remove the templates, they will no longer be available to
              you. This action can’t be undone.
            </label>
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={closeDeleteTemplateDialog}
            className="btn btn-outline dark-border"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteTemplate}
            variant="contained"
            className="btn btn-delete ml16"
          >
            {isDeleteTemplateLoader ? <CircularProgress className="loader-btn" /> : ""}
            <span style={{visibility: isDeleteTemplateLoader ? "hidden" : "visible"}}>Delete</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteTemplateDialog;
