import DividerIcon from "../../../../../assets/icons/icon-divider.svg";

export const registerDividerBlock = (editor, opts = {}) => {
  // Define the custom component type for dividerBlock
  editor.DomComponents.addType("Divider", {
    model: {
      defaults: {
        tagName: "span",
        attributes:{'data-gjs-type': 'Divider'},
        classes: ["block-div"],
        style: {
          display: "block !important",
          width: "100%",
          padding: "12px 16px",
          "text-align": "center",
        },
        components: [
          {
            tagName: "div",
            type: "divider", // Custom type or 'default' if no specific behavior is needed
            editable: false, // Dividers typically aren't text-editable
            style: {
              // Default style for the divider, customizable as needed
              "border-top": "2px",
              "border-top-style": "solid",
              "border-top-color": "#000000",
              display: "inline-block",
              width: "100%",
              "vertical-align": "middle",
            },
          },
        ],
        // Enable inline styling
        stylable: true,
        droppable: false,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for dividerBlock
  editor.BlockManager.add("dividerBlock", {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${DividerIcon} alt="Divider Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Divider</div>`,
    content: { type: "Divider" },
    category: "Blocks",
    draggable: "td",
  });
};
