import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ onChange, footerTraits }) => {
  const [editorHtml, setEditorHtml] = useState( footerTraits.content || "You have received this email as a registered user of {{companyname}} {{company_addresss}} You can {{unsubscribe}} from these emails here");

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange(html);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      [{ 'align': [] }],
      ['link'],
    ],
  };

  const formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'color',
    'align',
    'link',
  ];

  return (
    <div>
      <ReactQuill
        value={editorHtml}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder="Start typing your content..."
      />
    </div>
  );
};

export default TextEditor;
