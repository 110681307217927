import {
 BrowserRouter as Router,
 Routes,
 Route,
 Navigate
} from 'react-router-dom';
import EmailBuilder from './layouts/EmailBuilder/EmailBuilder';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import './styles/global.scss';
import './styles/override.scss';
import CustomNodeFlow from './layouts/Workflow/CustomNodeFlow';
import Login from './auth/Login';
import {useEffect, useState} from 'react';
import SignUp from './auth/Signup';
import ResetPass from './auth/createPass';
import VerificationSuccess from './auth/VerificationSuccess';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {setIsAuthenticated} from './store/store';

const App = () => {
 // const [isAuthenticated, setIsAuthenticated] = useState(false);

 //const dispatch = useDispatch();

 //  useEffect(() => {
 //   const loginToken = localStorage.getItem('loginToken');
 //   dispatch(dispatch(setIsAuthenticated(loginToken ? true : false)));
 //   window.history.pushState('/dashboard');
 //  }, []);

 const isAuthenticated = useSelector(state => state.isAuthenticated);
 return (
  <Router basename="/app">
   <Routes>
    <Route path="/verification-success" element={<VerificationSuccess />} />{' '}
    <Route path="/login" element={<Login />} />{' '}
    <Route path="/signup" element={<SignUp />} />{' '}
    <Route path="/createPass" element={<ResetPass />} />{' '}
    <Route path="/email-builder/:name/:id" element={<EmailBuilder />} />{' '}
    <Route path="/workflow" element={<CustomNodeFlow />} />{' '}
    <Route
     path="/*"
     element={
      isAuthenticated ? (
       <div className="App">
        <Sidebar />
        <Routes>
         <Route path="/*" element={<MainContent />} />{' '}
         <Route path="/" element={<Navigate to="/dashboard" replace />} />{' '}
        </Routes>{' '}
       </div>
      ) : (
       <Navigate
        to="/signup
                "
       />
      )
     }
    />{' '}
   </Routes>{' '}
  </Router>
 );
};

export default App;
