import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Button,
} from "@mui/material";

const CategoryFilter = () => {
  const categories = [
    { name: "All", count: 35 },
    { name: "eCommerce", count: 10 },
    { name: "Digital Marketer", count: 4 },
    { name: "eBook", count: 3 },
    { name: "Business Coach", count: 5 },
    { name: "Online Courses", count: 2 },
    { name: "Fitness Trainer", count: 4 },
    { name: "Pet Care", count: 3 },
    { name: "Webinar", count: 4 },
  ];

  const [selectedCategories, setSelectedCategories] = React.useState({
    All: true,
  });

  const handleChange = (event) => {
    setSelectedCategories({
      ...selectedCategories,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClearAll = () => {
    setSelectedCategories({});
  };

  return (
    <Box
      sx={{ width: 276, bgcolor: "background.paper", p: 2, borderRadius: 2 }}
    >
      <div className="prebuilt-sidebar-header">
        <Typography variant="h6" gutterBottom component="div">
          Categories
        </Typography>
        <Button onClick={handleClearAll} color="primary">
          Clear All
        </Button>
      </div>
      <FormGroup>
        {categories.map((category) => (
          <div className="category-label-container">
            <div className="category-label">
            <FormControlLabel
              key={category.name}
              control={
                <Checkbox
                  checked={!!selectedCategories[category.name]}
                  onChange={handleChange}
                  name={category.name}
                />
              }
              // label={`${category.name} (${category.count})`}
            />
            <Typography variant="body2">{category.name}</Typography>
            </div>
            <div className="category-count">
            <Typography variant="body2">{category.count}</Typography>
            </div>
          </div>
        ))}
      </FormGroup>
    </Box>
  );
};

export default CategoryFilter;
