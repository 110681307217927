import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
 TextField,
 Button,
 Box,
 Typography,
 createTheme,
 Link,
 Grid,
 CssBaseline,
 Container
} from '@mui/material';
import {auth} from '../api/authapi';

import appIcon from '../assets/icons/app-icon.svg';
import {ThemeProvider} from '@emotion/react';
import {resetPassSchema} from '../api/validation';
import * as zod from 'zod';
import verificationIcon from '../assets/icons/icon-comparison-tick.svg';
const defaultTheme = createTheme();

const ResetPass = () => {
 const [password, setPassword] = useState('');
 const [confirmPassword, setConfirmPassword] = useState('');
 const [errors, setErrors] = useState({});
 const [isReset, setIsReset] = useState(false);
 const navigate = useNavigate();

 const handleSubmit = async event => {
  event.preventDefault();
  //validating data
  try {
   console.log('validating data');
   await resetPassSchema.parseAsync({password, confirmPassword});
   console.log('Valid Data:', {password, confirmPassword});
   setErrors({});
  } catch (error) {
   if (error instanceof zod.ZodError) {
    const newErrors = {};
    error.issues.forEach(issue => {
     newErrors[issue.path[0]] = issue.message;
     console.log(issue.message);
    });
    console.log(newErrors);
    setErrors(newErrors);
    console.log({password, confirmPassword});
    return;
   } else {
    console.error('Unexpected error:', error);
    return;
    // Handle other types of errors in validation
   }
  }

  try {
   const response = await auth.resetPassword({
    password: password,
    confirmPassword: confirmPassword,
    token: ''
   });
   console.log(response.data, 'password created successfully');

   setIsReset(true);
  } catch (err) {
   console.log(err);
  }
 };

 const openLogin = () => {
  navigate('/login');
 };

 return (
  <>
   <div className="header auth">
    <div className="header-left">
     <img className="app-icon" alt="appIcon" src={appIcon} />{' '}
     <span className="template-name heading"> adflipr </span>{' '}
    </div>{' '}
    <div className="header-center"> </div> <div className="header-right"></div>{' '}
   </div>{' '}
   {isReset ? (
    <div className="verification-container">
     <img className="verification-icon" src={verificationIcon} />{' '}
     <h1 className="verification-heading"> Successfully Created Password </h1>{' '}
     <p className="verification-content">
      Now you can login with your new password{' '}
     </p>{' '}
     <a className="verification-link" onClick={openLogin}>
      Login{' '}
     </a>{' '}
    </div>
   ) : (
    <ThemeProvider theme={defaultTheme}>
     {' '}
     <Container component="main" className="form" maxWidth="xs">
      <CssBaseline />
      <Box
       sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
       }}
       className="form-container"
      >
       <Typography
        className="form-heading"
        component="h1"
        variant="h5"
        gutterBottom
       >
        Create New <strong> Password </strong>{' '}
       </Typography>{' '}
       <Box
        component="form"
        className="form-component login-form"
        noValidate
        sx={{mt: 3}}
       >
        {' '}
        <Grid className="form-grid" container spacing={2}>
         <Grid className="form-grid-item" item xs={12}>
          {' '}
         </Grid>{' '}
         <Grid className="form-grid-item" item xs={12}>
          <div className="input-container">
           <label className="email-dialog-label"> Enter New Password </label>{' '}
           <input
            className={`email-dialog-input ${
             errors.password ? 'box-error' : ''
            }`}
            placeholder="Type here..."
            type="password"
            onChange={e => {
             setPassword(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              password: ''
             }));
            }}
           ></input>{' '}
           {errors.password && (
            <div className="error-msg"> {errors.password} </div>
           )}{' '}
          </div>{' '}
         </Grid>{' '}
         <Grid className="form-grid-item" item xs={12}>
          <div className="input-container">
           <label className="email-dialog-label"> Confirm New Password </label>{' '}
           <input
            className={`email-dialog-input ${
             errors.confirmPassword ? 'box-error' : ''
            }`}
            placeholder="Type here..."
            type="password"
            onChange={e => {
             setConfirmPassword(e.target.value);
             setErrors(prevErrors => ({
              ...prevErrors,
              confirmPassword: ''
             }));
            }}
           ></input>{' '}
           {errors.confirmPassword && (
            <div className="error-msg"> {errors.confirmPassword} </div>
           )}{' '}
          </div>{' '}
         </Grid>{' '}
        </Grid>{' '}
        <Button
         type="submit"
         fullWidth
         className="btn btn-primary btn-signup"
         variant="contained"
         onClick={handleSubmit}
        >
         Create{' '}
        </Button>{' '}
       </Box>{' '}
       <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        className="form-info"
        sx={{mt: 2}}
       ></Typography>{' '}
      </Box>{' '}
     </Container>{' '}
    </ThemeProvider>
   )}{' '}
  </>
 );
};

export default ResetPass;
