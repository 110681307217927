import { DateTime } from "luxon";
import { socialTypes } from "./customBlocks/socialTypes";
import Sortable from "sortablejs";
import iconBold from "../../../../assets/icons/icon-bold.svg";
import iconItalic from "../../../../assets/icons/icon-italic.svg";
import iconUnderline from "../../../../assets/icons/icon-underline.svg";
import iconStrikethrough from "../../../../assets/icons/icon-Strikethrough.svg";
import iconHilite from "../../../../assets/icons/icon-Highlight.svg";
import iconUL from "../../../../assets/icons/icon-bullet.svg";
import iconOL from "../../../../assets/icons/icon-bullet-number.svg";
import iconSuperscript from "../../../../assets/icons/icon-Superscript.svg";
import iconSubscript from "../../../../assets/icons/icon-Subscript.svg";
import iconFontcolor from "../../../../assets/icons/icon-Textcolor.svg";
import iconLink from "../../../../assets/icons/icon-link.svg";

// Function to move blocks to divs
export const moveBlocksToDivs = (editor) => {
  if (!editor) {
    console.error("Editor is not initialized");
    return;
  }

  const blockManager = editor.BlockManager;
  if (!blockManager) {
    console.error("BlockManager is not initialized");
    return;
  }

  const allBlocks = blockManager.getAll();

  allBlocks.forEach((block) =>
    console.log("adding " + block.name + "in block manager")
  );

  const filteredBlocks = allBlocks.filter(
    (block) => block.get("category").attributes.id === "Blocks"
  );
  const filteredContainers = allBlocks.filter(
    (block) => block.get("category").attributes.id === "Containers"
  );
  const filteredLayouts = allBlocks.filter(
    (block) => block.get("category").attributes.id === "Layouts"
  );
  const filteredPrebuilts = allBlocks.filter(
    (block) => block.get("category").attributes.id === "Prebuilt"
  );

  console.log(
    "dynamic",
    filteredLayouts.length,
    "10",
    filteredBlocks.length,
    "8",
    filteredContainers.length,
    "4",
    filteredPrebuilts.length
  );

  const newBlocksEl = blockManager.render(filteredBlocks, { external: true });
  const newContainersEl = blockManager.render(filteredContainers, {
    external: true,
  });
  const newLayoutsEl = blockManager.render(filteredLayouts, { external: true });
  const newPrebuiltEl = blockManager.render(filteredPrebuilts, {
    external: true,
  });

  const containerDivs = document.querySelector("#containers-block");
  const layoutDivs = document.querySelector("#layouts-block");
  const blockDivs = document.querySelector("#block-div");
  const prebuiltDivs = document.querySelector("#prebuilt-block");

  containerDivs.innerHTML = "";
  layoutDivs.innerHTML = "";
  blockDivs.innerHTML = "";
  prebuiltDivs.innerHTML = "";

  blockDivs.appendChild(newBlocksEl);
  containerDivs.appendChild(newContainersEl);
  layoutDivs.appendChild(newLayoutsEl);
  prebuiltDivs.appendChild(newPrebuiltEl);
};

export function setupAssetManagerButtonBehavior(editor) {
  const am = editor.AssetManager;
  const originalRender = am.constructor.prototype.render;

  am.constructor.prototype.render = function () {
    originalRender.call(this); // Continue using the original render functionality
    addButtonsToAssets();
  };

  function addButtonsToAssets() {
    document
      .querySelectorAll(".gjs-am-close")
      .forEach((btn) => (btn.textContent = ""));
    document.querySelector(".gjs-mdl-btn-close").textContent = "";
    document.querySelector(".gjs-mdl-title").textContent =
      "Select Image to Upload";
    document
      .querySelectorAll(
        ".gjs-am-assets .gjs-am-asset .gjs-am-preview-cont:not(.button-added)"
      )
      .forEach((asset) => {
        const useButton = document.createElement("button");
        useButton.className = "use-image-button";
        useButton.innerText = "Use Image";
        asset.classList.add("button-added");

        asset.appendChild(useButton);

        asset.onmouseover = () => (useButton.style.display = "block");
        asset.onmouseout = () => (useButton.style.display = "none");

        useButton.onclick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          const backgroundImageStyle =
            asset.style.backgroundImage ||
            window.getComputedStyle(asset).backgroundImage;
          const match = backgroundImageStyle.match(/url\("?(.+?)"?\)/);
          if (match && match[1]) {
            const src = match[1];
            if (am.onSelect) {
              am.onSelect(src);
            } else {
              const target = am.getTarget();
              if (target) {
                target.set("src", src);
              }
            }
            am.close();
          } else {
            console.error("No background image found in the asset");
          }
        };
      });
  }

  function startObserving() {
    const assetManagerEl = document.querySelector(".gjs-am-assets");
    if (assetManagerEl) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            addButtonsToAssets();
          }
        });
      });

      observer.observe(assetManagerEl, { childList: true, subtree: true });
    } else {
      // Retry after 500 milliseconds if the element is not found
      setTimeout(startObserving, 500);
    }
  }

  // Start the observing process
  startObserving();
}

export function customizeRTEIcons() {
  const customIcons = {
    Bold: iconBold,
    Italic: iconItalic,
    Underline: iconUnderline,
    "Strike-through": iconStrikethrough,
    Hyperlink: iconLink,
    "Font Color": iconFontcolor,
    "Highlight Color": iconHilite,
    Subscript: iconSubscript,
    Superscript: iconSuperscript,
    "Ordered List": iconOL,
    "Unordered List": iconUL,
  };

  document.querySelectorAll(".gjs-rte-action").forEach((btn) => {
    const title = btn.getAttribute("title");
    const iconSrc = customIcons[title];

    // Only customize buttons that have matching titles
    if (iconSrc) {
      btn.innerHTML = ""; // Clear existing content
      const img = document.createElement("img");
      img.src = iconSrc;
      img.alt = title;
      img.title = title;
      img.width = 18;
      img.height = 18;
      btn.appendChild(img);
    }
  });
}
//Set Border Settings Trait Visibility on the basis of border-style value

export const setBorderTraitVisibility = (editor, value) => {
  const selectedComponent = editor.getSelected();

  if (value) {
    // Find the index of border-style trait
    const traits = selectedComponent.get("traits").models;
    const borderStyleTraitIndex = traits.findIndex(
      (trait) => trait.id === "border-style"
    );

    // Add or show border-width and border-color traits
    selectedComponent.addTrait(
      {
        type: "border-width",
        name: "border-width",
        label: "Border Width",
        changeProp: 1,
      },
      { at: borderStyleTraitIndex + 1 }
    );

    selectedComponent.addTrait(
      {
        type: "border-color",
        name: "border-color",
        label: "Border Color",
      },
      { at: borderStyleTraitIndex + 2 }
    );
  } else {
    // Remove or hide border-width and border-color traits
    selectedComponent.removeTrait("border-width");
    selectedComponent.removeTrait("border-color");
  }
};

//Section Trait Manager
export const renderSectionTableTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set("traits", []);
  // Create a container trait for section-width-delete types
  selectedComponent.addTrait({
    type: "trait-container",
    id: "widths-container", // ID for the container
  });

  // Find all `td` elements
  const tds = selectedComponent.find("td.container");

  // Create a trait for each `td` to set its width, adding them to the container
  tds.forEach((td, index) => {
    selectedComponent.addTrait({
      type: "section-width-delete",
      label: `Column ${index + 1}`,
      name: `width-${index}`,
      value: td.getEl().offsetWidth || "",
      tdIndex: index,
      parent: "widths-container", // Parent ID set to the container
    });
  });

  // Add additional traits outside the container
  if (tds.length < 4) {
    selectedComponent.addTrait({
      type: "add-column-button",
      text: "Add Column",
      name: "add-column",
    });
  }

  selectedComponent.addTrait({
    type: "color-picker",
    label: "Background",
    name: "background-color",
  });

  selectedComponent.addTrait({
    type: "column-gap",
    label: "Column Gap",
    name: "column-gap",
  });

  selectedComponent.addTrait({
    type: "border-settings",
    label: "",
    name: "border-settings",
  });

  selectedComponent.addTrait({
    type: "border-radius-control",
    label: "Border Radius",
    name: "border-radius",
  });

  selectedComponent.addTrait({
    type: "padding-control",
    label: "Padding",
    name: "padding",
  });

  // Re-render the traits panel
  editor.TraitManager.render();

  initializeSorting(selectedComponent, editor, "widths-container");
};

//Section Trait Functions
export const addColumnToTable = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  console.log("add button clicked", selectedComponent);
  if (selectedComponent.is("table")) {
    const tbody = selectedComponent.find("tbody")[0];
    if (tbody) {
      const sectionTableRow = selectedComponent.find(
        '[type="section-table"] tr'
      )[0];
      if (sectionTableRow) {
        sectionTableRow.append({
          type: "cell",
          tagName: "td",
          classes: "container-td container",
          attributes: { type: "column" },
          style: {
            width: "", // Include other styles as needed
          },
          components: [
            // Add any initial components you want inside the new td
          ],
        });
        editor.trigger("component:update", selectedComponent);
      }
    }
  }
};

export const addColumnToTraitManager = (editor, trait) => {
  // Assuming 'editor' is your GrapesJS editor instance
  const selectedComponent = editor.getSelected();

  if (selectedComponent) {
    const tds = selectedComponent.find("td");
    if (tds.length < 4) {
      editor.runCommand(trait.get("command"));
    }
    if (selectedComponent.views[0].attr.type === "section-table") {
      renderSectionTableTraitManager(selectedComponent, editor);
    }
  }
};

export const deleteColumnFromTraitManager = (editor, trait) => {
  const selectedComponent = editor.getSelected();

  if (selectedComponent) {
    const tds = selectedComponent.find("td.container");
    const index = trait.get("tdIndex"); // Assuming 'tdIndex' is correctly set when the trait is added

    if (tds.length > 1 && tds[index]) {
      tds[index].remove(); // Delete the column directly here
      editor.trigger("component:update", selectedComponent); // Refresh the selected component
    }

    // Optional: Additional functionality after deleting a column
    if (selectedComponent.views[0].attr.type === "section-table") {
      renderSectionTableTraitManager(selectedComponent, editor);
    }

    editor.TraitManager.render();
  }
};

export const changeColumnWidth = (editor, trait, event) => {
  const newValue = parseInt(event.target.value, 10);
  const selectedComponent = editor.getSelected();
  const index = parseInt(trait.get("name").split("-")[1], 10);
  const tds = selectedComponent.find("td");

  if (tds.length === 1) {
    alert("Cannot change width of single column");
    return;
  }

  if (tds[index]) {
    const oldValue = tds[index].getEl().offsetWidth;
    const difference = newValue - oldValue;
    let widths = tds.map((td) => td.getEl().offsetWidth);

    // Ensure the new value does not go below the minimum width
    if (newValue < 60) {
      alert("Minimum column width is 60px");
      return;
    }

    // Adjust the current column to the new value
    widths[index] = newValue;

    // Find the index of the largest column to adjust
    if (difference > 0) {
      let remainingDifference = difference;
      // Sort indexes by width descending, ignoring the current index
      const sortedIndexes = widths
        .map((width, i) => ({ index: i, width }))
        .filter((w) => w.index !== index)
        .sort((a, b) => b.width - a.width);

      for (let { index: i } of sortedIndexes) {
        if (remainingDifference <= 0) break;
        const maxDecrease = widths[i] - 60; // Minimum width is 60
        const decrease = Math.min(remainingDifference, maxDecrease);
        widths[i] -= decrease;
        remainingDifference -= decrease;
      }
    } else if (difference < 0) {
      const adjacentIndex = index === tds.length - 1 ? index - 1 : index + 1;
      widths[adjacentIndex] += Math.abs(difference);
    }

    // Apply the new styles and ensure the total width is maintained
    tds.forEach((td, idx) => {
      td.addStyle({ width: `${widths[idx]}px` });
    });

    // Trigger update and re-render the trait manager
    editor.trigger("component:update", selectedComponent);
    editor.TraitManager.render();
    renderSectionTableTraitManager(selectedComponent, editor);
  }
};

//Column Trait Manager
export const renderColumnTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "color-picker",
      label: "Background Color",
      name: "background-color",
    },
    {
      type: "border-settings", // Use your custom trait
      label: "Border Style",
      name: "border-settings",
    },
    {
      type: "border-radius-control",
      label: "Border Radius",
      name: "border-radius",
    },
    {
      type: "padding-control",
      label: "Padding",
      name: "padding",
    },
  ]);

  editor.TraitManager.render();
};

//TextBlock Trait Manager
export const renderTextBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "typography-settings",
      label: "",
      name: "typography",
    },
    {
      type: "padding-control",
      label: "Padding",
      name: "padding",
    },
  ]);

  editor.TraitManager.render();
};

//ImageBlock Trait Manager
export const renderImageBlockTraitManager = (selectedComponent, editor) => {
  //Clear existing traits
  selectedComponent.set("traits", []);

  const imageSrc = selectedComponent.find("img")[0]?.get("src");
  if (imageSrc && !imageSrc.startsWith("<svg ")) {
    selectedComponent.addTrait({
      type: "asset-manager",
    });

    selectedComponent.addTrait({
      type: "element-float",
      label: "Position",
      name: "element-float",
    });

    selectedComponent.addTrait({
      type: "image-width-control",
      label: "Width",
      name: "width",
    });

    selectedComponent.addTrait({
      type: "alt-text",
      label: "Alt Text",
      name: "alt",
    });

    selectedComponent.addTrait({
      type: "image-link-input",
      label: "Link",
      name: "link",
      changeProp: 1,
    });

    selectedComponent.addTrait({
      type: "child-border-settings",
      label: "Border Style",
      name: "border-settings",
    });

    selectedComponent.addTrait({
      type: "child-border-radius-control",
      label: "Border Radius",
      name: "border-radius",
    });

    selectedComponent.addTrait({
      type: "padding-control",
      label: "Padding",
      name: "padding",
    });
  } else {
    selectedComponent.addTrait({
      type: "asset-manager",
    });
  }

  editor.TraitManager.render();
};

// Function to wrap image with an anchor tag in the component structure
export function wrapImageWithLink(component, url) {
  const imageElement = component.find("img")[0];

  if (imageElement) {
    const spanComponent = imageElement.closest("span.block-div");

    if (spanComponent) {
      // Create the new anchor element as a GrapesJS component
      const anchorComponent = {
        tagName: "a",
        attributes: {
          href: url,
          target: "_blank",
        },
        components: [imageElement],
      };

      // Replace the content of the span with the new anchor-wrapped image
      spanComponent.components().reset([anchorComponent]);
    }
  }
}

// Function to unwrap image from an anchor tag in the component structure
export function unwrapImageFromLink(component) {
  const imageElement = component.find("img")[0];

  if (imageElement) {
    const spanComponent = imageElement.closest("span.block-div");

    if (spanComponent) {
      // Replace the content of the span with the image element
      spanComponent.components().reset([imageElement]);
    }
  }
}

// assetManagerFunctions.js
function getTruncatedName(fullName, maxLength = 10) {
  if (fullName.length <= maxLength) {
    return fullName;
  }

  const extension = fullName.slice(fullName.lastIndexOf("."));
  const baseName = fullName.slice(0, fullName.lastIndexOf("."));

  if (baseName.length + extension.length <= maxLength) {
    // If the full name is too long, but the base name fits within the maxLength
    return fullName;
  }

  // Calculate the number of characters that can be taken from the base name
  const baseNameLength = maxLength - extension.length - 3; // 3 for the ellipsis
  return baseName.substring(0, baseNameLength) + "..." + extension;
}

export function updateUIWithImage(
  asset,
  elements,
  grapesjsEditor,
  containerWidth
) {
  const { dropIcon, label, infoText, helperText, editButtonContainer, el } =
    elements;
  const src = asset.get("src");
  const name = asset.get("name") || "Untitled";
  let imageWidth, imageHeight;
  const imageComponent = grapesjsEditor.getSelected().find("img")[0];

  const img = new Image();
  img.onload = () => {
    imageWidth = img.width;
    imageHeight = img.height;

    if (src && !src.startsWith("data:image/svg+xml;base64")) {
      imageComponent.addAttributes({ src: src });
      // Apply width condition directly to the image on the canvas
      if (imageWidth > parseInt(containerWidth, 10)) {
        imageComponent.addStyle({
          width: "100%",
          height: "auto",
        });
      }
      if (imageWidth <= parseInt(containerWidth, 10)) {
        imageComponent.addStyle({
          width: `${imageWidth}px`,
          height: "auto",
        });
      }
      dropIcon.src = src;
      dropIcon.style.height = "auto";
      dropIcon.style.width = "100%";
      label.textContent = "";
      infoText.textContent = "";
      helperText.textContent = getTruncatedName(name, 36) || "";
      editButtonContainer.style.display = "flex";
      el.style.cursor = "default";
      el.style.padding = "0";
      el.style.maxHeight = "96px";
      el.style.overflow = "hidden";
    } else {
      resetUI(elements.grapesjsEditor);
    }
  };
  img.src = src;
}

export function resetUI(elements, grapesjsEditor) {
  const {
    dropIcon,
    label,
    infoText,
    helperText,
    editButtonContainer,
    el,
    iconUpload,
  } = elements;
  dropIcon.src = iconUpload;
  label.textContent = "Click to Upload Image";
  infoText.textContent = "Allow only PNG, JPG, JPEG, and GIF";
  helperText.textContent = "Max file size 3 MB and 2000 px in width";
  editButtonContainer.style.display = "none";
  el.style.cursor = "pointer";
  el.style.padding = "16px";
  el.style.maxHeight = "auto";
}

//ButtonBlock Trait Manager
export const renderButtonBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "element-float",
      label: "Position",
      name: "element-float",
    },
    {
      type: "button-link-manager",
      label: `Link`,
      name: `link-manager`,
    },
    {
      type: "font-family",
      label: "Font Family",
      name: "font-family",
    },
    {
      type: "line-height",
      label: "Line Height",
      name: "line-height",
    },
    {
      type: "child-width-control",
      label: "Enable Child Width",
      name: "enableChildWidth",
    },
    {
      type: "child-padding-control",
      label: "Padding",
      name: "child-padding",
    },
    {
      type: "padding-control",
      label: "Container Spacing",
      name: "padding",
    },
    {
      type: "second-child-border-settings",
      label: "Child Border Settings",
    },
    {
      type: "second-child-border-radius-control",
      label: "Border Radius",
      name: "border-radius",
    },
  ]);

  editor.TraitManager.render();
};

//DividerBlock Trait Manager
export const renderDividerBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "element-float",
      label: "Position",
      name: "element-float",
    },
    {
      type: "child-width",
      label: "Width",
      name: "width",
    },
    {
      type: "divider-height",
      label: "Height",
      name: "divider-height",
    },
    {
      type: "divider-style",
      label: "Style",
      name: "divider-style", // This should match the CSS property name if you want it to reflect the style without extra mapping
    },
    {
      type: "divider-color",
      label: "Color",
      name: "divider-color",
    },
    {
      type: "padding-control",
      label: "Padding",
      name: "padding",
    },
  ]);

  editor.TraitManager.render();
};

//TimerBlock Trait Manager
export const renderTimerTraitManager = (selectedComponent, editor) => {
  const viewMode =
    selectedComponent.getAttributes()["data-view-mode"] || "text";

  selectedComponent.set("traits", []);
  selectedComponent.addTrait({
    type: "view-mode-toggler",
    label: "Type",
    name: "data-view-mode",
    value: viewMode,
  });

  selectedComponent.addTrait({
    type: "datetime-local",
    label: "End Date & Time",
    name: "endTime",
  });

  selectedComponent.addTrait({
    type: "select-timezone",
    label: "Timezone",
    name: "timezone",
  });

  selectedComponent.addTrait({
    type: "select-language",
    label: "Language",
    name: "language",
  });

  selectedComponent.addTrait({
    type: "color-picker",
    label: "Background Color",
    name: "background-color",
  });

  if (viewMode === "card") {
    selectedComponent.addTrait({
      type: "card-bgcolor",
      label: "Card Background Color",
      name: "card-bgcolor",
    });
  }

  selectedComponent.addTrait({
    type: "timer-digit-color",
    label: "Digit Color",
    name: "digit-color",
  });

  selectedComponent.addTrait({
    type: "timer-label-color",
    label: "Label Color",
    name: "label-color",
  });

  selectedComponent.addTrait({
    type: "font-family",
    label: "Font Family",
    name: "font-family",
  });

  selectedComponent.addTrait({
    type: "digit-font-size",
    label: "Digit Font Size",
    name: "digit-font-size",
  });

  selectedComponent.addTrait({
    type: "label-font-size",
    label: "Label Font Size",
    name: "label-font-size",
  });

  selectedComponent.addTrait({
    type: "padding-control",
    label: "Padding",
    name: "padding",
  });

  editor.TraitManager.render();
};

const countdownTranslations = {
  arabic: { days: "أيام", hours: "ساعات", minutes: "دقائق", seconds: "ثواني" },
  chinese: { days: "天", hours: "小时", minutes: "分钟", seconds: "秒" },
  czech: {
    days: "Dny",
    hours: "Hodiny",
    minutes: "Minuty",
    seconds: "Sekundy",
  },
  danish: {
    days: "Dage",
    hours: "Timer",
    minutes: "Minutter",
    seconds: "Sekunder",
  },
  dutch: {
    days: "Dagen",
    hours: "Uren",
    minutes: "Minuten",
    seconds: "Seconden",
  },
  english: {
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
  },
  estonian: {
    days: "Päevad",
    hours: "Tunnid",
    minutes: "Minutid",
    seconds: "Sekundid",
  },
  farsi: { days: "روزها", hours: "ساعت", minutes: "دقیقه", seconds: "ثانیه" },
  finnish: {
    days: "Päivää",
    hours: "Tuntia",
    minutes: "Minuuttia",
    seconds: "Sekuntia",
  },
  french: {
    days: "Jours",
    hours: "Heures",
    minutes: "Minutes",
    seconds: "Secondes",
  },
  german: {
    days: "Tage",
    hours: "Stunden",
    minutes: "Minuten",
    seconds: "Sekunden",
  },
  italian: {
    days: "Giorni",
    hours: "Ore",
    minutes: "Minuti",
    seconds: "Secondi",
  },
  korean: { days: "일", hours: "시간", minutes: "분", seconds: "초" },
  polish: {
    days: "Dni",
    hours: "Godziny",
    minutes: "Minuty",
    seconds: "Sekundy",
  },
  portuguese: {
    days: "Dias",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  },
  russian: {
    days: "Дни",
    hours: "Часы",
    minutes: "Минуты",
    seconds: "Секунды",
  },
  spanish: {
    days: "Días",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  },
  swedish: {
    days: "Dagar",
    hours: "Timmar",
    minutes: "Minuter",
    seconds: "Sekunder",
  },
  turkish: {
    days: "Günler",
    hours: "Saatler",
    minutes: "Dakikalar",
    seconds: "Saniyeler",
  },
  hindi: { days: "दिन", hours: "घंटे", minutes: "मिनट", seconds: "सेकंड" },
};

export const updateCountdownDisplay = (
  component,
  remainingTime,
  language = "english"
) => {
  const labels = countdownTranslations[language];

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  const dayEl = component.find("[data-js='countdown-day']")[0];
  const hourEl = component.find("[data-js='countdown-hour']")[0];
  const minuteEl = component.find("[data-js='countdown-minute']")[0];
  const secondEl = component.find("[data-js='countdown-second']")[0];

  const dayBlock = component.find(".countdown-block")[0];

  const timerType = component.getAttributes()["data-view-mode"];
  console.log(timerType, "Timer Type"); // Debugging to check timer type

  const addSeparator = timerType !== "card";

  if (dayEl) {
    const dayParent = dayEl.parent();
    if (remainingTime >= 1000 * 60 * 60 * 24) {
      dayBlock.addAttributes({ style: { display: "block" } });
      dayEl.components(
        `${days < 10 ? "0" + days : days}${addSeparator ? " :" : ""}`
      );
      if (dayParent) {
        const dayLabel = dayParent.find(".countdown-label")[0];
        if (dayLabel) dayLabel.components(labels.days);
      }
    } else {
      // dayBlock.attributes.style.display = "none";
      dayBlock.addAttributes({ style: { display: "none" } });
      // Remove the day countdown-block
      // if (dayParent) {
      //   // dayParent.remove();

      // }
    }
  }
  if (hourEl) {
    hourEl.components(
      `${hours < 10 ? "0" + hours : hours}${addSeparator ? " :" : ""}`
    );
    const hourParent = hourEl.parent();
    if (hourParent) {
      const hourLabel = hourParent.find(".countdown-label")[0];
      if (hourLabel) hourLabel.components(labels.hours);
    }
  }
  if (minuteEl) {
    minuteEl.components(
      `${minutes < 10 ? "0" + minutes : minutes}${addSeparator ? " :" : ""}`
    );
    const minuteParent = minuteEl.parent();
    if (minuteParent) {
      const minuteLabel = minuteParent.find(".countdown-label")[0];
      if (minuteLabel) minuteLabel.components(labels.minutes);
    }
  }
  if (secondEl) {
    secondEl.components(`${seconds < 10 ? "0" + seconds : seconds}`);
    const secondParent = secondEl.parent();
    if (secondParent) {
      const secondLabel = secondParent.find(".countdown-label")[0];
      if (secondLabel) secondLabel.components(labels.seconds);
    }
  }
};

export const restartCountdown = (selectedComponent) => {
  const endTime =
    selectedComponent?.getAttributes()["data-datetime"] ||
    DateTime.local().toISO();
  const timezone =
    selectedComponent?.getAttributes()["data-timezone"] || "local";
  startCountdown(selectedComponent, endTime, timezone);
};

export const startCountdown = (component, endTime, timezone = "local") => {
  // const countDownDate = new Date(endTime).getTime();
  const countDownDate = DateTime.fromISO(endTime, {
    zone: timezone,
  }).toMillis();

  // Clear any existing interval
  if (component.countdownInterval) {
    clearInterval(component.countdownInterval);
  }

  const interval = setInterval(() => {
    // const now = new Date().getTime();
    const now = DateTime.local().setZone(timezone).toMillis();
    const remainingTime = countDownDate - now;

    const language = component?.getAttributes()["data-language"] || "english";

    if (remainingTime < 0) {
      clearInterval(interval);
      updateCountdownDisplay(component, 0, language);
    } else {
      updateCountdownDisplay(component, remainingTime, language);
    }
  }, 1000);

  // Store the interval ID on the component for later access
  component.countdownInterval = interval;

  return interval;
};

//TableBlock Trait Manager
export const renderTableTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "table-position",
      label: "Position",
      name: "table-position",
    },
    {
      type: "table-actions",
      label: `Table Actions <br>
      (You need to select the column to add table element) `,
      name: "table-actions",
    },
    {
      type: "table-heading-background-color",
      label: "Heading Background Color",
      name: "heading-background-color",
    },
    {
      type: "table-heading-text-color",
      label: "Heading Text Color",
      name: "heading-text-color",
    },
    {
      type: "table-border-settings", // Use your custom trait
      label: "Border Style",
      name: "table-border-settings",
    },
    {
      type: "table-font-family",
      label: "Font Family",
      name: "table-font-family",
    },
    {
      type: "table-line-height",
      label: "Line Height",
      name: "table-line-height",
    },
    {
      type: "table-width",
      label: "Width",
      name: "width",
    },
    {
      type: "column-padding",
      label: "Column Padding",
      name: "column-padding",
    },
    {
      type: "table-padding-control",
      label: "Container Padding",
      name: "table-padding",
    },
  ]);

  // Re-render the trait manager to show the new traits
  editor.TraitManager.render();
};

export const renderLogoBlockTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set("traits", []);
  // Add the info-link trait
  selectedComponent.addTrait({
    type: "info-link",
    label: false, // No label needed for this trait
    name: "info-link",
  });
  selectedComponent.addTrait({
    type: "element-float",
    label: "Position",
    name: "element-float",
  });
  editor.TraitManager.render();
};

//MenuBlock Trait Manager
export const renderMenuTraitManager = (selectedComponent, editor) => {
  // Clear existing traits
  selectedComponent.set("traits", []);

  // Get the 'td' element and its child 'a' elements
  const td = selectedComponent.getEl()?.querySelector("td");
  const links = td ? td.querySelectorAll("a") : [];

  selectedComponent.addTrait({
    type: "element-float",
    label: "Position",
    name: "element-float",
  });

  selectedComponent.addTrait({
    type: "trait-container",
    id: "sortable-menu", // ID for the container
  });
  // Create a trait for each link
  links.forEach((link, index) => {
    selectedComponent.addTrait({
      type: "link-manager",
      label: `Menu Item ${index + 1}`,
      name: `link-manager-${index}`,
      index: index,
      parent: "sortable-menu",
    });
  });

  // Add the 'add link' button trait
  selectedComponent.addTrait({
    type: "add-link-button",
    label: "Add Link",
    name: "add-link",
  });

  selectedComponent.addTrait({
    type: "enable-seperator",
    label: "Enable Seperator",
    name: "seperator",
  });
  selectedComponent.addTrait({
    type: "font-family",
    label: "Font Family",
    name: "font-family",
  });
  selectedComponent.addTrait({
    type: "line-height",
    label: "Line Height",
    name: "line-height",
  });
  selectedComponent.addTrait({
    type: "menu-link-padding",
    label: "Padding",
    name: "menu-link-padding",
  });
  selectedComponent.addTrait({
    type: "padding-control",
    label: "Container Padding",
    name: "padding",
  });

  initializeSorting(selectedComponent, editor, "sortable-menu");
};

//SocialBlock Trait Manager
export const renderSocialTraitManager = (selectedComponent, editor) => {
  //Clear existing traits
  selectedComponent.set("traits", []);

  //Find all 'img' elements
  const links = selectedComponent.find("a");

  selectedComponent.addTrait({
    type: "element-float",
    label: "Position",
    name: "element-float",
  });

  selectedComponent.addTrait({
    type: "add-imageLink",
    label: "Icons",
    name: "add-link",
  });

  selectedComponent.addTrait({
    type: "icon-style",
    text: "Style",
    name: "style",
  });

  selectedComponent.addTrait({
    type: "image-width",
    text: "Size",
    name: "size",
  });

  selectedComponent.addTrait({
    type: "image-link-gap",
    text: "Gap",
    name: "gap",
  });

  selectedComponent.addTrait({
    type: "trait-container",
    id: "sortable-div", // ID for the container
  });

  links.forEach((link, index) => {
    selectedComponent.addTrait({
      type: "imageLink-combined",
      text: "",
      name: `imageLink-${index}`,
      index: index,
      parent: "sortable-div",
    });
  });

  selectedComponent.addTrait({
    type: "padding-control",
    label: "Padding",
    name: "padding",
  });

  editor.TraitManager.render();
  // Initialize sorting
  initializeSorting(selectedComponent, editor, "sortable-div");
};

function initializeSorting(selectedComponent, editor, id) {
  // Use setTimeout to push the execution to the end of the call stack,
  // ensuring all elements have been added to the DOM.
  setTimeout(() => {
    const el = document.getElementById(id);
    if (el && !el.dataset.initialized) {
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          const { newIndex, oldIndex } = evt;
          if (newIndex !== oldIndex) {
            switch (id) {
              case "sortable-div":
                updateLinkOrder(selectedComponent, editor, oldIndex, newIndex);
                break;
              case "widths-container":
                updateColumnOrder(
                  selectedComponent,
                  editor,
                  oldIndex,
                  newIndex
                );
                break;
              case "sortable-menu":
                updateMenuOrder(selectedComponent, editor, oldIndex, newIndex);
                break;
              default:
                break;
            }
          }
        },
      });
      // Set a flag to indicate that sorting has been initialized
      el.dataset.initialized = "true";
    }
  }, 0);
}
function updateMenuOrder(selectedComponent, editor, oldIndex, newIndex) {
  // Access the 'td' that contains the 'a' link elements directly
  const tdComponent = selectedComponent.find("td")[0]; // Assuming links are direct children of the first 'td'

  if (tdComponent) {
    // Get the current array of 'a' link components
    let linkComponents = tdComponent.components().models;

    // Check if both oldIndex and newIndex are within the range of existing linkComponents
    if (oldIndex < linkComponents.length && newIndex < linkComponents.length) {
      // Create a new array with the moved 'a' component
      const movedLink = linkComponents.splice(oldIndex, 1)[0];
      linkComponents.splice(newIndex, 0, movedLink);

      // Reset the components array to the new order
      tdComponent.components().reset(linkComponents);

      // Re-render the component view to reflect changes
      tdComponent.view.render();

      // Optionally, update traits or trigger some other updates in the editor
      editor.TraitManager.render();
    } else {
      console.error("Invalid index for reordering links");
    }
  } else {
    console.error("TD container for links not found!");
  }
  selectedComponent.components().forEach((child) => {
    applyNonSelectability(child);
    removeNoPointerClass(child);
  });
  renderMenuTraitManager(selectedComponent, editor);
}

function updateLinkOrder(selectedComponent, editor, oldIndex, newIndex) {
  // Access the 'social-block' component directly
  const socialBlockComponent = selectedComponent.find(".social-block")[0];

  if (socialBlockComponent) {
    // Get the current array of link components
    let linksComponents = socialBlockComponent.components().models;

    // Save current styles and src of the links
    const linkStyles = linksComponents.map((linkComponent) => {
      const imgComponent = linkComponent.components().at(0);
      return {
        name: imgComponent.attributes.attributes.alt,
        src: imgComponent.attributes.attributes.src,
        borderRadius: imgComponent.view.el.style.borderRadius,
      };
    });

    console.log(linkStyles, "style when sorted");
    // Create a new array with the moved component
    const movedLink = linksComponents.splice(oldIndex, 1)[0];
    linksComponents.splice(newIndex, 0, movedLink);

    // Reset the components array to the new order
    socialBlockComponent.components().reset(linksComponents);

    // Reapply saved styles and src to each link
    linksComponents.forEach((linkComponent, index) => {
      const imgComponent = linkComponent.components().at(0);
      const originalStyle = linkStyles.find(
        (style) => style.name === imgComponent.attributes.attributes.alt
      );

      if (originalStyle) {
        imgComponent.attributes.src = originalStyle.src;
        imgComponent.addStyle({ borderRadius: originalStyle.borderRadius });
      }
      console.log(imgComponent, imgComponent.attributes);
    });
    // Re-render the component view to reflect changes
    socialBlockComponent.view.render();

    // Re-render traits to reflect the new order
    renderSocialTraitManager(selectedComponent, editor);
  } else {
    console.error("Social block component not found!");
  }
}

function updateColumnOrder(selectedComponent, editor, oldIndex, newIndex) {
  // Access the row that contains the 'td' elements directly
  const rowComponent = selectedComponent.find("tr")[0]; // Assuming there's only one row to simplify

  if (rowComponent) {
    // Get the current array of 'td' components
    let tdComponents = rowComponent.components().models;

    // Check if both oldIndex and newIndex are within the range of existing tdComponents
    if (oldIndex < tdComponents.length && newIndex < tdComponents.length) {
      // Create a new array with the moved 'td' component
      const movedTd = tdComponents.splice(oldIndex, 1)[0];
      tdComponents.splice(newIndex, 0, movedTd);

      // Reset the components array to the new order
      rowComponent.components().reset(tdComponents);

      // Re-render the component view to reflect changes
      rowComponent.view.render();

      // Optionally, update traits or trigger some other updates in the editor
      editor.TraitManager.render();
    } else {
      console.error("Invalid index for reordering");
    }
  } else {
    console.error("Row component not found!");
  }
  renderSectionTableTraitManager(selectedComponent, editor);
}

export function appendToDiv(trait, container) {
  setTimeout(() => {
    if (trait.get("parent")) {
      const parentEl = document.getElementById(trait.get("parent"));
      parentEl.appendChild(container);
    }
  }, 0);
}

export const onImageLinkInputChange = (editor, trait, value) => {
  const selectedComponent = editor.getSelected();
  const linkIndex = trait.get("index");
  const links = selectedComponent.find("a");

  if (links[linkIndex]) {
    links[linkIndex].addAttributes({ href: value });
    editor.trigger("component:update", selectedComponent);
  }
};

export const onDeleteImageLinkClick = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  const linkIndex = trait.get("index");
  const links = selectedComponent.find("a");

  if (links.length > 1 && links[linkIndex]) {
    links[linkIndex].remove();
    editor.trigger("component:update", selectedComponent);
  } else {
    alert("Cannot delete all the imageLinks");
  }

  renderSocialTraitManager(selectedComponent, editor);
};

export const addImageLinkToSocialBlock = (editor, social) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find(".social-block")[0] ||
    editor.getWrapper().find(".social-block")[0];
  if (socialBlock) {
    const existingLinks = socialBlock.find("a");
    if (
      existingLinks.some(
        (link) => link.find(`img[alt="${social.name}"]`).length > 0
      )
    ) {
      alert(`${social.name} link already exists in the social block.`);
      return;
    }

    const currentStyle = window.currentIconStyle || "circle";
    console.log(currentStyle, "when adding link to social block");
    const useBlackVersion = currentStyle?.includes("black");

    // Default properties if no existing links are found
    let width = "24px"; // default width
    let gap = "4px"; // default gap
    let borRad = "50%"; // default border-radius

    // Check if there are any existing links to copy styles from
    if (existingLinks.length > 0) {
      const existingLink = existingLinks[0];
      const existingImg = existingLink.find("img")[0];

      if (existingImg) {
        width = existingImg.getStyle("width") || width;
        borRad = existingImg.getStyle("border-radius") || borRad;
      }

      gap = existingLink.getStyle("margin-right") || gap; // Assuming margin-left and margin-right are the same
    }

    // Determine the icon source based on style
    const iconSrc = useBlackVersion ? social.iconDark : social.icon;

    // Define the new link component with an image inside
    const linkComponent = {
      type: "link",
      tagName: "a",
      attributes: { href: social.url, target: "_blank" },
      style: { "margin-left": gap, "margin-right": gap },
      components: [
        {
          type: "image",
          tagName: "img",
          attributes: { src: iconSrc, alt: social.name },
          style: { "border-radius": borRad, width: width },
        },
      ],
    };

    // Append the new link component to the social block
    socialBlock.append(linkComponent);
    editor.trigger("component:update", selectedComponent);
    renderSocialTraitManager(selectedComponent, editor);
  }
};

export const updateSocialImagesWidth = (editor, width) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find(".social-block")[0] ||
    editor.getWrapper().find(".social-block")[0];
  if (socialBlock) {
    const images = socialBlock.find("img");
    images.forEach((img) => {
      img.addStyle({ width: `${width}px` });
    });
  }
};

export const updateImageLinkGap = (editor, gap) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find(".social-block")[0] ||
    editor.getWrapper().find(".social-block")[0];
  if (socialBlock) {
    const links = socialBlock.find("a");
    links.forEach((link) => {
      link.addStyle({
        "margin-left": `${gap}px`,
        "margin-right": `${gap}px`,
      });
    });
  }
};

export const updateIconStyle = (editor, style) => {
  const selectedComponent = editor.getSelected();
  const socialBlock =
    selectedComponent.find(".social-block")[0] ||
    editor.getWrapper().find(".social-block")[0];
  if (socialBlock) {
    const links = socialBlock.find("img");
    const useBlackVersion = style.includes("black");
    links.forEach((link) => {
      // Reset styles
      link.addStyle({
        "border-radius": "",
        "background-color": "",
        padding: "",
      });

      const socialTypeName = link?.getAttributes()["alt"];
      const socialType = socialTypes.find(
        (social) => social.name === socialTypeName
      );

      if (socialType) {
        // Set the source based on the selected style
        const newSrc = useBlackVersion ? socialType.iconDark : socialType.icon;
        link.setAttributes({ src: newSrc, alt: socialTypeName, width: "24px" });
      }
      switch (style) {
        case "circle":
        case "circle-black":
          link.addStyle({ "border-radius": "50%" });
          break;
        case "round":
        case "round-black":
          link.addStyle({ "border-radius": "4px" });
          break;
        case "square":
        case "square-black":
          link.addStyle({ "border-radius": "0" });
          break;
        default:
          break;
      }
    });
  }
  window.currentIconStyle = style;
  renderSocialTraitManager(selectedComponent, editor);
};

//FooterBlock Trait Manager
export const renderFooterBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "font-family",
      label: "Font Family",
      name: "font-family",
    },
    {
      type: "line-height",
      label: "Line Height",
      name: "line-height",
    },
    {
      type: "padding-control",
      label: "Padding",
      name: "padding",
    },
  ]);
};

//HTMLBlock Trait Manager
export const renderHTMLBlockTraitManager = (selectedComponent, editor) => {
  selectedComponent.set("traits", [
    {
      type: "htmlTrait",
      label: "HTML",
      name: "html",
      changeProp: 1,
      default: "<strong>Hello World</strong>",
    },
    {
      type: "padding-control",
      label: "Padding",
      name: "padding",
    },
  ]);

  editor.TraitManager.render();
};

//Menu Trait Functions
// Function to update link details
export function updateLinkDetails(
  selectedComponent,
  linkIndex,
  type,
  input,
  subject,
  content,
  editor
) {
  const links = selectedComponent.find("a");
  if (links.length > linkIndex) {
    const link = links.at(linkIndex);
    let href = "";
    let error = null;

    switch (type) {
      case "web":
        const urlPattern = /^(http:\/\/|https:\/\/)?(.*)$/i;
        const match = input.match(urlPattern);
        input = match[2];
        href = `https://${input}`;
        break;
      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(input)) {
          error = "Please enter a valid email address.";
        } else {
          href = `mailto:${input}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(content)}`;
        }
        break;
      case "phone":
        input = input.replace(/[^0-9]/g, "");
        if (input.length > 10) {
          error = "Phone number must be up to 10 digits.";
          input = input.substring(0, 10);
        }
        href = `tel:${input}`;
        break;
      default:
        href = input;
        break;
    }

    if (error) {
      console.error(error);
      return;
    }

    link.addAttributes({ href });
    editor.trigger("component:update", selectedComponent);
  }
}

export const addLinkToMenu = (selectedComponent, editor) => {
  const tdComponent = selectedComponent.find("td")[0];

  if (tdComponent) {
    tdComponent.components().add({
      type: "link", // Use 'link' as the component type for anchor tags
      tagName: "a",
      hoverable: false,
      selectable: false,
      editable: false,
      droppable: false,
      attributes: { href: "", target: "_blank" },
      components: [
        {
          tagName: "span",
          type: "text",
          content: "New Link",
          droppable: false,
          hoverable: false,
          selectable: false,
          editable: true,
          style: {
            display: "inline-block",
            padding: "8px 16px",
            "font-size": "14px",
            "font-weight": "600",
            "text-decoration": "none",
            color: "#000000",
          },
        },
      ],
    });
  }

  selectedComponent.components().forEach((child) => {
    applyNonSelectability(child);
    removeNoPointerClass(child);
  });

  renderMenuTraitManager(selectedComponent, editor);
};

export const deleteLinkFromMenu = (editor, trait) => {
  const selectedComponent = editor.getSelected();
  const td = selectedComponent.find("td")[0];
  const links = td.find("a");
  if (links.length > 1) {
    const linkIndex = trait.get("index");
    const linkToDelete = links.at(linkIndex);
    if (linkToDelete) {
      linkToDelete.remove();
      renderMenuTraitManager(selectedComponent, editor); // Re-render traits
    }
  } else {
    alert("There should be atleast 1 link");
  }
};

//Table Trait Functions
export const addRowTableTop = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to add rows");
  }

  if (selectedComponent.attributes.tagName === "td") {
    const parentRow = selectedComponent.parent();
    const tableBody = parentRow.parent();
    const rowIndex = tableBody.components().indexOf(parentRow);
    const newRow = parentRow.clone();

    // Clear content of the new row's cells
    newRow.components().forEach((cell) => {
      cell.set("content", "New Cell");
      cell.components().reset();
    });

    // Add the new row at the correct index
    tableBody.components().add(newRow, { at: rowIndex });

    editor.trigger("component:update", tableBody);
  }
};

export const addRowTableBottom = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to add rows");
  }

  if (selectedComponent.attributes.tagName === "td") {
    const parentRow = selectedComponent.parent();
    const tableBody = parentRow.parent();
    const rowIndex = tableBody.components().indexOf(parentRow);
    const newRow = parentRow.clone();

    // Clear content of the new row's cells
    newRow.components().forEach((cell) => {
      cell.set("content", "New Cell");
      cell.components().reset();
    });

    // Add the new row at the correct index
    tableBody.components().add(newRow, { at: rowIndex + 1 });

    editor.trigger("component:update", tableBody);
  }
};

export const addColTableLeft = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to add columne");
    return;
  }
  if (selectedComponent.attributes.tagName === "td") {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = currentCell.parent();

    // Get the table body by accessing the parent of the row
    const tableBody = parentRow.parent();

    // Find the index of the current cell within the row
    const cellIndex = parentRow.components().indexOf(currentCell);

    // Get the first row's th cell
    const firstRow = tableBody.components().at(0);
    const thCell = firstRow
      .components()
      .find((cell) => cell.attributes.tagName === "th");

    // Iterate over each row to add a new cell at the correct index
    tableBody.components().forEach((row, idx) => {
      let newCell;
      if (idx === 0 && thCell) {
        newCell = thCell.clone();
      } else {
        newCell = currentCell.clone();
      }
      newCell.set("content", "New Cell");
      newCell.components().reset();
      row.components().add(newCell, { at: cellIndex });
    });

    // Update the view to reflect the changes
    editor.trigger("component:update", tableBody);
  }
};

export const addColTableRight = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to add columns");
    return;
  }
  if (selectedComponent.attributes.tagName === "td") {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = currentCell.parent();

    // Get the table body by accessing the parent of the row
    const tableBody = parentRow.parent();

    // Find the index of the current cell within the row
    const cellIndex = parentRow.components().indexOf(currentCell);

    // Get the first row's th cell
    const firstRow = tableBody.components().at(0);
    const thCell = firstRow
      .components()
      .find((cell) => cell.attributes.tagName === "th");

    // Iterate over each row to add a new cell at the correct index
    tableBody.components().forEach((row, idx) => {
      let newCell;
      if (idx === 0 && thCell) {
        // Clone the th cell for the first row
        newCell = thCell.clone();
      } else {
        // Clone the selected cell for other rows
        newCell = currentCell.clone();
      }
      newCell.set("content", "New Cell");
      newCell.components().reset();
      row.components().add(newCell, { at: cellIndex + 1 });
    });

    // Update the view to reflect the changes
    editor.trigger("component:update", tableBody);
  }
};

export const deleteTableColumn = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to delete its column");
    return;
  }

  if (selectedComponent.attributes.tagName === "td") {
    // Get the current cell
    const currentCell = selectedComponent;

    // Get the index of the current cell in its parent row
    const cellIndex = currentCell.parent()?.components().indexOf(currentCell);

    // Get the table body by going up two levels in the hierarchy
    const tableBody = currentCell.parent().parent();

    // Iterate over each row and remove the cell at the same index
    tableBody?.components().forEach((row) => {
      const cell = row.components().at(cellIndex);
      if (cell) {
        cell.remove();
      }
    });

    // Update the view to reflect the changes
    editor.trigger("component:update", tableBody);
  }
};

export const deleteTableRow = (selectedComponent, editor) => {
  if (selectedComponent.components().at(0)?.attributes.tagName === "table") {
    alert("Select a cell to delete");
    return;
  }
  if (selectedComponent.attributes.tagName === "td") {
    // Get the current cell and its parent row
    const currentCell = selectedComponent;
    const parentRow = selectedComponent.parent();

    if (parentRow) {
      parentRow.remove();
    }
    // Update the view to reflect the changes
    editor.trigger("component:update");
  }
};

// utils.js

const removeNoPointerClass = (component) => {
  const el = component.getEl();
  if (el && el.classList?.contains("gjs-no-pointer")) {
    el.classList.remove("gjs-no-pointer");
  }
  component.components().forEach((child) => {
    removeNoPointerClass(child);
  });
};

export const applyNonSelectability = (component) => {
  // Define the selectability conditions
  const isSelectable = (comp) => {
    const el = comp.getEl();

    if (!el) {
      return false; // Return false if el is null or undefined
    }

    // Condition 1: Body (div with data-gjs-type="wrapper")
    if (el.dataset && el.dataset.gjsType === "wrapper") {
      return true;
    }

    // Condition 2: Section (table with type="section-table" and class="content-table")
    if (
      el.tagName === "TABLE" &&
      el.getAttribute("type") === "section-table" &&
      el.classList.contains("content-table")
    ) {
      return true;
    }

    // Condition 3: Column (td with type="column" and class="container")
    if (
      el.tagName === "TD" &&
      el.getAttribute("type") === "column" &&
      el.classList.contains("container")
    ) {
      return true;
    }

    // Condition 4: All blocks (span with class="block-div")
    if (el.tagName === "SPAN" && el.classList.contains("block-div")) {
      return true;
    }

    // Condition 5: If span with class="block-div" has data-gjs-type="Text" or "Footer", then its first child (div with class="text-block" or "footer-block")
    if (
      el.dataset &&
      (el.dataset.gjsType === "Text" || el.dataset.gjsType === "Footer") &&
      el.firstChild
    ) {
      if (
        el.firstChild.classList.contains("text-block") ||
        el.firstChild.classList.contains("footer-block")
      ) {
        return true;
      }
    }

    return false;
  };

  // Apply non-selectability if the component doesn't meet the selectability conditions

  if (component?.parent()?.attributes.type === "Table") {
    component.find("th, td").forEach((cell) => {
      cell.addAttributes({ "data-gjs-type": "text" });
      cell.removeAttributes("type");
      cell.set({
        selectable: true,
        hoverable: true,
        editable: true,
      });

      // Ensure RTE settings are applied
      cell.components().forEach((child) => {
        child.set({
          editable: true,
        });
      });
    });
    return;
  } else {
    if (!isSelectable(component)) {
      component.addAttributes({ "data-non-selectable": "true" });
      component.addClass("non-selectable");
      component.set("selectable", false);
      component.set("hoverable", false);
    }
    component.components().forEach((child) => {
      applyNonSelectability(child);
      removeNoPointerClass(child);
    });
  }
};
