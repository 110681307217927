import React, { useEffect, useState } from "react";
import MyTemplateHeader from "./components/MyTemplateHeader.js";
import MyTemplateEmpty from "./components/MyTemplateEmpty";
import MyTemplateListing from "./components/MyTemplateListing";
import { template } from "../../../api/templateapi.js";
import DeleteTemplateDialog from "./components/DeleteTemplateDialog.js";
import { useSelector } from "react-redux";
import DuplicateTemplateLoader from "./components/DuplicateTemplateLoader.js";
import RenameTemplateDialog from "./components/RenameTemplateDialog.js";
import { useTemplates } from "../../../hooks/useTemplates.js";
import Progress from "../../../components/Progress/Progess.jsx";

const MyTemplate = () => {
  
  const isDeleteTemplateDialog = useSelector(
    (state) => state.isDeleteTemplateDialog
  );

  const isDuplicateTemplateLoader = useSelector(
    (state) => state.isDuplicateTemplateLoader
  )

  const isRenameTemplateDialog = useSelector(state => state.isRenameTemplateDialog);

  const {fetchTemplates, loading, templateData} = useTemplates();

  useEffect(() => {
    fetchTemplates();
  },[fetchTemplates]);

  return (
    <>
      {isDeleteTemplateDialog ? <DeleteTemplateDialog /> : ""}
      {isDuplicateTemplateLoader ? <DuplicateTemplateLoader/> : ""}
      {isRenameTemplateDialog ? <RenameTemplateDialog /> : ""} 
      <MyTemplateHeader />
      {templateData.length !== null && templateData.length > 0 ? (
        <div className="main-content-container">
          {loading ? <Progress/> : <MyTemplateListing />}
        </div>
      ) : (
        templateData.length !== null && (loading ? <Progress/> : <MyTemplateEmpty />)
      )}
    </>
  );
};

export default MyTemplate;
