import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  CircularProgress
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTemplateId,
  setCurrentTemplateName,
  toggleCreateTemplateDialog,
  toggleCreateTemplateLoader,
} from "../../../../store/store.js";
import iconClose from "../../../../assets/icons/icon-cross.svg";
import { useNavigate } from "react-router-dom";
import { template } from "../../../../api/templateapi.js";

const CreateEmailDialog = () => {
  const [templateName, setTemplateName] = useState("");

  const isCreateTemplateLoader = useSelector((state) => state.isCreateTemplateLoader);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const history = useHistory();
  const isCreateTemplateDialog = useSelector(
    (state) => state.isCreateTemplateDialog
  );

  const closeCreateEmailDialog = () => {
    dispatch(toggleCreateTemplateDialog());
  };

  const createTemplate = async (e) => {
    if (!templateName) {
      return alert("Please enter a name for the template");
    }
    e.preventDefault();
    try {
      dispatch(toggleCreateTemplateLoader());
      const response = await template.createTemplate({
        name: templateName,
        htmlContent: `
            <table class='content-table' align="center" style="width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table">
              <tbody>
                <tr>
                  <td style="width:100%;" class='container-td container' type="column">
                  </td>
                </tr>
              </tbody>
            </table>
            <table class='content-table' align="center" style="width: 100%; max-width: 640px; border-spacing: 16px; border-collapse: separate; background-color: white;" type="section-table">
              <tbody>
                <tr style="height: 100%">
                  <td style=" width: 50%;" class='container-td container' type="column">
                    
                  </td>
                  <td style=" width: 50%;" class='container-td container' type="column">
                    
                  </td>
                </tr>
              </tbody>
            </table> 
        
    `,
        metadata: {
          tags: "[]",
          css: "[]",
        },
      });
      navigate(`/email-builder/${response.data.name}/${response.data.id}`);
      dispatch(setCurrentTemplateId(response.data.id));
      dispatch(setCurrentTemplateName(response.data.name));
    } catch (error) {
      navigate("/templates/prebuilt-templates");
    } finally {
      dispatch(toggleCreateTemplateLoader());
      closeCreateEmailDialog();
    }
  };

  return (
    <div className="email-dialog">
      <Dialog open={isCreateTemplateDialog} onClose={closeCreateEmailDialog}>
        <div className="email-dialog-header">
          <DialogTitle>Create Email Template</DialogTitle>
          <Button
            onClick={closeCreateEmailDialog}
            className="email-dialog-close-btn"
          >
            <img src={iconClose} />
          </Button>
        </div>
        <div className="email-dialog-body">
          <div className="input-container">
            <label className="email-dialog-label">Name</label>
            <input
              type="text"
              placeHolder="Type here..."
              className="email-dialog-input"
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={closeCreateEmailDialog}
            className="btn btn-outline dark-border"
          >
            Cancel
          </Button>
          <Button
            onClick={createTemplate}
            variant="contained"
            className="btn btn-primary ml16"
          >
            {isCreateTemplateLoader ? <CircularProgress className="loader-btn" /> : " "}
            <span style={{visibility: isCreateTemplateLoader ? "hidden" : "visible"}}>Create</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateEmailDialog;
