import React from "react";

const Analytics = () => {
  return (
    <div>
      <div className="header">
        <div className="header-left">
          <span className="template-name"> Analytics </span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div>Analytics</div>
    </div>
  );
};

export default Analytics;
