import React, { useState, useEffect } from "react";
import ColorPicker from "../ColorPicker/ColorPicker";
// import infoIcon from "../../assets/icons/icon-info.svg";
import infoIcon from "../../../../assets/icons/icon-info.svg";
import leftIcon from "../../../../assets/icons/icon-left.svg";
import centerIcon from "../../../../assets/icons/icon-center.svg";
import rightIcon from "../../../../assets/icons/icon-right.svg";
import backIcon from "../../../../assets/icons/icon-arrow-brand.svg";
import { useDispatch, useSelector } from "react-redux";
import PropertyPopup from "./PropertyPopup";
import { Tooltip } from "@mui/material";
//
import Overlay from "../BrandStyle/Overlay.jsx";
import { toggleBrandStyle } from "../../../../store/store.js";

const BlockProperties = ({ editor }) => {
  const propertyPopup = useSelector((state) => state.propertyPopup);
  const dispatch = useDispatch();
  const brandColors = useSelector((state) => state.brandColors);
  const [tableWidth, setTableWidth] = useState(640);
  const [bodyBgColor, setBodyBgColor] = useState("#f7f8fa");

  useEffect(() => {
    if (editor) {
      // Find the first content-table element in the editor
      const contentTable = editor.getWrapper().find(".content-table")[0];
      if (contentTable) {
        // Get the computed style of the element
        const computedStyle = window.getComputedStyle(contentTable.getEl());
        const currentMaxWidth = computedStyle.getPropertyValue("max-width");
        setTableWidth(currentMaxWidth.replace("px", ""));

        const alignment = contentTable.attributes.attributes.align;
        const btns = document.querySelectorAll(".alignment-btn");
        btns.forEach((btn) => {
          if (btn.classList.contains("alignment-selected")) {
            btn.classList.remove("alignment-selected");
          }
        });
        if(alignment === 'left'){
          btns[0].classList.add("alignment-selected");
        }else if(alignment === 'center'){
          btns[1].classList.add("alignment-selected");          
        }else{
          btns[2].classList.add("alignment-selected");
        }

        // Retrieve the background color from the wrapper
        const wrapperBgColor = editor.getWrapper().getStyle().backgroundColor;
        setBodyBgColor(wrapperBgColor || "#f7f8fa");
      }
    }
  });

  //overlay
  const isBrandStyle = useSelector((state) => state.isBrandStyle);

  const handleMaxWidthChange = (event) => {
    setTableWidth(event.target.value);
    const maxWidth = event.target.value + "px";
    editor
      .getWrapper()
      .find(".content-table")
      .forEach((tableComponent) => {
        tableComponent.addStyle({ "max-width": maxWidth });
      });
  };

  //overlay handler
  const handlePreview = () => {
    dispatch(toggleBrandStyle());
  };

  function setBodyBackgroundColor(color) {
    setBodyBgColor(color);
    editor.getWrapper().setStyle({ background: color });
  }

  function alignContent(position) {
    editor
      .getWrapper()
      .find(".content-table")
      .forEach((tableComponent) => {
        tableComponent.addAttributes({ align: position });
      });

    const btns = document.querySelectorAll(".alignment-btn");
    btns.forEach((btn) => {
      if (btn.classList.contains("alignment-selected")) {
        btn.classList.remove("alignment-selected");
      }
    });
    if (position === "left") {
      btns[0].classList.add("alignment-selected");
    } else if (position === "center") {
      btns[1].classList.add("alignment-selected");
    } else {
      btns[2].classList.add("alignment-selected");
    }
  }

  return (
    <>
      <div className="right-side-block">
        <div
          className="property-popup"
          style={{ display: propertyPopup ? "none" : "block" }}
        >
          <div className="property-popup-header">
            <span className="property-header-key"> Template Settings </span>
            <a className="property-header-value" onClick={handlePreview}>
              Brand Styles <img src={backIcon} height="16px" width="16px" />
            </a>
          </div>
          <div className="properties-values">
            <div className="key-value">
              <span className="key"> Desktop Width </span>
              <input
                min="600"
                max="960"
                className="width-input"
                type="number"
                value={tableWidth}
                onChange={handleMaxWidthChange}
              />
            </div>
            <div className="key-value">
              <span className="key"> Body Background </span>
              <ColorPicker
                initialColor={bodyBgColor}
                onChange={(color) => setBodyBackgroundColor(color)}
                brandColors={brandColors}
              />
            </div>
            <div className="key-value">
              <span className="key">
                Content Position
                <Tooltip title="Alignment of the email content area">
                  <img
                    src={infoIcon}
                    alt="Content Position info button"
                    height="16px"
                    width="16px"
                  />
                </Tooltip>
              </span>
              <div className="alignments">
                <button
                  className="alignment-btn"
                  onClick={() => alignContent("left")}
                >
                  <img src={leftIcon} alt="Align Content Left Button" />
                </button>
                <button
                  className="alignment-btn"
                  onClick={() => alignContent("center")}
                >
                  <img src={centerIcon} alt="Align Content Center Button" />
                </button>
                <button
                  className="alignment-btn"
                  onClick={() => alignContent("right")}
                >
                  <img src={rightIcon} alt="Align Content Right Button" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <PropertyPopup editor={editor} />
      </div>
      <Overlay isOpen={isBrandStyle} />
    </>
  );
};

export default BlockProperties;
