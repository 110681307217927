import { apiRequest } from "./http";

export const template = {
    createTemplate: ( requestBody ) => {
        return apiRequest('POST', "/templates" ,requestBody)
    },
    getTemplate: () => {
        return apiRequest('GET', "/templates");
    },
    getTemplateById: (id) => {
        return apiRequest('GET', `/templates/${id}`);
    },
    getPrebuiltTemplate : () => {
        return apiRequest('GET', "/templates/preBuiltTemplates");
    },
    deleteTemplate: (requestBody) => {
        return apiRequest('DELETE', "/templates", requestBody);
    },
    cloneTemplate: (requestBody) => {
        return apiRequest('POST', "/templates/clone", requestBody);
    },
    updateTemplate: (id, requestBody) => {
        return apiRequest('PUT', `/templates/${id}`, requestBody);
    },
    createLayout: (requestBody) => {
        return apiRequest('POST', "/templates", requestBody);
    },
    getLayout: () => {
        return apiRequest('GET', `/templates?type=layout`);
    },
    getPrebuiltLayout: () => {
        return apiRequest('GET', "/templates/preBuiltTemplates");
    },
    sendTestEmail: (requestBody) => {
        return apiRequest('POST', '/templates/send/mail', requestBody);
    },
};

