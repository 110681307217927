import React from "react";
import { Dialog, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const DuplicateTemplateLoader = () => {
  const isDuplicateTemplateLoader = useSelector(
    (state) => state.isDuplicateTemplateLoader
  );

  return (
    <div className="email-dialog">
      <Dialog open={isDuplicateTemplateLoader}>
        <div className="email-dialog-body">
          <CircularProgress className="loader-btn" />
          <label className="email-dialog-label">
            Duplicating the template...
          </label>
        </div>
      </Dialog>
    </div>
  );
};

export default DuplicateTemplateLoader;
