import React, { useState } from "react";

const LinkInputTrait = ({
  htmlContent,
  updateHtmlContent,
  brandLogoLink,
  setBrandLogoLink,
}) => {
  const [linkValue, setLinkValue] = useState(brandLogoLink || "");

  const handleInputChange = (event) => {
    const url = event.target.value.trim();
    setLinkValue(url);
    setBrandLogoLink(url);

    const htmlDoc = parseHtml(htmlContent);

    // Update the href attribute of the <a> tag wrapping the <img> element
    const anchorTag = htmlDoc.querySelector('.image-block').closest('a');
    if (anchorTag) {
      anchorTag.setAttribute('href', url);
    }

    updateHtmlContent(serializeHtml(htmlDoc));
  };

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, "text/html");
  };

  const serializeHtml = (doc) => {
    return new XMLSerializer().serializeToString(doc);
  };

  return (
    <div className="global-trait vertical link-input-trait">
      <label>Link</label>
      <input
        type="text"
        placeholder="http://"
        className="input-link input-trait"
        style={{ width: "100%", boxSizing: "border-box" }}
        value={linkValue}
        onInput={handleInputChange}
      />
    </div>
  );
};

export default LinkInputTrait;
