import GeneralDrawer from '../../../../components/Drawer/Drawer';
import React from 'react';
import {Button} from '@mui/material';
import {Box} from '@mui/material';
import SortableList from './SortableList';

const MyDrawer = ({isOpen, handleDrawerClose}) => {
 return (
  <>
   <GeneralDrawer
    heading="Edit Displayed columns"
    width="480px"
    isOpen={isOpen}
    handleDrawerClose={handleDrawerClose}
   >
    <Box sx={{padding: '16px 24px'}}>
     <SortableList />
    </Box>
   </GeneralDrawer>
  </>
 );
};

export default MyDrawer;
