import React, { useState } from "react";
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CustomAutocomplete from "../../components/AutoComplete";

const Dashboard = () => {
  const [autoCompleteValue, setAutoCompleteValue] = useState([])
  return (
    <Stack sx={{height: '100%'}} alignItems='center' justifyContent='center'>
      <h1>
        Auto Complete Demo
      </h1>
      <Box sx={{width: '408px'}}>
        <CustomAutocomplete 
          label='Tags'
          placeholder='Search to select'
          fetchUrl='/tag/search'
          createUrl='/tag/'
          onChange={setAutoCompleteValue}
          autoCompleteValue={autoCompleteValue}
        />
      </Box>
    </Stack>
  );
};

export default Dashboard;
