// BrandLogoTraitManager.jsx
import React, { useState, useEffect } from "react";
import PaddingControlTrait from "../TraitFunctions/PaddingControl";
import BorderRadiusControlTrait from "../TraitFunctions/BorderRadiusControl";
import AssetManagerTrait from "../TraitFunctions/AssetManager";
import LinkInputTrait from "../TraitFunctions/LinkInput";
import WidthControlTrait from "../TraitFunctions/FitToContainer";

const BrandLogoTraitManager = ({
  brandLogo,
  setBrandLogo,
  brandLogoLink,
  setBrandLogoLink,
  brandLogoWidth,
  setBrandLogoWidth,
  brandLogoPadding,
  setBrandLogoPadding,
  brandLogoBorderRadius,
  setBrandLogoBorderRadius,
  onChange,
  onParentChange,
  htmlContent,
  updateHtmlContent,
}) => {
  const [padding, setPadding] = useState({
    top:
      parseInt(brandLogoPadding?.split(" ")[0]?.trim().replace("px", ""), 10) ||
      0,
    right:
      parseInt(brandLogoPadding?.split(" ")[1]?.trim().replace("px", ""), 10) ||
      0,
    bottom:
      parseInt(brandLogoPadding?.split(" ")[2]?.trim().replace("px", ""), 10) ||
      0,
    left:
      parseInt(brandLogoPadding?.split(" ")[3]?.trim().replace("px", ""), 10) ||
      0,
  });

  const [borderRadius, setBorderRadius] = useState({
    "top-left":
      parseInt(
        brandLogoBorderRadius?.split(" ")[0]?.trim().replace("px", ""),
        10
      ) || 0,
    "top-right":
      parseInt(
        brandLogoBorderRadius?.split(" ")[1]?.trim().replace("px", ""),
        10
      ) || 0,
    "bottom-right":
      parseInt(
        brandLogoBorderRadius?.split(" ")[2]?.trim().replace("px", ""),
        10
      ) || 0,
    "bottom-left":
      parseInt(
        brandLogoBorderRadius?.split(" ")[3]?.trim().replace("px", ""),
        10
      ) || 0,
  });

  const [isFitToContainer, setIsFitToContainer] = useState(
    brandLogoWidth === "auto"
  );
  const [customWidth, setCustomWidth] = useState(
    brandLogoWidth || "30"
  );

  const handlePaddingChange = (newPadding) => {
    setPadding(newPadding);
    const paddingString = `${newPadding.top}px ${newPadding.right}px ${newPadding.bottom}px ${newPadding.left}px`;
    setBrandLogoPadding(paddingString);
    onParentChange({ padding: paddingString });
  };

  const handleBorderRadiusChange = (newBorderRadius) => {
    setBorderRadius(newBorderRadius);
    const radiusString = `${newBorderRadius["top-left"]}px ${newBorderRadius["top-right"]}px ${newBorderRadius["bottom-right"]}px ${newBorderRadius["bottom-left"]}px`;
    setBrandLogoBorderRadius(radiusString);
    onChange({ borderRadius: radiusString });
  };

  const handleWidthToggleChange = (newValue) => {
    setIsFitToContainer(newValue);
    const widthValue = newValue ? "auto" : `${customWidth}%`;
    setBrandLogoWidth(widthValue);
    onChange({ width: widthValue });
  };

  const handleWidthChange = (newWidth) => {
    setCustomWidth(newWidth);
    const widthValue = `${newWidth}%`;
    setBrandLogoWidth(newWidth);
    onChange({ width: widthValue });
  };

  useEffect(() => {
    if (isFitToContainer) {
      onChange({ width: "auto" });
    } else {
      onChange({ width: `${customWidth}%` });
    }
  }, [isFitToContainer, customWidth]);

  return (
    <div>
      <AssetManagerTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        brandLogo={brandLogo}
        setBrandLogo={setBrandLogo}
      />
      <LinkInputTrait
        htmlContent={htmlContent}
        updateHtmlContent={updateHtmlContent}
        brandLogoLink={brandLogoLink}
        setBrandLogoLink={setBrandLogoLink}
      />
      <WidthControlTrait
        label="Auto Width"
        isFitToContainer={isFitToContainer}
        customWidth={customWidth}
        onToggleChange={handleWidthToggleChange}
        onWidthChange={handleWidthChange}
      />
      <PaddingControlTrait value={padding} onChange={handlePaddingChange} />
      <BorderRadiusControlTrait
        value={borderRadius}
        onChange={handleBorderRadiusChange}
      />
    </div>
  );
};

export default BrandLogoTraitManager;
