// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
 width: 100%;
 height: 764px !important;
}

.Tab-container {
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 100%;
 min-height: 475.6px;

 .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: isEmpty ? 'center' : 'flex-start'; */
  width: 100%;
  flex-grow: 1;
  .noData {
   margin-top: 124px !important;
  }
 }
}
.notes-container {
 padding: 8px 0px 8px 0px;

 gap: 36px;
 opacity: 0px;
 box-shadow: 0px 1px 0px 0px #0000001a;
 margin-bottom: 8px;
}

.task-text {
 margin: 0;
 font-size: 14px;
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.task-meta {
 font-size: 12px;
 color: #717171;
 font-weight: 400;
 align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tab/Tab.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,wBAAwB;AACzB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,WAAW;CACX,mBAAmB;;CAEnB;EACC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wDAAwD;EACxD,WAAW;EACX,YAAY;EACZ;GACC,4BAA4B;EAC7B;CACD;AACD;AACA;CACC,wBAAwB;;CAExB,SAAS;CACT,YAAY;CACZ,qCAAqC;CACrC,kBAAkB;AACnB;;AAEA;CACC,SAAS;CACT,eAAe;CACf,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,cAAc;CACd,gBAAgB;CAChB,mBAAmB;AACpB","sourcesContent":[".panel {\n width: 100%;\n height: 764px !important;\n}\n\n.Tab-container {\n display: flex;\n flex-direction: column;\n align-items: center;\n width: 100%;\n min-height: 475.6px;\n\n .container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  /* justify-content: isEmpty ? 'center' : 'flex-start'; */\n  width: 100%;\n  flex-grow: 1;\n  .noData {\n   margin-top: 124px !important;\n  }\n }\n}\n.notes-container {\n padding: 8px 0px 8px 0px;\n\n gap: 36px;\n opacity: 0px;\n box-shadow: 0px 1px 0px 0px #0000001a;\n margin-bottom: 8px;\n}\n\n.task-text {\n margin: 0;\n font-size: 14px;\n display: flex;\n justify-content: space-between;\n align-items: center;\n}\n\n.task-meta {\n font-size: 12px;\n color: #717171;\n font-weight: 400;\n align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
