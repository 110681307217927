import React from "react";
import appIcon from "../assets/icons/app-icon.svg";
import { useNavigate } from "react-router-dom";
import verificationIcon from "../assets/icons/icon-comparison-tick.svg";

const VerificationSuccess = () => {
  const navigate = useNavigate();

  const openLogin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="header auth">
        <div className="header-left">
          <img className="app-icon" src={appIcon} />
          <span className="template-name heading">adflipr</span>
        </div>
        <div className="header-center"></div>

        <div className="header-right"></div>
      </div>
      <div className="verification-container">
        <img className="verification-icon" src={verificationIcon} />
        <h1 className="verification-heading">Successfully Verified</h1>
        <p className="verification-content">
        Your account has been successfully verified. Please log in to your account</p>
        <a className="verification-link" onClick={openLogin}>
          Login
        </a>
      </div>
    </>
  );
};

export default VerificationSuccess;
