import {TextField} from '@mui/material';
import React, {useState} from 'react';
import styles from './FormRenderer.module.scss';
import classNames from 'classnames';
import CustomAutocomplete from '../AutoComplete';

const FormRenderer = ({fields = [], register, errors, watch}) => {
 return (
  <form>
   {fields.map(field => {
    const {type, name} = field;
    switch (type) {
     case 'text':
      return <Input {...field} register={register} error={errors[name]} />;
     case 'textarea':
      return <TextArea {...field} register={register} error={errors[name]} />;
     case 'dropdown':
      return (
       <Dropdown
        key={field.name}
        {...field}
        watch={watch}
        register={register}
        error={errors[name]}
       />
      );
     case 'checkbox':
      return <Checkbox {...field} register={register} error={errors[name]} />;
     case 'radiobutton':
      return (
       <RadioButton {...field} register={register} error={errors[name]} />
      );
     default:
      return <input {...field} register={register} error={errors[name]} />;
    }
   })}
  </form>
 );
};

const Input = ({
 label,
 name,
 placeHolder,
 value,
 description,
 register,
 validations,
 error
}) => {
 const id = `form-field-${name}`;

 return (
  <div
   key={name}
   className={classNames('input-container', styles.inputContainer)}
  >
   <label htmlFor={id} className="email-dialog-label">
    {label}
   </label>
   <input
    type="text"
    id={id}
    name={name}
    value={value}
    placeHolder={placeHolder}
    className={classNames('email-dialog-input', {
     'box-error': error
    })}
    {...register(name, validations)}
   />
   {description && <div className="input-description">{description}</div>}
   {error && <div className="error-msg">{error.message}</div>}
  </div>
 );
};

const TextArea = ({label, name, placeHolder, register}) => {
 const id = `form-field-${name}`;
 return (
  <div
   key={name}
   className={classNames('input-container', styles.inputContainer)}
  >
   <label htmlFor={id} className="email-dialog-label">
    {label}
   </label>
   <textarea
    id={id}
    type="textarea"
    name={name}
    style={{
     resize: 'none'
    }}
    placeHolder={placeHolder}
    className="email-dialog-input"
    onChange={() => {}}
    {...register(name)}
   />
  </div>
 );
};

const Dropdown = ({label, name, placeHolder, watch, register}) => {
 const {onChange} = register(name);
 const autoCompleteValue = watch(name) || [];

 return (
  <CustomAutocomplete
   label={label}
   onChange={value => {
    onChange({
     target: {
      name,
      value
     }
    });
   }}
   classes={{
    container: styles.inputContainer
   }}
   autoCompleteValue={autoCompleteValue}
   placeholder={placeHolder}
   fetchUrl="/tag/search"
   createUrl="/tag"
   key={name}
  />
 );
};

const Checkbox = ({label, name, register}) => {
 const id = `form-field-${name}`;
 return (
  <div
   key={name}
   className={classNames('input-container horizontal', styles.inputContainer)}
  >
   <input
    type="checkbox"
    id={id}
    name={name}
    className={classNames('email-dialog-input', styles.checkbox)}
    onChange={() => {}}
    {...register(name)}
   />
   <label
    htmlFor={id}
    className={classNames('email-dialog-label', styles.label)}
   >
    {label}
   </label>
  </div>
 );
};
const RadioButton = ({label, name, description, register}) => {
 const id = `form-field-${name}`;
 return (
  <div
   key={name}
   className={classNames('input-container horizontal', styles.inputContainer)}
  >
   <input
    type="radiobutton"
    id={id}
    name={name}
    className={classNames('email-dialog-input', styles.checkbox)}
    onChange={() => {}}
    {...register(name)}
   />
   <div>
    <label
     htmlFor={id}
     className={classNames('email-dialog-label', styles.label)}
    >
     {label}
    </label>
    {description && <div className="input-description">{description}</div>}
   </div>
  </div>
 );
};

export default FormRenderer;
