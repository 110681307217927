import React, {useEffect} from 'react';
import {useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import ResourceApi from '../../../api/resourceapi';
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table';
import CustomDialog from '../../../components/Dialog/Dialog';
import FormRenderer from '../../../components/FormRenderer';
import iconAddCircleWhite from '../../../assets/icons/icon-add-white.svg';
import iconBack from '../../../assets/icons/icon-back-vertical.svg';
import {Button, Typography} from '@mui/material';
import formatDate from '../../../utils/date-utils';
import {render} from '@testing-library/react';
import {useParams} from 'react-router-dom';
import Message from '../../../components/Message';
import EmptyState from '../../../components/EmptyState';
import importBanner from '../../../assets/icons/template_blank.png';
import createBanner from '../../../assets/icons/use_prebuilt.png';
import iconAddCircle from '../../../assets/icons/icon-add-circle.svg';
import iconExternalURL from '../../../assets/icons/icon-external URL.svg';
import addUser from '../../../assets/icons/undraw_add_user_re_5oib 1.png';
import followingUser from '../../../assets/icons/undraw_following_re_d5aa 1.png';
const Tags = () => {
 const {type} = useParams();
 const [data, setData] = useState([]);
 const [title, setTitle] = useState('');

 useEffect(() => {
  // Fetch data based on the type
  if (type === 'tags') {
   setTitle('Tags');
   onTagFetch();
   setData([]);
  } else if (type === 'list') {
   setTitle('List');
   setData([]);
   onListFetch();
   console.log('title is list');
   ///fetch function will be called to get the list data
  }
 }, [type]);
 //const [tags, setTags] = useState([]);
 const [isTagLoading, setIsTagLoading] = useState(true);

 const currentTagid = useRef(null);
 const [alert, setAlert] = useState({
  type: '',
  show: false,
  message: ''
 });
 //for creat3 dialog
 const [dialogConfig, setDialogConfig] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 //for edit dialog
 const [editDialogConfig, setEditDialogConfig] = useState({
  title: '',
  open: false,
  onClose: () => {},
  type: '',
  actions: []
 });
 const {
  register,
  handleSubmit,
  watch,
  setValue,
  setError,
  formState: {errors},
  reset
 } = useForm({mode: 'onBlur'});

 const onCloseDialog = () => {
  setDialogConfig(prevConfig => ({...prevConfig, open: false}));
 };

 const onEditCloseDialog = () => {
  setEditDialogConfig(prevConfig => ({...prevConfig, open: false}));
 };
 const onTagFormClose = () => {
  onCloseDialog();
  reset();
 };

 const onEditTagFormClose = () => {
  onEditCloseDialog();
  reset();
 };
 //tag api call
 const onTagFetch = () => {
  ResourceApi.tag.get().then(({data}) => {
   setIsTagLoading(false);
   handleTagResponse(data);
   console.log(data);
  });
 };

 const onListFetch = () => {
  //list fetch api will be called here
  setIsTagLoading(false);
  ResourceApi.tag.fetchList().then(({data}) => {
   setIsTagLoading(false);
   handleTagResponse(data);
   console.log(data);
  });
 };

 const handleTagResponse = data => {
  if (data.length > 0) {
   data.sort((tag1, tag2) => {
    const date1 = new Date(tag1.updated);
    const date2 = new Date(tag2.updated);
    return date2.getTime() - date1.getTime();
   });
  }
  setData(data);
 };

 const onTagCreate = data => {
  console.log(data);
  let api = {};
  if (title === 'Tags') {
   api = {
    name: data.name,
    tagOrList: 'TAG'
   };
  } else if (title === 'List') {
   api = {
    name: data.name,
    tagOrList: 'LIST'
   };
  }
  ResourceApi.tag
   .create(api)
   .then(() => {
    onTagFormClose();
    setAlert({
     type: 'success',
     show: true,
     message: 'Tag created successfully'
    });
    if (title === 'Tags') {
     onTagFetch();
    } else if (title === 'List') {
     onListFetch();
    }
   })
   .catch(error => {
    setError('email', {
     type: 'manual',
     message: error.response?.data?.message || ''
    });
   });
 };

 const onTagEdit = data => {
  ResourceApi.tag
   .update(currentTagid.current, data)
   .then(() => {
    onEditTagFormClose();
    setAlert({
     type: 'success',
     show: true,
     message: 'Tag edited successfully'
    });
    if (title === 'Tags') {
     onTagFetch();
    } else if (title === 'List') {
     onListFetch();
    }
   })
   .catch(error => {
    setError('email', {
     type: 'manual',
     message: error.response?.data?.message || ''
    });
   });
 };

 const onTagDelete = ids => {
  onCloseDialog();

  ResourceApi.tag
   .delete({
    ids
   })
   .then(() => {
    setAlert({
     type: 'success',
     show: true,
     message: `${ids.length} tag(s) deleted successfully`
    });
    if (title === 'Tags') {
     onTagFetch();
    } else if (title === 'List') {
     onListFetch();
    }
   });
 };

 const TagCreateForm = () => {
  setValue('name', '');
  return (
   <FormRenderer
    register={register}
    watch={watch}
    errors={errors}
    fields={[
     {
      type: 'text',
      name: 'name',
      placeHolder: 'type here',
      label: 'Name',
      validations: {required: 'Name is required'}
     }
    ]}
   />
  );
 };

 const TagEditForm = () => {
  const tag = data.find(tag => tag.id === currentTagid.current);
  setValue('name', tag.name);
  console.log(tag);

  return (
   <FormRenderer
    register={register}
    watch={watch}
    errors={errors}
    fields={[
     {
      type: 'text',
      name: 'name',
      label: 'Name',
      validations: {required: 'Name is required'}
     }
    ]}
   />
  );
 };
 const onTagEditFormOpen = () => {
  setEditDialogConfig({
   title: 'Edit' + title,
   open: true,
   onClose: onEditTagFormClose,
   type: 'edittag',
   actions: [
    {
     label: 'Cancel',
     onClick: onEditTagFormClose,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Save',
     onClick: () => handleSubmit(onTagEdit)(),
     classes: 'btn-primary'
    }
   ]
  });
 };
 const onTagFormOpen = () => {
  setDialogConfig({
   title: 'Create ' + title,
   open: true,
   onClose: onTagFormClose,
   type: 'tag',
   actions: [
    {
     label: 'Cancel',
     onClick: onTagFormClose,
     classes: 'btn-outline dark-border'
    },
    {
     label: 'Create',
     onClick: () => handleSubmit(onTagCreate)(),
     classes: 'btn-primary'
    }
   ]
  });
 };

 const titlemsg = title + ' (' + data.length + ')';
 const createLabel = title === 'Tags' ? 'Create Tag' : 'Create List';
 const showEmptyState = !isTagLoading && data.length === 0;
 const emptyTitle = 'Manage Contact ' + title;
 const emptyDescription =
  'Create custom ' + title + ' to help you organize and target contacts';
 return (
  <>
   <CustomDialog
    {...dialogConfig}
    content={
     dialogConfig.type === 'tag' ? <TagCreateForm /> : dialogConfig.content
    }
   />
   <CustomDialog
    {...editDialogConfig}
    content={
     editDialogConfig.type === 'edittag' ? (
      <TagEditForm />
     ) : (
      editDialogConfig.content
     )
    }
   />
   <Header
    title={titlemsg}
    actions={[
     {
      label: createLabel,
      isPrimary: true,
      icon: iconAddCircleWhite,
      onClick: onTagFormOpen
     }
    ]}
   />
   {!showEmptyState && (
    <div className="main-content-container">
     <Table
      headers={[
       {
        numeric: false,
        key: 'name',
        disablePadding: false,
        //onClick: row => navigate(`/contacts/tags/${row.id}`),
        label: 'Name',
        render: row => {
         return <Typography sx={{fontWeight: '600'}}>{row.name}</Typography>;
        }
       },
       {
        numeric: false,
        key: 'created',
        disablePadding: false,
        label: (
         <span
          style={{display: 'inline-flex', alignItems: 'center', gap: '8px'}}
         >
          Created on{' '}
          <img
           src={iconBack}
           alt="icon"
           style={{width: '16px', height: '16px'}}
          />
         </span>
        ),
        render: row => {
         return formatDate(row.created, true);
        }
       },
       {
        numeric: false,
        key: 'contactCount',
        disablePadding: false,
        label: 'Contacts'
       },
       {
        numeric: false,
        key: 'details',
        disablePadding: false,
        label: 'Details',
        customClass: 'tableDetails',
        render: row => <Button className="btn btn-grey">View</Button>
       }
      ]}
      rows={data}
      isLoading={isTagLoading}
      actions={[
       {
        label: 'Delete',
        onClick: ids => {
         setDialogConfig({
          open: true,
          title: 'Delete tag',
          onClose: onCloseDialog,
          content: `Are you sure you want to delete ${ids.length} tag(s)?`,
          actions: [
           {
            label: 'Cancel',
            onClick: onCloseDialog,
            classes: 'btn-outline dark-border'
           },
           {
            label: 'Delete',
            onClick: () => onTagDelete(ids),
            classes: 'btn-primary'
           }
          ]
         });
        }
       },
       {
        label: 'Edit',
        onClick: ids => {
         currentTagid.current = ids;
         onTagEditFormOpen();
        }
       }
      ]}
     />
    </div>
   )}
   {showEmptyState && (
    <EmptyState
     title={emptyTitle}
     description={emptyDescription}
     options={[
      {
       title: 'Create' + title,
       description:
        'Organize Your Contacts by Creating Custom ' +
        title +
        ' for Better Segmentation',
       action: {
        label: 'Create ' + title,
        icon: iconAddCircle,
        onClick: onTagFormOpen
       },
       banner: addUser,
       bannerBackground: 'yellow'
      },
      {
       title: 'Assign to Contacts',
       description:
        'Enhance Communication by Assigning ' +
        title +
        ' to Categorize, Filter and Target Your Contacts',
       action: {
        label: 'Learn More',
        icon: iconExternalURL,
        onClick: () => {}
       },
       banner: followingUser,
       bannerBackground: 'green'
      }
     ]}
    />
   )}
   <Message
    open={alert.show}
    type={alert.type}
    message={alert.message}
    onMessageClose={() => {
     setAlert({
      type: '',
      show: false,
      message: ''
     });
    }}
   />
  </>
 );
};

export default Tags;
