import React, {useState} from 'react';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import Icon from '../Icon';
import CustomMenu from '../Menu';

const Header = ({title, actions, moreActions = []}) => {
 console.log(React.isValidElement(title), title);
 const [anchorEl, setAnchorEl] = useState(null);
 return (
  <>
   <div className="header">
    <div className="header-left">
     {React.isValidElement(title) ? (
      title
     ) : (
      <span className="template-name">{title}</span>
     )}
    </div>
    <div className="header-center"></div>

    <div className="header-right">
     {actions.map(action => {
      const {label, icon, isPrimary, onClick, classes, isNotButton, title} =
       action;

      if (isNotButton) {
       return (
        <>
         {React.isValidElement(title) ? (
          title
         ) : (
          <span className="template-name">{title}</span>
         )}
        </>
       );
      }
      return (
       <Button
        className={
         classes
          ? classes
          : classNames('btn', {
             'btn-primary': isPrimary,
             'btn-outline': !isPrimary,
             'dark-border': !isPrimary
            })
        }
        sx={{
         mr: '12px'
        }}
        variant={isPrimary ? 'contained' : 'outlined'}
        onClick={onClick}
       >
        {icon && (
         <img
          className="icon"
          src={icon}
          alt="icon"
          style={
           !label
            ? {
               padding: '2px 0',
               boxSizing: 'content-box'
              }
            : {}
          }
         />
        )}
        {label}
       </Button>
      );
     })}
     {moreActions.length > 0 && (
      <Button
       onClick={event => {
        setAnchorEl(event.currentTarget);
       }}
       className="btn dark-border btn-outline"
      >
       <Icon type="icon-more" size="medium" />
      </Button>
     )}
    </div>
   </div>
   <CustomMenu
    onSelect={value => {
     const {onClick} =
      moreActions.find(moreAction => moreAction.value === value) || {};
     onClick?.();
    }}
    options={moreActions}
    open={!!anchorEl}
    onClose={() => {
     setAnchorEl(null);
    }}
    horizontalAlignment="right"
    verticalAlighnment="bottom"
    anchorEl={anchorEl}
   />
  </>
 );
};

export default Header;
