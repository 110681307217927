import React, { useState } from "react";
import FontFamilyTrait from "../TraitFunctions/FontFamily";
import FontSizeTrait from "../TraitFunctions/FontSize";

const TypographyTraitManager = ({ typegraphyTraits, setTypegraphyTraits, onChange }) => {

  const [fontFamily, setFontFamily] = useState(typegraphyTraits.fontFamily.split(",")[0].trim() || "Arial");
  const [backupFontFamily, setBackupFontFamily] = useState(typegraphyTraits.backupFontFamily || "Arial");
  const [fontSize, setFontSize] = useState(parseInt(typegraphyTraits.fontSize.replace('px', ''), 10) || 14);

  const handleFontFamilyChange = (event) => {
    const newFontFamily = event.target.value;
    setFontFamily(newFontFamily);
    setTypegraphyTraits({
      ...typegraphyTraits,
      fontFamily: newFontFamily,
    });
    onChange({ fontFamily: newFontFamily });
  };

  const handleBackupFontFamilyChange = (event) => {
    const newBackupFontFamily = event.target.value;
    setBackupFontFamily(newBackupFontFamily);
    setTypegraphyTraits({
      ...typegraphyTraits,
      backupFontFamily: newBackupFontFamily,
    });
    onChange({ backupFontFamily: newBackupFontFamily });
  };

  const handleFontSizeChange = (event) => {
    const newFontSize = `${event.target.value}px`;
    setFontSize(parseInt(event.target.value, 10));
    setTypegraphyTraits({
      ...typegraphyTraits,
      fontSize: newFontSize,
    });
    onChange({ fontSize: newFontSize });
  };

  return (
    <div>
      <FontFamilyTrait
        value={fontFamily}
        onChange={handleFontFamilyChange}
        label="Font Family"
      />
      <FontFamilyTrait
        value={backupFontFamily}
        onChange={handleBackupFontFamilyChange}
        label="Backup Font Family"
      />
      <FontSizeTrait
        value={fontSize}
        onChange={handleFontSizeChange}
        label="Font Size"
      />
    </div>
  );
};

export default TypographyTraitManager;
