// http.js
import axios from 'axios';
import {Navigate} from 'react-router-dom';

// Set up a base instance of axios
const axiosInstance = axios.create({
  //baseURL: 'http://138.197.63.124:8080/api/v1',
  //baseURL: 'https://adflipr.com/api/v1', 
  baseURL: window.location.hostname.includes('localhost')
    ? 'http://138.197.63.124:8080/api/v1'
    : `${window.location.origin}/api/v1`,
  timeout: 100000, 
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add a request interceptor to include the authorization token
axiosInstance.interceptors.request.use(
 config => {
  const loginToken = localStorage.getItem('loginToken');
  if (loginToken) {
   config.headers['Authorization'] = `${loginToken}`;
  }
  return config;
 },
 error => {
  return Promise.reject(error);
 }
);

// Common function to handle API requests
export const apiRequest = async (method, endpoint, data = {}, params = {}, isMultipart = false) => {
 try {
  const headers = isMultipart ? { 'Content-Type': 'multipart/form-data' } : {};
  const response = await axiosInstance({
   method,
   url: endpoint,
   data,
   params,
   headers,
  });
  return response;
 } catch (error) {
  handleError(error);
 }
};

// Function to handle errors
const handleError = error => {
 if (error.response) {
  // Server responded with a status other than 2xx
  console.error('Error response:', error.response.data);
  console.error('Error status:', error.response.status);
  if (error.response.status === '401') {
   Navigate('/login');
  }
 } else if (error.request) {
  // Request was made but no response was received
  console.error('Error request:', error.request);
 } else {
  // Something else happened while setting up the request
  console.error('Error message:', error.message);
 }
 throw error;
};

export default axiosInstance;
