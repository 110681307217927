import React from "react";
import { Button } from "@mui/material";
import createTemplate from "../../../../assets/icons/template_blank.png";
import viewTemplate from "../../../../assets/icons/use_prebuilt.png";
import iconAddCircle from "../../../../assets/icons/icon-add.svg";
import iconView from "../../../../assets/icons/icon-preview.svg";
import { useNavigate } from "react-router-dom";

const MyTemplateEmpty = () => {
  const navigate = useNavigate();

  const openPrebuiltTemplate = () => {
    navigate("/templates/prebuilt-templates");
  };

  return (
    <div className="empty-wrapper">
      <div className="empty-headings">
        <h1>Start Using adflipr with Email Template Creation</h1>
        <p>For templates, you can use your own layouts, dropping them </p>
      </div>
      <div className="image-container">
        <div className="empty-section">
          <div className="empty-section-title">
            <h3>Start from Blank</h3>
            <p>Create a template from scratch using Blocks and Modules</p>

            <Button
              className="btn btn-outline dark-border"
              variant="contained"
              onClick={openPrebuiltTemplate}
            >
              <img className="icon" src={iconAddCircle} />
              Create Template
            </Button>
          </div>
          <div className="empty-section-img yellow">
            <img src={createTemplate} />
          </div>
        </div>
        <div className="empty-section">
          <div className="empty-section-title">
            <h3>Use Pre-built Templates</h3>
            <p>Edit and use right away any of 50+ beautiful templates</p>

            <Button
              className="btn btn-outline dark-border"
              variant="contained"
              onClick={openPrebuiltTemplate}
            >
              <img className="icon" src={iconView} />
              View Template
            </Button>
          </div>
          <div className="empty-section-img green">
            <img src={viewTemplate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTemplateEmpty;
