// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactDetails_container__L0BhN {
  border-radius: 12px !important;
  padding: 16px 24px;
}
.ContactDetails_container__L0BhN .ContactDetails_header__cZ0DK {
  margin-bottom: 10px;
  padding: 0;
}
.ContactDetails_container__L0BhN .ContactDetails_content__GWN03 {
  padding: 0 !important;
  font-size: 14px;
  font-weight: 500;
}
.ContactDetails_container__L0BhN .ContactDetails_content__GWN03 .ContactDetails_cell__dF7-e {
  font-size: 12px;
  color: #717171;
}
.ContactDetails_container__L0BhN .ContactDetails_content__GWN03 .ContactDetails_row__eP8wX {
  padding: 12px 0;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  line-height: 1.5;
}
.ContactDetails_container__L0BhN .ContactDetails_content__GWN03 .ContactDetails_row__eP8wX .ContactDetails_name__Xl-1u {
  flex-basis: 33.3333333333%;
  color: #717171;
}
.ContactDetails_container__L0BhN .ContactDetails_content__GWN03 .ContactDetails_row__eP8wX .ContactDetails_value__0u3W2 {
  flex-basis: 66.6666666667%;
  display: flex;
  align-items: center;
}

.ContactDetails_editForm__tXFgO {
  padding: 16px;
  width: 360px;
  border-radius: 12px;
}

.ContactDetails_btnStatus__aX4oZ {
  padding: 4px 8px !important;
  border-radius: 20px !important;
  text-transform: none !important;
  height: 24px;
  font-size: 12px !important;
  color: #000000 !important;
  background: #F1F2F9 !important;
}
.ContactDetails_btnStatus__aX4oZ:hover {
  background: #F1F2F9 !important;
}

.ContactDetails_statusMenu__OQ1\\+H {
  width: 180px;
  list-style-type: none;
  padding: 0;
}
.ContactDetails_statusMenu__OQ1\\+H li {
  padding: 8px 12px;
  cursor: pointer;
}
.ContactDetails_statusMenu__OQ1\\+H li:hover {
  background: #F1F2F9;
}`, "",{"version":3,"sources":["webpack://./src/pages/contacts/AllContacts/ContactDetails/ContactDetails.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;EACA,UAAA;AACJ;AAEE;EACE,qBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AACI;EACE,eAAA;EACA,cAAA;AACN;AACI;EACE,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,4CAAA;EACA,gBAAA;AACN;AAAM;EACE,0BAAA;EACA,cAAA;AAER;AAAM;EACE,0BAAA;EACA,aAAA;EACA,mBAAA;AAER;;AAIA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AADF;;AAIA;EACE,2BAAA;EACA,8BAAA;EACA,+BAAA;EACA,YAAA;EACA,0BAAA;EACA,yBAAA;EACA,8BAAA;AADF;AAEE;EACE,8BAAA;AAAJ;;AAIA;EACE,YAAA;EACA,qBAAA;EACA,UAAA;AADF;AAEE;EACE,iBAAA;EACA,eAAA;AAAJ;AACI;EACE,mBAAA;AACN","sourcesContent":[".container {\n  border-radius: 12px !important;\n  padding: 16px 24px;\n  \n  .header {\n    margin-bottom: 10px;\n    padding: 0;\n  }\n\n  .content {\n    padding: 0 !important;\n    font-size: 14px;\n    font-weight: 500;\n    .cell {\n      font-size: 12px;\n      color: #717171;\n    }\n    .row {\n      padding: 12px 0;\n      flex-direction: row;\n      align-items: center;\n      border-bottom: 1px solid var(--color-border);\n      line-height: 1.5;\n      .name {\n        flex-basis: calc(100% / 3);\n        color: #717171;\n      }\n      .value {\n        flex-basis: calc(100% * 2/3);\n        display: flex;\n        align-items: center;\n      }\n    }\n  }\n}\n\n.editForm {\n  padding: 16px;\n  width: 360px;\n  border-radius: 12px;\n}\n\n.btnStatus {\n  padding: 4px 8px !important;\n  border-radius: 20px !important;\n  text-transform: none !important;\n  height: 24px;\n  font-size: 12px !important;\n  color: #000000 !important;\n  background: #F1F2F9 !important;\n  &:hover {\n    background: #F1F2F9 !important\n  }\n}\n\n.statusMenu {\n  width: 180px;\n  list-style-type: none;\n  padding: 0;\n  li {\n    padding: 8px 12px;\n    cursor: pointer;\n    &:hover {\n      background: #F1F2F9;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactDetails_container__L0BhN`,
	"header": `ContactDetails_header__cZ0DK`,
	"content": `ContactDetails_content__GWN03`,
	"cell": `ContactDetails_cell__dF7-e`,
	"row": `ContactDetails_row__eP8wX`,
	"name": `ContactDetails_name__Xl-1u`,
	"value": `ContactDetails_value__0u3W2`,
	"editForm": `ContactDetails_editForm__tXFgO`,
	"btnStatus": `ContactDetails_btnStatus__aX4oZ`,
	"statusMenu": `ContactDetails_statusMenu__OQ1+H`
};
export default ___CSS_LOADER_EXPORT___;
