import * as React from 'react';
import {
 List,
 ListItem,
 ListItemText,
 Button,
 Drawer,
 Box,
 Typography,
 DialogContent,
 CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import iconClose from '../../assets/icons/icon-cross.svg';
import DesktopIcon from '../../assets/icons/icon-desktop.svg';
import DesktopIconWhite from '../../assets/icons/icon-desktop-white.svg';
import MobileIcon from '../../assets/icons/icon-Mobile.svg';
import MobileIconWhite from '../../assets/icons/icon-Mobile-white.svg';
import {useDispatch, useSelector} from 'react-redux';
import {
 toggleDevice,
 setCurrentTemplateId,
 setCurrentTemplateName
} from '../../store/store';
import Toggler from '../../layouts/EmailBuilder/components/Toggler/Toggler';
import {useLocation, useNavigate} from 'react-router-dom';
import {template} from '../../api/templateapi';
import CustomDialog from '../Dialog/Dialog';
import {useEffect} from 'react';
export default function TemplatePreviewDrawer({
 isOpen,
 toggleDrawer,
 cards,
 setIsTemplatePreviewDrawer,
 selectedCard,
 setTemplateLayout
}) {
 const [selectedTemplate, setSelectedTemplate] = React.useState(selectedCard);
 const deviceState = useSelector(state => state.onMobile);
 const templateId = useSelector(state => state.currentTemplateId);
 const templateName = useSelector(state => state.currentTemplateName);
 const [templateWidth, setTemplateWidth] = React.useState('640px');
 const [loader, setLoader] = React.useState(false);
 const [updateDialog, setUpdateDialog] = React.useState(false);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const editor = useSelector(state => state.editor);



 const cardStyle = {
  display: 'flex',
  padding: '0px',
  width: '150px',
  height: '150px',
  overflow: 'hidden',
  maxHeight: '150px',
  position: 'relative'
 };

 const mainStyle = {
  display: 'flex',
  padding: '0px',
  width: templateWidth,
  height: '636px',
  overflow: 'hidden',
  position: 'relative',
  transition: 'width 2s linear 1s',
  transitionTimingFunction: 'ease-in-out'
 };

 const openTemplate = async () => {
  console.log('Import clicked');
  try {
   setLoader(true);

   if (templateId) {
    setUpdateDialog(true);
   } else {
    const response = await template.createTemplate({
     name: selectedTemplate.name,
     htmlContent: selectedTemplate.content
    });
    navigate(`/email-builder/${response.data.name}/${response.data.id}`, {
     state: {
      htmlContent: selectedTemplate.content,
      from: location
     }
    });
    dispatch(setCurrentTemplateId(response.data.id));
    dispatch(setCurrentTemplateName(response.data.name));
   }
  } catch (err) {
   console.log(err);
  } finally {
   toggleDrawer(false);
   setLoader(false);
  }
 };

 const openEditedTemplate = async () => {
  try {
   setLoader(true);
   const response = await template.updateTemplate(templateId, {
    htmlContent: selectedTemplate.content
   });
   toggleDrawer(false);

   setTemplateLayout(false);
   navigate(`/email-builder/${response.data.name}/${response.data.id}`, {
    state: {
     htmlContent: selectedTemplate.content,
     from: location
    }
   });
  } catch (e) {
   console.log(e);
  } finally {
   toggleDrawer(false);
   setLoader(false);
  }
 };

 const switchDevice = () => {
  const newDevice = deviceState ? 'Desktop' : 'Mobile';
  setTemplateWidth(newDevice === 'Desktop' ? '640px' : '360px');
  dispatch(toggleDevice());
 };

 React.useEffect(() => {
  setSelectedTemplate(selectedCard);
 }, [selectedCard]);

 const drawerContent = (
  <Box sx={{display: 'flex', height: '100%', width: '1280px'}}>
   <Box
    sx={{
     width: '198px',
     borderRight: '1px solid #ccc',
     overflowY: 'auto'
    }}
   >
    <Typography variant="h6" sx={{p: 2}}>
     Select Templates
    </Typography>
    <List className="drawer-sidebar">
     {cards.map((card, index) => (
      <ListItem
       button
       key={index}
       onClick={() => setSelectedTemplate(card)}
       className="drawer-side-card"
      >
       <DialogContent style={cardStyle}>
        <iframe
         srcDoc={card.content}
         frameBorder="0"
         style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          border: 'none',
          position: 'absolute'
         }}
        />
       </DialogContent>
       {card.name && <div className="template-name">{card.name}</div>}
      </ListItem>
     ))}
    </List>
   </Box>
   <Box sx={{width: '1082px'}} className="drawer-body">
    <div className="header">
     <div className="header-left">
      <span className="template-name"> My Templates </span>
     </div>
     <div className="header-center">
      <Toggler
       isOn={deviceState}
       onToggle={switchDevice}
       icons={{
        on: (
         <img
          src={deviceState ? DesktopIconWhite : DesktopIcon}
          alt="Desktop"
         />
        ),
        off: (
         <img
          src={deviceState ? MobileIcon : MobileIconWhite}
          alt="Mobile"
          type={'device-toggler'}
         />
        )
       }}
      />
     </div>
     <div className="header-right">
      <Button
       onClick={openTemplate}
       className="btn btn-primary"
       variant="contained"
      >
       {loader ? <CircularProgress className="loader-btn" /> : ''}
       <span style={{visibility: loader ? 'hidden' : 'visible'}}>Import</span>
      </Button>
      <Button onClick={toggleDrawer(false)}>
       <img className="icon" src={iconClose} alt="Close" />
      </Button>
     </div>
    </div>

    <DialogContent style={mainStyle}>
     <iframe
      srcDoc={selectedTemplate.content}
      frameBorder="0"
      style={{
       width: '100%',
       height: '100%',
       overflow: 'hidden',
       border: 'none',
       position: 'absolute'
      }}
     />
    </DialogContent>
   </Box>
  </Box>
 );

 return (
  <>
   {updateDialog && (
    <CustomDialog
     open={updateDialog}
     title={'Replacing Template?'}
     actions={[
      {
       label: 'Cancel',
       onClick: () => setUpdateDialog(false),
       classes: 'btn btn-outline dark-border'
      },
      {
       label: 'Proceed',
       onClick: () => openEditedTemplate(),
       classes: 'btn btn-delete dark-border'
      }
     ]}
     content={
      'When you import this template, your existing template will be replaced with this new template.'
     }
     onClose={() => setUpdateDialog(false)}
    />
   )}

   <Drawer
    anchor="right"
    open={isOpen}
    onClose={toggleDrawer(false)}
    sx={{zIndex: 1300}}
   >
    {drawerContent}
   </Drawer>
  </>
 );
}

TemplatePreviewDrawer.propTypes = {
 isOpen: PropTypes.bool.isRequired,
 toggleDrawer: PropTypes.func.isRequired,
 cards: PropTypes.arrayOf(
  PropTypes.shape({
   name: PropTypes.string,
   content: PropTypes.string.isRequired
  })
 ).isRequired,
 selectedCard: PropTypes.shape({
  name: PropTypes.string,
  content: PropTypes.string.isRequired
 })
};
