import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import {useNavigate, useParams} from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Icon from '../../../components/Icon';
import ResourceApi from '../../../api/resourceapi';
import styles from '../../contacts/AllContacts/ContactDetails/ContactDetails.module.scss';
import Header from '../../../components/Header';
import Progress from '../../../components/Progress/Progess';
import CustomMenu from '../../../components/Menu/Menu';
import DynamicTabs from '../../../components/Tab/Tab';
import PieChart from './PieChart';
import DoughnutChart from './Doughnut';
import BarChart from './BarGraph';
import iconEdit from '../../../assets/icons/icon-edit.svg';
const contactStatusOptions = [
 {
  icon: 'icon-tick',
  label: 'Subscribed',
  value: 'SUBSCRIBE'
 },
 {
  icon: 'icon-unsubscribe',
  label: 'Unsubscribed',
  value: 'UNSUBSCRIBED'
 },
 {
  icon: 'icon-blocked',
  label: 'Blocked',
  value: 'BLOCKED'
 },
 {
  icon: 'icon-bounced',
  label: 'Bounced',
  value: 'BOUNCED'
 },
 {
  icon: 'icon-bounced',
  label: 'Marked as Spam',
  value: 'MARKED-AS-SPAM'
 }
];

const DoughnutDatadata = {
 labels: ['Sent', 'Open', 'Failed', 'Bounce'],
 datasets: [
  {
   data: [30, 70, 45, 60],
   backgroundColor: ['#636E72', '#00B894', '#E17055', '#FDCB6E'],
   hoverBackgroundColor: ['#636E72', '#00B894', '#E17055', '#FDCB6E']
  }
 ]
};

const options = {
 plugins: {
  legend: {
   display: true,
   position: 'right',
   labels: {
    usePointStyle: true,
    pointStyle: 'circle', // This will make the legend markers circular
    boxWidth: 5
   }
  }
 },
 responsive: true,
 maintainAspectRatio: false
};

const barData = {
 labels: [
  '1 Jan',
  '2 Jan',
  '3 Jan',
  '4 Jan',
  '5 Jan',
  '6 Jan',
  '7 Jan',
  '8 Jan',
  '9 Jan',
  '10 Jan',
  '11 Jan',
  '12 Jan',
  '13 Jan',
  '14 Jan',
  '15 Jan',
  '16 Jan',
  '17 Jan',
  '18 Jan',
  '19 Jan',
  '20 Jan'
 ],
 datasets: [
  {
   label: 'Performance',
   data: [
    500, 1000, 1500, 1000, 2000, 1500, 1000, 1200, 1300, 1400, 1600, 1700, 1800,
    1900, 500, 1000, 1500, 1000, 2000, 2000
   ],
   backgroundColor: context => {
    const index = context.dataIndex;
    return index === 4 ? 'rgba(153, 102, 255, 1)' : 'rgba(153, 102, 255, 0.6)';
   },
   borderColor: 'rgba(153, 102, 255, 1)',
   borderWidth: 1
  }
 ]
};

const barOptions = {
 plugins: {
  legend: {
   display: false
  },
  tooltip: {
   callbacks: {
    label: function (tooltipItem) {
     const data = tooltipItem.raw;
     const labels = ['Open', 'Click', 'Order', 'Revenue'];
     const values = [90, 40, 2, 23.56];
     let tooltipText = '';
     for (let i = 0; i < labels.length; i++) {
      tooltipText += `${labels[i]}: ${values[i]}${
       i === labels.length - 1 ? '$' : ''
      }\n`;
     }
     return tooltipText;
    }
   }
  }
 },
 scales: {
  x: {
   categoryPercentage: 10, // Adjust this value to set the gap between bars
   barPercentage: 2 // Adjust this value to set the width of bars
  },
  y: {
   beginAtZero: true,
   max: 4000,
   ticks: {
    stepSize: 1000
   }
  }
 }
};

const Charts = ({match}) => {
 const navigate = useNavigate();
 const tabs = ['Overview', 'Reciepient Activity', 'Orders'];
 const panels = [
  <Overview />,
  <div className="Tab-container">Content for Tab Two</div>,
  <div className="Tab-container">Content for Tab Four</div>
 ];
 return (
  <>
   <Header
    title={
     <Stack direction="row" gap={2} alignItems="center">
      <IconButton
       aria-label="back"
       onClick={() => {
        navigate('/campaigns');
       }}
      >
       <Icon type="icon-back" />
      </IconButton>
      <Typography>Welcome email to new users</Typography>
      <Chip
       size="small"
       label="Draft"
       sx={{
        backgroundColor: '#FCF6EB',
        color: '#B68F40',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none' // Prevents label from transforming to uppercase
       }}
      />
     </Stack>
    }
    actions={[
     {
      label: 'Edit',
      isPrimary: false,
      icon: iconEdit
     }
    ]}
    moreActions={[
     {
      label: 'Pause',
      value: 'assign_to_automation',
      icon: 'icon-pause'
     },
     {
      label: 'Cancelled',
      value: 'assign_to_automation',
      icon: 'icon-cross-circle'
     },
     {
      label: 'Delete',
      value: 'delete',
      icon: 'icon-delete'
     }
    ]}
   />
   <DynamicTabs tabs={tabs} panels={panels} />
  </>
 );
};
//  const statusDetails = contactStatusOptions.find(
//   statusOption => statusOption.value === contact.status
//  );
const Overview = () => {
 return (
  <>
   <div
    style={{
     backgroundColor: '#F9FAFB',
     minHeight: 'calc(100vh - 56px)'
    }}
   >
    <Stack
     direction="row"
     gap={3}
     sx={{display: 'grid', gridTemplateColumns: '62% 38%'}}
    >
     <Stack gap={3} sx={{}}>
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Key Insights
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        {/* {isContactLoading ? <Progress /> : <></>} */}
        <div className="insights">
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
         <div className="data">
          <div className="number">100</div>
          <div className="preview-text">contacts</div>
         </div>
        </div>
       </CardContent>
      </Card>
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Performance
         </Typography>
        }
       />
       <CardContent className={styles.content}>
        <BarChart data={barData} options={barOptions} />
       </CardContent>
      </Card>
      <Stack gap={3} sx={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
       <Card variant="outlined" className={styles.container}>
        <CardHeader
         className={styles.header}
         title={
          <Typography
           variant="h2"
           sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
          >
           Send to Unopen
          </Typography>
         }
        />
        <CardContent className={styles.content}>
         <div className="send-status">
          You can resend the emails to contacts who didn’t open the emails that
          you sent
          <div className="Data">
           <div className="number">10</div>
           <div className="preview-text">Contacts</div>
          </div>
          <div className="botton">
           <button className="btn btn-outline dark-border ">
            Resend a Foolow-up campaign
           </button>{' '}
          </div>
         </div>
        </CardContent>
       </Card>
       <Card variant="outlined" className={styles.container}>
        <CardHeader
         className={styles.header}
         title={
          <Typography
           variant="h2"
           sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
          >
           Send to Failed
          </Typography>
         }
        />
        <CardContent className={styles.content}>
         <div className="send-status">
          You can resend the emails to contacts who didn’t open the emails that
          you sent
          <div className="Data">
           <div className="number">10</div>
           <div className="preview-text">Contacts</div>
          </div>
          <div className="botton">
           <button className="btn btn-outline dark-border ">
            Resend a Foolow-up campaign
           </button>{' '}
          </div>
         </div>
        </CardContent>
       </Card>
      </Stack>
     </Stack>
     <Stack gap={3} justifyContent="flex-start">
      <Card variant="outlined" className={styles.container}>
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Engagements
         </Typography>
        }
       />
       <CardContent className={styles.content} sx={{display: 'grid'}}>
        {/* <PieChart data={pieChartData} width={168} height={168} /> */}
        <DoughnutChart
         data={DoughnutDatadata}
         options={options}
         width={'342px'}
         height={'218px'}
        />
       </CardContent>
      </Card>
      <Card variant="outlined">
       <CardHeader
        className={styles.header}
        title={
         <Typography
          variant="h2"
          sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
         >
          Campaign Activity
         </Typography>
        }
       />
       <CardContent>
        <div className="campaign-activity">
         <div className="activity-content">
          <span className="name-initial">RS</span>
          <div>
           <div className="activity-action">Ravi Saxena opened the email</div>
           <div className="activity-time">26 May, 2024 at 01:50 PM</div>
          </div>
         </div>
         <div className="activity-content">
          <span className="name-initial">RS</span>
          <div>
           <div className="activity-action">Ravi Saxena opened the email</div>
           <div className="activity-time">26 May, 2024 at 01:50 PM</div>
          </div>
         </div>
         <div className="activity-content">
          <span className="name-initial">RS</span>
          <div>
           <div className="activity-action">Ravi Saxena opened the email</div>
           <div className="activity-time">26 May, 2024 at 01:50 PM</div>
          </div>
         </div>
        </div>
       </CardContent>
      </Card>
     </Stack>
    </Stack>
    <Card
     variant="outlined"
     className={styles.container}
     sx={{marginTop: '24px'}}
    >
     <CardHeader
      className={styles.header}
      title={
       <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography
         variant="h2"
         sx={{lineHeight: 1.2, fontWeight: 600, fontSize: '1.25rem'}}
        >
         Content
        </Typography>
        <button className="btn btn-outline dark-border">Preview</button>
       </div>
      }
     />
     <CardContent className={styles.content}>
      <div className="campaign-content">
       <img src="" alt="campaign preview " />
       <div className="content-text">
        <div>
         <div className="label">Subject</div>
         <div className="description">
          Special 10% discount to my new Customers this summar
         </div>
        </div>
        <div>
         <div className="label">Subject</div>
         <div className="description">
          Special 10% discount to my new Customers this summar
         </div>
        </div>
        <div>
         <div className="label">Subject</div>
         <div className="description">
          Special 10% discount to my new Customers this summar
         </div>
        </div>
        <div>
         <div className="label">Subject</div>
         <div className="description">
          Special 10% discount to my new Customers this summar
         </div>
        </div>
        <div>
         <div className="label">Subject</div>
         <div className="description">
          Special 10% discount to my new Customers this summar
         </div>
        </div>
        <div className="UTM-details">
         <div className="UTM-header">UTM</div>
         <div className="content">
          <div className="text">
           <div className="label">Campaign Source</div>
           <div className="description">Adflipr</div>
          </div>
          <div className="text">
           <div className="label">Campaign Source</div>
           <div className="description">Adflipr</div>
          </div>
          <div className="text">
           <div className="label">Campaign Source</div>
           <div className="description">Adflipr</div>
          </div>
         </div>
         <div className="content">
          <div className="text">
           <div className="label">Campaign Source</div>
           <div className="description">Adflipr</div>
          </div>
          <div className="text">
           <div className="label">Campaign Source</div>
           <div className="description">Adflipr</div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </CardContent>
    </Card>
   </div>
   {/* <Popover
    open={!!editAnchorElement}
    anchorEl={editAnchorElement}
    onClose={handleEditClose}
    anchorOrigin={{
     vertical: 'bottom',
     horizontal: 'center'
    }}
   >
    <div className={styles.editForm}>
     <Typography
      variant="h2"
      sx={{fontSize: '16px', mb: 1, lineHeight: 1.5, fontWeight: 600}}
     >
      Edit Details
     </Typography>

     <Stack direction="row" gap={1} marginTop={2} justifyContent="flex-end">
      <Button className="btn btn-outline dark-border" onClick={handleEditClose}>
       Cancel
      </Button>
      <Button className="btn btn-primary" onClick={event => {}}>
       Save
      </Button>
     </Stack>
    </div>
   </Popover> */}

   {/* <CustomMenu
    onClose={() => {
     setStatusAnchorElement(null);
    }}
    onSelect={value => {
     setStatusAnchorElement(null);
     // ResourceApi
     //   .contact
     //   .update(contact.id, { status: value })
     //   .then(({ data }) => {
     //     setContact(data)
     //   })
    }}
    options={contactStatusOptions}
    anchorEl={statusAnchorElement}
   /> */}
  </>
 );
};

export default Charts;
