import MenuIcon from "../../../../../assets/icons/icon-menu.svg";

export const registerMenuBlock = (editor, brandStyles, opts = {}) => {
  // Define the custom component type for menuBlock using your structure
  editor.DomComponents.addType("Menu", {
    model: {
      defaults: {
        tagName: "span",
        classes: ["block-div"],
        attributes: {"data-gjs-type": "Menu"},
        style: {
          display: "block !important",
          "font-family":  `${brandStyles?.typeGraphy?.fontFamily}, ${brandStyles?.typeGraphy?.backupFontFamily}` ||  "Arial",
          width: "100%",
        },
      
        components: [
          {
            tagName: "table",
            type: "table",
            style: {
              display: "inline-block",
            },
            droppable: false,
            components: [
              {
                tagName: "tbody",
                type: "tbody",
                droppable: false,
                components: [
                  {
                    tagName: "tr",
                    type: "row",
                    droppable: false,
                    components: [
                      {
                        tagName: "td",
                        type: "cell",
                        droppable: false,
                        components: [
                          {
                            tagName: "a",
                            attributes: { href: "", target: "_blank" },
                            components: [
                              {
                                tagName: "span",
                                type: "text",
                                content: "Item 1",
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              }
                            ],
                            droppable: false,
                            
                          },
                          {
                            tagName: "a",
                            attributes: { href: "", target: "_blank"  },
                            components: [
                              {
                                tagName: "span",
                                type: "text",
                                content: "Item 2",
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size":  brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              }
                            ],
                            droppable: false,
                          },
                          {
                            tagName: "a",
                            attributes: { href: "", target: "_blank"  },
                            components: [
                              {
                                tagName: "span",
                                type: "text",
                                content: "Item 3",
                                droppable: false,
                                editable: true,
                                style: {
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  "font-size": brandStyles?.typeGraphy?.fontSize || "14px",
                                  "font-weight": "600",
                                  "text-decoration": "none",
                                  color: "#000000",
                                },
                              }
                            ],
                            droppable: false,
                          },
                          // ... add more links as needed
                        ],
                      },
                      // ... add more cells if needed
                    ],
                  },
                  // ... add more rows if needed
                ],
              },
            ],
          },
        ],
        droppable: false,
        // Enable inline styling
        stylable: true,
      },
      init() {
        // Ensure styles are inline when the component is initialized
        this.set({ style: this.defaults.style });
      },
    },
  });

  // Add the block for menuBlock
  editor.BlockManager.add("menuBlock", {
    label: `<div class="gjs-block-label-container" data-category="Block"><img src=${MenuIcon} alt="Menu Icon" height="24px" width="24px"/></div>
    <div class="block-label-text">Menu</div>`,
    content: { type: "Menu" },
    category: "Blocks",
    draggable: "td",
  });
};
